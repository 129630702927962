import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { StorageService, TicketService } from '../../shared/services';
import { Cancellation } from '../../shared/models';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-exit-alert-modal',
  templateUrl: './exit-alert-modal.component.html',
  styleUrls: ['./exit-alert-modal.component.scss']
})
export class ExitAlertModalComponent implements OnInit {
  accessKey: string;
  ticketId: number;
  cancellation: Cancellation;
  providerId: number;
  isBoticario: boolean;

  constructor(
    private ticketService: TicketService,
    private storageService: StorageService,
    private router: Router,
    public dialogRef: MatDialogRef<ExitAlertModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.accessKey = this.data.accessKey;
    this.ticketId = this.data.ticketId;
    this.providerId = this.data.providerId;
    this.isBoticario = this.data.isBoticario;
  }

  cancelTicket() {
    if (this.isBoticario) {
      window['dataLayer'] = window['dataLayer'] || [];

      window['dataLayer'].push({
        event: 'click',
        data: 'Saida_Fila_Sair'
      });
    }

    this.cancellation = {
      operatorId: this.accessKey ?? environment.operatorId,
      reason: '',
    };

    this.ticketService.cancel(this.ticketId, this.providerId, this.cancellation).subscribe(() => {
      this.storageService.removeTicket(this.ticketId);

      if (this.storageService.tickets.length > 0) {
        this.storageService.setCurrentTicket(this.storageService.tickets[0]);
      } else {
        this.storageService.setCurrentTicket(null);
      }

      this.dialogRef.close();

      this.router.navigate([`/tickets`]);
    });
  }

  closeDialog() {
    if (this.isBoticario) {
      window['dataLayer'] = window['dataLayer'] || [];

      window['dataLayer'].push({
        event: 'click',
        data: 'Saida_Fila_Voltar'
      });
    }

    this.dialogRef.close();
  }

}
