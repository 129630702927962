import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Terminal, TerminalPayload, Customer, Ticket } from '../models';
import { Cancellation } from '../models/cancellation.model';

@Injectable()
export class TicketService {
  private urlApi: string = environment.urlApi;

  constructor(private http: HttpClient) { }

  getTicket(id: number): Observable<any> {
    return this.http
      .get<any>(`${this.urlApi}v2/ticketing/tickets/${id}`);
  }

  getCurrentTicket(): Ticket {
    return JSON.parse(localStorage.getItem('currentTicket'));
  }

  setCurrentTicket(ticket: Ticket) {
    localStorage.setItem('currentTicket', JSON.stringify(ticket));
  }

  getChildrenKey(id: number): Observable<any> {
    return this.http.get<any>(`${this.urlApi}v2/ticketing/tickets/getchildrenkey/${id}`)
  }

  booking(payload: TerminalPayload): Observable<any> {
    return this.http
      .post<any>(`${this.urlApi}v2/ticketing/tickets/booking-express`, payload);
  }

  cancel(id: number, providerId: number, payload: Cancellation): Observable<any> {
    return this.http.post<any>(`${this.urlApi}v2/ticketing/public/providers/${providerId}/tickets/${id}/cancel`, payload);
  }

  getPosition(providerId: number, id: number): Observable<any> {
    return this.http.get<any>(`${this.urlApi}v2/ticketing/providers/${providerId}/tickets/${id}/position`);
  }

  getPrevision(id: number): Observable<any> {
    return this.http.get<any>(`${this.urlApi}v2/prevision/tickets/${id}`);
  }

  confirm(id: number, providerId: number) {
    return this.http.post<any>(`${this.urlApi}v2/ticketing/public/providers/${providerId}/tickets/${id}/confirm`, null);
  }

  checkin(smartCode: string, providerId: number) {
    return this.http.post<any>(`${this.urlApi}v2/ticketing/tickets/check-code`, {
      smartCode,
      pid: providerId
    });
  }
}
