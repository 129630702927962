<app-header [bgColor]="'#7604C2'" [logoUrl]="'assets/images/fz-logo-white.svg'"></app-header>

<div class="container">
  <div class="image-container">
    <img src="assets/images/404.png">
  </div>

  <div class="message-container">
    <h2>Que pena! :(</h2>
    <p>Não conseguimos encontrar a página que você está procurando.</p>
    <button class="btn" mat-flat-button color="primary" routerLink="/">Início</button>
  </div>
</div>
