import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { Ticket } from '../models';

@Injectable()
export class PresenceService {
  private pushId: string;

  constructor(private db: AngularFireDatabase) {
    this.pushId = this.db.createPushId();
  }

  private getPath(ticket: Ticket): string {
    return `${ticket.providerId}/${ticket.serviceConfigId}/${ticket.id}/connectedDevices`;
  }

  setPresence(status: string, ticket: Ticket) {
    switch(status) {
      case 'visible':
        status = 'online';
        break;
      case 'hidden':
        status = 'away';
    }

    const ticketRef = this.db.object(this.getPath(ticket));
    ticketRef.update({[this.pushId]: status});
  }

  addVisibilityListener(ticket: Ticket) {
    var ref = this.db.database.ref(this.getPath(ticket));

    ref.onDisconnect().update(
      {[this.pushId]: 'offline'},
      () => {
        if (document.onvisibilitychange !== undefined) {
          this.dispose();
          ref.update({[this.pushId]: 'offline'});
        }
      }
    ).then(() => {
      this.addVisibilityEvent(ticket)
      this.setPresence(document.visibilityState, ticket);
    });

    window.onbeforeunload = () => {
      this.dispose();
      this.setPresence('offline', ticket);
    }
  }

  addVisibilityEvent(ticket: Ticket) {
    document.onvisibilitychange = (e) => {
      this.setPresence(document.visibilityState, ticket);
    }
  }

  dispose() {
    document.onvisibilitychange = undefined;
  }
}
