import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class FeedbackService {
  private urlApi: string = environment.urlApi;

  constructor(private http: HttpClient) { }

  update(feedback: any): Observable<any> {
    const body = { feedbackId: feedback.id, guid: feedback.guid, comment: feedback.comment, rate: feedback.rating, platform: 'web' };

    return this.http.put(`${this.urlApi}v2/ticketing/feedbacks/${feedback.id}?guid=${feedback.guid}`, body);
  }
}
