import { Component, Input, OnInit } from '@angular/core';

  @Component({
  selector: 'app-queue-tracker',
  templateUrl: './queue-tracker.component.html',
  styleUrls: ['./queue-tracker.component.scss']
})
export class QueueTrackerComponent implements OnInit {
  @Input() location: string;
  @Input() amount: number;
  @Input() backgroundColor: string;
  @Input() fontColor?: string;

  constructor() { }

  ngOnInit(): void {
  }

}
