import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BoticarioStartComponent } from './boticario-start/boticario-start.component';
import { BoticarioScheduleComponent } from './boticario-schedule/boticario-schedule.component';
import { BoticarioTrackComponent } from './boticario-track/boticario-track.component';
import { RouterModule } from '@angular/router';
import { BoticarioRoutes } from './boticario.routing';
import { ComponentsModule } from '../components/components.module';
import { MaterialModule } from '../material/material.module';
import { FormsModule } from '@angular/forms';
import { PipeModule } from '../shared/pipe/pipe.module';
import { NgxMaskDirective, NgxMaskPipe } from 'ngx-mask';
import { PagesModule } from '../shared/pages/pages.module';
import { TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    BoticarioStartComponent,
    BoticarioScheduleComponent,
    BoticarioTrackComponent
  ],
  imports: [
    CommonModule,
    ComponentsModule,
    FormsModule,
    MaterialModule,
    PipeModule,
    RouterModule.forChild(BoticarioRoutes),
    NgxMaskDirective, NgxMaskPipe,
    PagesModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateModule,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ]
})
export class BoticarioModule { }
