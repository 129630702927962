import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

interface RemoteConfig {
  defaultValue: {
    value: string;
  },
  valueType: string
}

@Injectable({
  providedIn: 'root'
})
export class RemoteConfigService {

  constructor(private readonly http: HttpClient) { }

  async shouldUseCustomInterface(): Promise<boolean> {
    const url = `${environment.cloudFunctionsUrl}/getUseCustomInterface?env=${environment.env}`;
    
    const response = await this.http.get<RemoteConfig>(url).toPromise();

    return response.valueType === 'BOOLEAN' && response.defaultValue.value === 'true';
  }
}
