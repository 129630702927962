export const environment = {
  env: 'dev',
  production: true,
  urlBase: 'https://entre.dev.filazero.app/',
  urlExterna: 'https://site.dev.filazero.net/',
  urlApi: 'https://api.dev.filazero.net/',
  urlMeet: 'https://meetdev.filazero.net/',
  feedBackSiteUrl: 'https://site.dev.filazero.net/',
  cloudFunctionsUrl: 'https://us-central1-filazero-6db8c.cloudfunctions.net/',
  recaptchaKey: '6LfccPMpAAAAAIEhlhoMJsdxym18sXbzRZ4AI9bs',
  pusher: {
    key: '0041ab94e110de594bdb',
    cluster: 'mt1',
    app_id: '75465'
  },
  artesanoProviderId: 460,
  boticarioProviderId: 352,
  phoneNoelProviderId: 357,
  queueNoelProviderId: 776, // same providerId of the production
  firebase: {
    apiKey: 'AIzaSyAaV9u67AL1nczlia-VJU-gmiR8rWpRIdE',
    authDomain: 'filazero-6db8c.firebaseapp.com',
    databaseURL: 'https://fz-develop.firebaseio.com',
    projectId: 'filazero-6db8c',
    storageBucket: 'filazero-6db8c.appspot.com',
    messagingSenderId: '747494649248',
    appId: '1:747494649248:web:9e427106a978765e0888c5',
    measurementId: 'G-XQFEP5KGCE'
  },
  nike: {
    providerId: 356,
    nikeStore: {
      'f04e0acea9fb4fc6ad5969f02ee48205': 'nike'
    }
  },
  operatorId: '0f086a11-fbe4-4da6-8bc2-988beb420a1e',
  smartCodePrefix: 'SC',
};
