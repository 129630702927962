<app-header [bgColor]="'#7604C2'" [logoUrl]="'assets/images/fz-logo-white.svg'"></app-header>

<app-secondary-header
  [icon]="'access_time'"
  [text]="date | timezoneFormat: '' | dateFormat: { format: 'LL', locale: currentLang }"
  [isClickable]="false"
  [isBoticario]="false"
  [reduceHeight]="false"
  [bgColor]="'#A937F5'"
>
</app-secondary-header>

<app-empty-state
  *ngIf="hasNoTickets"
  [color]="'#7604C2'"
  icon="error_outline"
  message="{{ ('general.pages.tickets.noTickets' | translate) }}"
></app-empty-state>

<div class="list-tickets">
  <app-ticket-list-tile class="ticket" *ngFor="let ticket of tickets" [ticket]="ticket"></app-ticket-list-tile>
</div>

<div class="footer-fixed">
  <div class="button-next">
    <button
      class="btn"
      (click)="goHome()"
      mat-flat-button>
      <span>
        {{ 'general.actions.enterNewQueue' | translate }}
      </span>
    </button>
  </div>
</div>
