// Force a hex value to have 2 characters
function pad2(c: string) {
  return c.length === 1 ? '0' + c : '' + c;
}

export function rgbToHex(r: number, g: number, b: number): string {
  return [
    pad2(Math.round(r).toString(16)),
    pad2(Math.round(g).toString(16)),
    pad2(Math.round(b).toString(16))
  ].join('');
}

export function hexToRgb(hex: string): number[] {
  if (hex.length > 6) {
    hex = hex.substr(1, hex.length - 1);
  }

  const match = hex.match(/.{1,2}/g);

  return [
    parseInt(match[0], 16),
    parseInt(match[1], 16),
    parseInt(match[2], 16),
  ];
}

export function brighten(color: string, amount: number): string {
  amount = (amount === 0) ? 0 : (amount || 10);

  const rgb = hexToRgb(color);

  rgb[0] = Math.max(0, Math.min(255, rgb[0] - Math.round(255 * - (amount / 100))));
  rgb[1] = Math.max(0, Math.min(255, rgb[1] - Math.round(255 * - (amount / 100))));
  rgb[2] = Math.max(0, Math.min(255, rgb[2] - Math.round(255 * - (amount / 100))));

  return rgbToHex(rgb[0], rgb[1], rgb[2]);
}
