<app-header
  [bgColor]="'#7604C2'"
  [logoUrl]="'assets/images/fz-logo-white.svg'"
></app-header>

<section class="container">
  <content>
    <section class="wrapper-center">
      <mat-spinner [diameter]="40"></mat-spinner>
    </section>
  </content>
</section>