import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { interval, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

moment.updateLocale('pt-br', {
  relativeTime: {
    future: (str) => str === 'Em instantes' ? str : `Em ${str}`,
    past: (str) => str === 'Em instantes' ? str : `Há ${str}`,
    s: 'Em instantes',
    m: '1 min',
    mm: '%d min',
    h: '1 hora',
    d: '1 dia',
    M: '1 mês',
    y: '1 ano'
  }
});

moment.relativeTimeThreshold('s', 60);
moment.relativeTimeThreshold('ss', 59);
moment.relativeTimeThreshold('m', 60);
moment.relativeTimeThreshold('h', 24);

@Pipe({
  name: 'relativeTimeFormat'
})
export class RelativeTimeFormatPipe implements PipeTransform {

  transform(value: string): Observable<string> {
    return interval(1000).pipe(
      map(() => moment(value).fromNow()),
      distinctUntilChanged()
    );
  }
}
