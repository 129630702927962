import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { Ticket } from '../models';
import { TicketService } from '../services';

@Injectable({
  providedIn: 'root'
})
export class PositionUtilService {

  constructor(private ticketService: TicketService) {}

  getNewPosition(ticket: Ticket): Promise<number> {
    return new Observable<number>((observer) => {
      this.ticketService.getPosition(ticket.providerId, ticket.id).subscribe(response => {
        observer.next(response);
        observer.complete();
      });

    }).toPromise();
  }
}
