import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Service } from '../../shared/models';
import { StorageService, TerminalService, TicketService } from '../../shared/services';
import Hashids from 'hashids';
@Component({
  selector: 'app-ticket',
  templateUrl: './ticket.component.html',
  styleUrls: ['./ticket.component.scss']
})
export class TicketComponent implements OnInit {
  terminalAccessKey: string;
  ticketAccessKey: string;

  constructor(
    private router: Router,
    private _activatedRoute: ActivatedRoute,
    private storageService: StorageService,
    private ticketService: TicketService,
    private terminalService: TerminalService,
  ) { }

  ngOnInit() {
    this.ticketAccessKey = this._activatedRoute.snapshot.params['accessKey'];
    this.terminalAccessKey = this._activatedRoute.snapshot.queryParams['t'];
    this.getTerminal(this.terminalAccessKey);
  }

  private getTicket(ticketId: any) {
    this.ticketService.getTicket(ticketId)
      .subscribe((response) => {
        const ticket = {
          id: response.id,
          prevision: response.estimatedServiceStart,
          status: response.status,
          resource: response.resource?.name,
          attendanceType: response.attendanceType,
          timezoneId: response.location.timezoneId,
          providerId: response.provider.id,
          position: 0,
          serviceConfigId: response.session.serviceConfigId,
          friendlyCode: response.friendlyCode,
          accessKey: response.accessKey,
          locationId: response.location.id,
          locationName: response.location.name,
          providerName: response.provider.name,
          serviceName: response.service.name,
          feedback: response.feedback,
          smartCode: response.smartCode
        };

        this.storageService.addTicket(ticket);
        this.storageService.setCurrentTicket(ticket);

        this.terminalService.getService(response.service.id)
          .subscribe((data) => {
            const service: Service = {
              id: data.id,
              name: data.name,
              sessions: [],
              isRecommendDesktop: data.isRecommendDesktop,
              lobbyContent: data.lobbyContent,
              note: data.note,
              embedChat: data.embedChat
            }
            this.storageService.setCurrentService(service)
            this.router.navigate([`/track`]);
          });
        this.terminalService.getTemplate(response.provider.slug).subscribe((template) => 
            this.storageService.setTemplate(template)
          );

      });
  }

  private getTerminal(accessKey: string): void {
    localStorage.setItem('terminalKey', accessKey);
    this.terminalService.getTerminal(accessKey).subscribe(response => {
      if (!(response.messages && response.messages[0].type === 'ERROR')) {
        const terminal = response;

        this.storageService.removeTerminal(terminal.id);
        this.storageService.addTerminal(terminal);
      }

      this.getTicket(this.decode(this.ticketAccessKey));
    });
  }

  private decode(accessKey: string): any {
    const hashids = new Hashids("45148c5e-3401-470b-a737-253d9d0b0831", 8, "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789");
    return hashids.decode(accessKey)[0].valueOf();
  }
}
