import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-queue-tracker-list',
  templateUrl: './queue-tracker-list.component.html',
  styleUrls: ['./queue-tracker-list.component.scss']
})
export class QueueTrackerListComponent implements OnInit {
  @Input() customersInStoreAmount: number;
  @Input() isFull: boolean;
  @Input() capacity: number
  @Input() customersInQueueAmount: number;

  constructor() { }

  ngOnInit(): void {
  }

}
