import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { CustomFieldService } from '../custom-field.service';
import { FieldBase } from '../models/field-base.model';

@Component({
  selector: 'app-custom-fields',
  templateUrl: './custom-fields.component.html',
  styleUrls: ['./custom-fields.component.scss']
})
export class CustomFieldsComponent implements OnInit, OnDestroy {
  @Input() field: FieldBase;
  @Input() providerId: number;
  @Input() locationId: number;
  @Input() form: UntypedFormGroup;

  currentDate: string;
  options = [];

  private subscription: Subscription;

  constructor(private customFieldService: CustomFieldService) {
    this.currentDate = new Date().toISOString().slice(0, 10);
  }

  ngOnInit() { }

  ngOnDestroy() {
    if (this.subscription !== undefined) {
      this.subscription.unsubscribe();
    }
  }

  get isInvalid() {
    return this.form.controls[this.field.title].hasError('required') && this.form.controls[this.field.title].touched;
  }

  get isInvalidLength() {
    return this.form.controls[this.field.title].hasError('minlength') && this.form.controls[this.field.title].touched;
  }

  get isInvalidCpf() {
    return this.form.controls[this.field.title].hasError('cpfNotValid') && this.form.controls[this.field.title].touched;
  }

  get isInvalidCnpj() {
    return this.form.controls[this.field.title].hasError('cnpjNotValid') && this.form.controls[this.field.title].touched;
  }

  getCustomFieldOptions() {
    if (this.options.length === 0) {
      this.subscription = this.customFieldService.getCustomFieldOptions(this.providerId, this.locationId, this.field.id)
        .subscribe((options) => this.options = options);
    }
  }
}
