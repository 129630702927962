import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { MaterialModule } from 'src/app/material/material.module';
import { NgxMaskDirective, NgxMaskPipe } from 'ngx-mask';
import { CustomFieldService } from './custom-field.service';
import { CustomFieldsComponent } from './components/custom-fields.component';
import { CustomFieldsFormComponent } from './components/custom-fields-form.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MaterialModule,
    NgxMaskDirective, NgxMaskPipe,
    TranslateModule
  ],
  declarations: [
    CustomFieldsComponent,
    CustomFieldsFormComponent
  ],
  providers: [
    CustomFieldService
  ],
  exports: [
    CustomFieldsComponent,
    CustomFieldsFormComponent
  ]
})
export class CustomFieldsModule {
  constructor() { }
}
