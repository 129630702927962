<app-header
  [bgColor]="terminal.headerBackgroundColor || '#7604C2'"
  [logoUrl]="terminal.providerLogoUrl || 'assets/images/fz-logo-white.svg'"
></app-header>

<app-secondary-header
  [icon]="'keyboard_backspace'"
  [text]="'general.pages.schedule.title' | translate"
  [isClickable]="true"
  [isBoticario]="false"
  [bgColor]="brighten(terminal.headerBackgroundColor || '#7604C2', 20)"
>
</app-secondary-header>

<section *ngIf="!isLoading && hasServices && hasSessions && areSlotsAvailable && !displayOptions" class="container">
  <section class="services">
    <section class="schedule-wrapper">
      <div class="input-wrapper">
        <div class="title">
          <h3>{{ 'general.pages.schedule.basicInformation' | translate }}</h3>
        </div>
        <div [formGroup]="formGroup" class="form">
          <div>
            <mat-form-field class="input">
              <mat-label>{{ 'general.pages.schedule.form.name.label' | translate }}</mat-label>

              <input #name (focus)="onFocus($event, name)" matInput formControlName="name">
              <mat-error>
                {{ 'general.pages.schedule.form.name.errors.required' | translate }}
              </mat-error>
            </mat-form-field>
          </div>
          <div>
            <mat-form-field class="input">
              <mat-label>{{ 'general.pages.schedule.form.phone.label' | translate }}</mat-label>

              <input #phone (focus)="onFocus($event, phone)" mask="(00) 0 0000-0000" matInput formControlName="phone">
              <mat-error>
                {{ 'general.pages.schedule.form.phone.errors.required' | translate }}
              </mat-error>
            </mat-form-field>
          </div>

          <div *ngIf="isQueueNoel">
            <label for="">{{ 'general.pages.schedule.form.email.title' | translate }}*</label>

            <mat-form-field class="input" appearance="outline">
              <mat-label>{{ 'general.pages.schedule.form.email.label' | translate }}</mat-label>

              <input #email (focus)="onFocus($event, email)" matInput formControlName="email">
              <mat-error>
                {{ 'general.pages.schedule.form.email.errors.required' | translate }}
              </mat-error>
            </mat-form-field>
          </div>

          <div *ngIf="hasCustomFields">
            <app-custom-fields-form
              (sendFormGroup)="setCustomFieldForm($event)"
              [fields]="fields"
              [providerId]="terminal.provider.id"
              [locationId]="terminal.location.id"
            >
            </app-custom-fields-form>
          </div>
        </div>

        <div *ngIf="allowPriority" class="priority-checkbox">
          <div class="check-option">
            <mat-checkbox [(ngModel)]="isPriority" (change)="openPriorityDialog()">
              {{ 'general.pages.schedule.form.preferential' | translate }}
              <mat-icon
                class="material-icons-outlined"
                id="report-icon"
              >
                accessible
              </mat-icon>
            </mat-checkbox>
          </div>
        </div>

        <div class="fixed-footer">
          <div class="next">
            <button
              class="btn"
              [style.backgroundColor]="brighten(terminal.headerBackgroundColor || '#7604C2', 20)"
              color="primary"
              mat-raised-button
              (click)="this.showOptionList()"
              [disabled]="!isValid || isLoadingEmit"
            >
              {{ 'general.actions.continue' | translate }}
            </button>
          </div>
        </div>
      </div>
    </section>
  </section>
</section>

<app-service-list-wrapper [displayOptions]="displayOptions" [isLoadingEmit]="isLoadingEmit">
  <app-collapsable-option-button
    *ngIf="selectedCategory"
    [button]="categoryBackButton"
    (buttonClick)="leaveCategory()"
  ></app-collapsable-option-button>
  <app-collapsable-option-button
  *ngFor="let button of buttons"
    [button]="button"
    (buttonClick)="onCardClick($event)"
   ></app-collapsable-option-button>
</app-service-list-wrapper>

<app-empty-state
  *ngIf="!isLoading && (!hasServices || !hasSessions || !areSlotsAvailable)"
  [color]="terminal.headerBackgroundColor"
  icon="error_outline"
  message="{{ isQueuesNoel ? ('general.pages.schedule.noServicesNoel' | translate) : ('general.pages.schedule.noServices' | translate) }}"
></app-empty-state>

<app-embed-chat
  *ngIf="!isLoading && !!terminal.location.embedChat"
  [embedChat]="terminal.location.embedChat"
></app-embed-chat>
