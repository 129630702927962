<div class="wrapper">

  <app-queue-tracker [amount]="customersInQueueAmount" location="{{ 'general.display.queue' | translate }}" backgroundColor="white">
    <mat-icon icon class="material-icons" style="font-size: 1.5em;">
      person_outline
    </mat-icon>
  </app-queue-tracker>

  <app-queue-tracker [amount]="customersInStoreAmount" location="{{ 'general.display.store' | translate }}"
    [backgroundColor]="isFull ? 'red' : 'green'" fontColor="white">

    <mat-icon icon class="material-icons-outlined" style="font-size: 1.5em;">
      shopping_bag
    </mat-icon>

    <span capacity>
      {{ 'general.display.capacity' | translate }} {{ capacity }}
    </span>
  </app-queue-tracker>

</div>