<div *ngIf="isLoading" class="loader"></div>

<div *ngIf="!doesExistPanel" class="invalid">
  <mat-icon
    class="material-icons-outlined"
    id="report-icon"
  >
    report_problem
  </mat-icon>
  <span>{{ 'general.display.noPanel' | translate }}</span> 
</div>

<div class="container" [style.background-image]="'url(' + backgroundUrl + ')'" *ngIf="!isLoading && doesExistPanel">
  <div class="scanner">
    <app-store-scanner [providerLogo]="providerLogoUrl" [expressLink]="expressLink"></app-store-scanner>
  </div>
  <div class="trackers">
    <app-queue-tracker-list 
      [isFull]="isFull" 
      [customersInStoreAmount]="customersInStoreAmount"
      [customersInQueueAmount]="customersInQueueAmount" 
      [capacity]="capacity"
    ></app-queue-tracker-list>
  </div>
</div>


