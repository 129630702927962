import { Injectable } from "@angular/core";
import { ServiceConfig } from "src/app/shared/models/service.config.model";
import { PusherService } from "src/app/shared/services";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";

@Injectable()
export class DisplayService {
  private urlApi: string = environment.urlApi;
  customersInQueue: number;
  customersInStore: number;
  eventMap: Map<string, Function>;
  eventNames = [
    "TicketCheckin",
    "TicketStart",
    "TicketComplete",
    "TicketCancel",
  ];

  constructor(private pusherService: PusherService, private http: HttpClient) {
    this.eventMap = this.getEventMap();
  }

  getPanel(accessKey: string): Observable<any> {
    return this.http.get(`${this.urlApi}api/panels/${accessKey}`);
  }

  setInitialCount(countStarted: number, countChecked: number): void {
    this.customersInStore = countStarted;
    this.customersInQueue = countChecked;
  }

  subscribeQueues(serviceConfigs: ServiceConfig[]): void {
    for (const serviceConfig of serviceConfigs) {
      this.pusherService.add(
        `serviceConfig-${serviceConfig.id}`,
        this.eventMap
      );
    }
  }

  private getEventMap(): Map<string, Function> {
    const map = new Map<string, Function>();
    this.eventNames.forEach((eventName) => {
      map.set(eventName, () => {
        switch (eventName) {
          case "TicketCheckin":
            this.incrementQueue();
            break;
          case "TicketStart":
            this.incrementInStore();
            this.decrementQueue();
            break;
          case "TicketComplete":
            this.decrementInStore();
            break;
          case "TicketCancel":
            this.decrementQueue();
            break;
        }
      });
    });
    return map;
  }

  private incrementQueue(): void {
    this.customersInQueue++;
  }

  private decrementQueue(): void {
    if (this.customersInQueue - 1 < 0) {
      this.customersInQueue = 0;
    } else {
      this.customersInQueue--;
    }
  }

  private incrementInStore(): void {
    this.customersInStore++;
  }

  private decrementInStore(): void {
    if (this.customersInStore - 1 < 0) {
      this.customersInStore = 0;
    } else {
      this.customersInStore--;
    }
  }

  get customersInQueueAmount(): number {
    return this.customersInQueue;
  }

  get customersInStoreAmount(): number {
    return this.customersInStore;
  }
}
