import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Injector } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ComponentsModule } from './components/components.module';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { MaterialModule } from './material/material.module';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import {
  FeedbackService,
  LocaleService,
  NotificationService,
  PresenceService,
  PusherService,
  ServiceLocator,
  StorageService,
  TerminalService,
  TicketService,
} from './shared/services';
import { BoticarioModule } from './boticario/boticario.module';
import { PipeModule } from './shared/pipe/pipe.module';
import { NgxMaskDirective, NgxMaskPipe } from 'ngx-mask';
import { AngularFireModule } from '@angular/fire/compat';
import 'firebase/compat/app';
import 'firebase/compat/auth'; 
import 'firebase/compat/database';
import { AngularFireAnalyticsModule, ScreenTrackingService } from '@angular/fire/compat/analytics';
import { PagesModule } from './shared/pages/pages.module';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { CustomFieldsModule } from './modules/custom-fields/custom-fields.module';
import { DisplayModule } from './modules/display/display.module';
import { TicketListComponent } from './ticket-list/ticket-list.component';
import { DisplayComponent } from './display/display.component';
import { FilazeroModule } from './filazero/filazero.module';
import { GoogleMapsModule } from '@angular/google-maps';
import { QRCodeModule } from 'angularx-qrcode';
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    TicketListComponent,
    DisplayComponent
  ],
  imports: [
    AngularFireModule.initializeApp(environment.firebase, 'express'),
    AngularFireAnalyticsModule,
    AppRoutingModule,
    BoticarioModule,
    BrowserAnimationsModule,
    BrowserModule,
    ComponentsModule,
    CustomFieldsModule,
    DisplayModule,
    FilazeroModule,
    FormsModule,
    GoogleMapsModule,
    HttpClientModule,
    MaterialModule,
    NgxMaskDirective, NgxMaskPipe,
    PagesModule,
    PipeModule,
    QRCodeModule,
    ReactiveFormsModule,
    RecaptchaV3Module,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
  ],
  providers: [
    FeedbackService,
    LocaleService,
    NotificationService,
    PresenceService,
    PusherService,
    ScreenTrackingService,
    StorageService,
    TerminalService,
    TicketService,
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.recaptchaKey },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private Injector: Injector) {
    ServiceLocator.injector = this.Injector;
  }
}
