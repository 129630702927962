<app-header
  *ngIf="!isLoading"
  [bgColor]="terminal?.headerBackgroundColor || '#7604C2'"
  [logoUrl]="terminal?.providerLogoUrl || 'assets/images/fz-logo-white.svg'"
></app-header>

<content *ngIf="isLoading">
  <section class="wrapper-center">
    <mat-spinner [diameter]="40"></mat-spinner>
  </section>
</content>

<div id="welcome-wrapper" *ngIf="terminal && !isLoading" class="welcome-wrapper">
  <section class="welcome">
    <div class="welcome-message">
      <p>
        {{ 'general.pages.start.wellcome' | translate }}
      </p>
    </div>

    <div class="shop-name">
      <h3 [style.color]="terminal.headerBackgroundColor">
        {{ terminal.location.name | uppercase }}
      </h3>
    </div>

    <div class="terms">
      <mat-checkbox [(ngModel)]="isChecked">
        <p>
          {{ 'general.pages.start.legalTerms.part1' | translate }}
          <a *ngIf="!isPhoneNoel" (click)="openTermsModal()" [style.color]="brighten(terminal.headerBackgroundColor || '#7604C2', 25)">
            {{ 'general.pages.start.legalTerms.termsOfUse' | translate }}
          </a>
          <a *ngIf="isPhoneNoel" (click)="openTermsNoelModal()" [style.color]="brighten(terminal.headerBackgroundColor || '#7604C2', 25)">
            {{ 'general.pages.start.legalTerms.termsOfUse' | translate }}
          </a>
          {{ 'general.pages.start.legalTerms.part2' | translate }}
          <a (click)="openPrivacyPolicyModal()" [style.color]="brighten(terminal.headerBackgroundColor || '#7604C2', 25)">
            {{ 'general.pages.start.legalTerms.privacyPolicy' | translate }}
          </a>
        </p>
      </mat-checkbox>
    </div>
  </section>

  <div class="footer-fixed">
    <div class="button-next">
      <button
        [style.backgroundColor]="brighten(terminal.headerBackgroundColor || '#7604C2', 20)"
        class="btn"
        (click)="next()"
        mat-flat-button
        color="primary"
        [disabled]="!isValid"
      >
        {{ 'general.actions.getInLine' | translate }}
      </button>
    </div>
  </div>
</div>

<app-empty-state
  *ngIf="!terminal && !isLoading"
  [color]="'#7604C2'"
  icon="error_outline"
  message="{{ 'general.pages.start.queueNotFound' | translate }}"
></app-empty-state>

<app-embed-chat
  *ngIf="!isLoading && !!terminal.location.embedChat"
  [embedChat]="terminal.location.embedChat"
></app-embed-chat>
