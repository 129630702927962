import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { Ticket } from 'src/app/shared/models';
import { LocaleService, NotificationService, StorageService } from 'src/app/shared/services';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Input() bgColor: string;
  @Input() logoUrl: string;
  @Input() logoHeight = '24px';
  @Input() showText = false;
  @Input() textColor = 'white';
  @Output() logoClick = new EventEmitter();
  @Output() digitalQueueClick = new EventEmitter();

  langs = [
    {
      code: 'pt-BR',
      name: 'Brasil'
    },
    {
      code: 'pt-PT',
      name: 'Portugal'
    },
    {
      code: 'en-US',
      name: 'EUA'
    }
  ];

  hasTicketChanged: boolean = false;
  tickets: Ticket[];
  eventNames = ['TicketCheckin', 'PrevisionChanged', 'TicketCalled', 'TicketStart', 'TicketComplete', 'TicketCancel'];
  subscription: Subscription;

  constructor(
    private translateService: TranslateService,
    private localeService: LocaleService,
    private notificationService: NotificationService,
    private router: Router,
    private storageService: StorageService
  ) { }

  ngOnInit() {
    this.tickets = this.storageService.tickets;
    this.addTicketsToChannels();
    this.subscribe();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  subscribe(): void {
    this.subscription = this.notificationService.notifications.subscribe((response) => {
      if (response.ticket.id !== this.storageService.currentTicket.id) {
        if (this.eventNames.includes(response.eventName)) {
          this.changeTicketStatus();
        }
      }
    });
  }

  addTicketsToChannels(): void {
    this.tickets.forEach(ticket => {
      if (ticket.id !== this.storageService.currentTicket.id) {
        this.notificationService.add(ticket.id, ticket.serviceConfigId, this.eventNames)
      }
    });
  }

  next(): void {
    this.router.navigate(['/tickets']);
    this.hasTicketChanged = false;
  }

  changeTicketStatus(): void {
    this.hasTicketChanged = !this.hasTicketChanged;
  }

  shouldHideBadge(): boolean {
    return this.isRouteTicketList() || this.isRouteHome() || !this.hasTicketChanged;
  }

  isRouteTicketList(): boolean {
    return this.router.url === '/tickets';
  }

  isRouteHome(): boolean {
    return this.router.url === '/home';
  }

  setLang(lang: string) {
    this.translateService.use(lang)
      .subscribe(() => this.localeService.storeUserLocale(lang));
  }

  get currentLang() {
    return this.localeService.getUserLocale();
  }

  onLogoClick() {
    this.logoClick.emit();
  }

  onDigitalQueueClick() {
    this.digitalQueueClick.emit();
  }

}
