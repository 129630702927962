import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

import { FieldBase } from './models/field-base.model';
import { FieldInput } from './models/field-input.model';

@Injectable({
  providedIn: 'root'
})
export class CustomFieldService {
  ENDPOINT = environment.urlApi + 'api/providers/';

  constructor(private readonly http: HttpClient) { }

  getCustomFields(locationId: number, serviceConfigList: number[]) {
    return new Observable<FieldBase[]>(observer => {
      this.http.get<FieldBase[]>(environment.urlApi + `v2/scheduling/self-service/locations/${locationId}/custom-fields`).subscribe((response) => {
        observer.next(this.buildFieldList(response));
        observer.complete();
      });
    });
  }

  getCustomFieldOptions(providerId: number, locationId: number, customFieldId: number): Observable<any> {
    return this.http.get<any>(`${this.ENDPOINT}${providerId}/locations/${locationId}/custom-fields/${customFieldId}/options`);
  }

  private buildFieldList(response: FieldBase[]): FieldBase[] {
    return response.map((field) => this.buildField(field));
  }

  private buildField(field: any): FieldBase {
    const data = new FieldInput({
      id: field.id,
      title: field.title,
      required: field.required,
      maxLength: field.maxLength
    });

    switch (field.type) {
      case 0:
        data.type = 'text';
        break;
      case 1:
        data.type = 'number';
        break;
      case 2:
        data.type = 'date';
        break;
      case 3:
        data.type = 'cpf';
        break;
      case 4:
        data.type = 'select';
        break;
      case 5:
        data.type = 'cnpj';
        break;
      default:
        data.type = 'text';
        break;
    }

    return data;
  }
}
