import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TimezoneFormatPipe } from 'src/app/shared/pipe/timezone-format.pipe';
import { DateFormatPipe } from 'src/app/shared/pipe/date-format.pipe';
import { RelativeTimeFormatPipe } from 'src/app/shared/pipe/relative-time-format.pipe';
import { SafeHtmlPipe } from 'src/app/shared/pipe/safe-html.pipe';
import { SmartCodeFormatPipe } from './smart-code-format.pipe';

@NgModule({
  declarations: [
    DateFormatPipe,
    TimezoneFormatPipe,
    RelativeTimeFormatPipe,
    SafeHtmlPipe,
    SmartCodeFormatPipe
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    DateFormatPipe,
    TimezoneFormatPipe,
    RelativeTimeFormatPipe,
    SafeHtmlPipe,
    SmartCodeFormatPipe
  ]
})
export class PipeModule { }
