import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { SecondaryHeaderComponent } from './secondary-header/secondary-header.component';
import { ExitAlertModalComponent } from './exit-alert-modal/exit-alert-modal.component';
import { TermsModalComponent } from './terms-modal/terms-modal.component';
import { TermsNoelModalComponent } from './terms-noel-modal/terms-noel-modal.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { PipeModule } from '../shared/pipe/pipe.module';
import { TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SoundAlertComponent } from './sound-alert/sound-alert.component';
import { EmptyStateComponent } from './empty-state/empty-state.component';
import { TrackInformationComponent } from './track-information/track-information.component';
import { MatBadgeModule } from '@angular/material/badge';
import { TicketListTileComponent } from './ticket-list-tile/ticket-list-tile.component';
import { OptionButtonComponent } from './option-button/option-button.component';
import { NavigationBarComponent } from './navigation-bar/navigation-bar.component';
import { CollapsableOptionButtonComponent } from './collapsable-option-button/collapsable-option-button.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { ServiceListWrapperComponent } from './service-list-wrapper/service-list-wrapper.component';
import { PriorityModalComponent } from './priority-modal/priority-modal.component';
import { MatDialogModule } from '@angular/material/dialog';
import { PrivacyPolicyModalComponent } from './privacy-policy-modal/privacy-policy-modal.component';
import { EmbedChatComponent } from './embed-chat/embed-chat.component';
import { VidoconferenceAlertComponent } from './vidoconference-alert/vidoconference-alert.component';
import { TicketDetailsComponent } from './ticket-details/ticket-details.component';
import { QRCodeModule } from 'angularx-qrcode';
import { GoogleMapsModule } from '@angular/google-maps';


export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
    declarations: [
        HeaderComponent,
        SecondaryHeaderComponent,
        ExitAlertModalComponent,
        TermsModalComponent,
        TermsNoelModalComponent,
        SoundAlertComponent,
        EmptyStateComponent,
        TrackInformationComponent,
        TicketListTileComponent,
        OptionButtonComponent,
        NavigationBarComponent,
        CollapsableOptionButtonComponent,
        ServiceListWrapperComponent,
        PriorityModalComponent,
        PrivacyPolicyModalComponent,
        EmbedChatComponent,
        VidoconferenceAlertComponent,
        TicketDetailsComponent
    ],
    imports: [
        CommonModule,
        MatButtonModule,
        MatIconModule,
        MatToolbarModule,
        PipeModule,
        MatMenuModule,
        MatBadgeModule,
        MatExpansionModule,
        MatDialogModule,
        QRCodeModule,
        GoogleMapsModule,
        TranslateModule.forChild({
            loader: {
                provide: TranslateModule,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        })
    ],
    exports: [
        HeaderComponent,
        SecondaryHeaderComponent,
        ExitAlertModalComponent,
        TermsModalComponent,
        TermsNoelModalComponent,
        SoundAlertComponent,
        EmptyStateComponent,
        TrackInformationComponent,
        TicketListTileComponent,
        OptionButtonComponent,
        NavigationBarComponent,
        CollapsableOptionButtonComponent,
        ServiceListWrapperComponent,
        EmbedChatComponent,
        TicketDetailsComponent
    ]
})
export class ComponentsModule { }
