<div class="queue" [ngStyle]="{'background-color': backgroundColor, 'color': fontColor}">
  <div class="information">
    <div>
      <ng-content select="[icon]"></ng-content>
    </div>
    <div>
      <span class="space">{{ amount }}</span>
      <span>{{ 'general.display.peopleAt' | translate }} {{ location }}.</span>
      <div class="capacity">
        <ng-content select="[capacity]"></ng-content>
      </div>
    </div>
  </div>
</div>