import { Component, OnInit, ViewChild } from '@angular/core';
import { CollapsableButton, CustomData, Service, Session, Terminal, TerminalOptionsDisplay, TerminalPayload, Ticket } from '../../shared/models';
import { MessagingService, PusherService, StorageService, TerminalService, TicketService } from '../../shared/services';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { finalize } from 'rxjs/operators';
import { Router } from '@angular/router';
import { brighten } from '../../shared/utils/colors';
import { environment } from 'src/environments/environment';
import { FieldInput } from '../../modules/custom-fields/models/field-input.model';
import { CustomFieldService } from '../../modules/custom-fields/custom-field.service';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { PriorityModalComponent } from '../../components/priority-modal/priority-modal.component';
import { v4 as uuidv4 } from 'uuid';
import { chain } from 'lodash';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss']
})
export class ScheduleComponent implements OnInit {
  terminal: Terminal;
  payload: TerminalPayload;
  isLoading = true;
  isLoadingEmit = false;
  completed = false;
  ticketId: number;
  isNameFocus = false;
  isPhoneFocus = false;
  isEmailFocus = false;
  formGroup: UntypedFormGroup;
  fields: FieldInput[] = [];
  isConstumFieldFormValid = false;
  customData: CustomData[] = [];
  hasSlot = true;
  isPriority = false;
  showOptions = false;
  serviceCategories: Array<[string, Service[]]>;
  cardList: Array<any> = [];
  cardLisTemp: Array<any> = [];
  selectedCategory: string = null;
  categoryBackButton: CollapsableButton = {
    text: 'Voltar',
    fontColor: 'primary',
  } as CollapsableButton;
  buttons:Array<CollapsableButton>;

  constructor(
    private customFieldService: CustomFieldService,
    private pusherService: PusherService,
    private router: Router,
    private snackBar: MatSnackBar,
    private storageService: StorageService,
    private terminalService: TerminalService,
    private ticketService: TicketService,
    public dialog: MatDialog,
    private messagingService: MessagingService,
    private deviceService: DeviceDetectorService,
    private reCaptchaV3Service: ReCaptchaV3Service
  ) {
    this.terminal = this.storageService.currentTerminal;

    this.formGroup = new UntypedFormGroup({
      name: new UntypedFormControl('', [Validators.required]),
      phone: new UntypedFormControl('', this.isPhoneNoel ? [Validators.required] : [])
    });
    const uuid = uuidv4();
    localStorage.setItem('browserUuid', uuid);
  }

  ngOnInit() {
    this.messagingService.requestPermission();
    this.messagingService.receiveMessage();
    if (this.isQueueNoel) {
      this.formGroup.addControl('email', new UntypedFormControl('', [Validators.required]));
    }
    this.payload = this.terminalService.getCurrentTerminalPayload();
    this.isLoading = false;

    this.customFieldService.getCustomFields(this.payload.locationId, [])
      .subscribe((fields) => this.fields = fields);

    this.groupServicesByCategory(this.terminal.services);
    this.buttons = this.getButtons();
  }

  onCardClick(info: string | number) {
    if (typeof info === 'string') {
      this.selectCategory(info);
    } else {
      this.selectService(info);
    }
  }

  selectService(serviceId: number) {
    this.payload.serviceId = serviceId;
    const service = this.terminal.services.find((s) => s.id === serviceId);
    this.storageService.setCurrentService(service);
    this.payload.terminalSchedule.sessions = service.sessions;
    this.terminalService.setCurrentTerminalPayload(this.payload);

    this.reCaptchaV3Service
      .execute('schedule')
      .subscribe((token) => this.resolvedCaptcha(token));

  }

  booking() {
    this.getCustomFieldsValues();

    const browserUuid = localStorage.getItem('browserUuid');

    if (this.hasCustomFields) {
      this.payload.customData = this.customData;
    }

    this.payload.browserUuid = browserUuid;

    this.payload.customer.name = this.formGroup.value.name;
    this.payload.customer.phone = this.formGroup.value.phone;
    if (this.isQueueNoel) {
      this.payload.customer.email = this.formGroup.value.email;
    }
    this.selectServicePreference();
    this.terminalService.setCurrentTerminalPayload(this.payload);
    this.isLoadingEmit = true;
    this.terminalService.booking(this.payload)
      .pipe(finalize(() => {
        setTimeout(() => {
          if (!this.completed) {
            this.getTicket();
          }
        }, 5000);
      }))
      .subscribe((result) => {
        if (result.responseData) {
          this.subscribeBooking(result.responseData.aggregatorId);
          this.ticketId = result.responseData.tickets[0];
        } else {
          this.isLoadingEmit = false;
          this.snackBar.open(result.messages[0].description, 'Erro', { duration: 3000 });
          this.completed = true;
        }
      }, (error) => {
        this.isLoadingEmit = false;
        this.snackBar.open('Não foi possível emitir o bilhete', 'Erro', { duration: 3000 });
        this.completed = true;
      });
  }

  subscribeBooking(aggregatorId: string) {
    const scheduleChannel = this.pusherService.pusher.subscribe('schedule-' + aggregatorId);

    scheduleChannel.bind('ScheduleAuthorized', data => {
      this.isLoadingEmit = false;
      this.completed = true;

      const ticket: Ticket = {
        accessKey: data.tickets[0].AccessKey,
        friendlyCode: data.tickets[0].FriendlyCode,
        locationId: data.tickets[0].LocationId,
        id: data.tickets[0].Id,
        prevision: data.tickets[0].prevision,
        providerId: data.tickets[0].TenantId,
        timezoneId: data.tickets[0].Timezone,
        serviceConfigId: data.tickets[0].ServiceConfigId
      } as Ticket;

      this.storageService.addTicket(ticket);
      this.storageService.setCurrentTicket(ticket);

      this.goToTrack();
    });

    scheduleChannel.bind('ScheduleUnauthorized', data => {
      this.isLoadingEmit = false;
      this.completed = true;
    });
  }

  private getTicket() {
    this.ticketService.getTicket(this.ticketId)
      .pipe()
      .subscribe((ticket) => {
        const t: Ticket = {
          accessKey: ticket.accessKey,
          friendlyCode: ticket.friendlyCode,
          locationId: ticket.location.id,
          id: ticket.id,
          providerId: ticket.provider.id,
          timezoneId: ticket.location.timezoneId,
          serviceConfigId: ticket.session.serviceConfigId
        } as Ticket;

        this.storageService.addTicket(t);
        this.storageService.setCurrentTicket(t);
        this.goToTrack();
      });
  }

  openPriorityDialog() {
    if(this.isPriority) {
      const dialogRef = this.dialog.open(PriorityModalComponent);
      dialogRef.afterClosed().subscribe(confirm => {
        if(!confirm) {
          this.isPriority = false;
        }
      });
    }
  }

  resolvedCaptcha(result) {
    this.payload.recaptcha = result;
    this.booking();
  }

  private goToTrack() {
    this.router.navigate(['/track']);
  }

  onFocus(event: FocusEvent, el: HTMLElement) {
    if (this.deviceService.os === 'Android') {
      setTimeout(() => el.scrollIntoView(), 300);
    }
  }

  brighten(color: string, amount: number): string {
    return '#' + brighten(color, amount);
  }

  setCustomFieldForm(formGroup: UntypedFormGroup) {
    this.formGroup.addControl('custom', formGroup);
  }

  showOptionList() {
    this.showOptions = true;
  }

  private getCustomFieldsValues(): CustomData[] {
    this.customData = [];
    if (this.fields) {
      for (const field of this.fields) {
        if (this.formGroup.value.custom[field.title]) {
          this.customData.push({
            customFieldId: field.id,
            value: String(this.formGroup.value.custom[field.title])
          });
        }
      }
    }
    return this.customData.length > 0 ? this.customData : undefined;
  }

  private selectServicePreference() {
    this.isPriority
      ? this.payload.priority = 1
      : this.payload.priority = 0;
  }

  get hasServices() {
    return this.terminal.services &&
      this.terminal.services.length > 0 &&
      this.filteredServices.length > 0;
  }

  get filteredServices(): Service[] {
    return this.terminalService.providerAvailableServices(this.terminal.services, this.terminal.showOptionsBasedOnSessions, new Date());
  }

  private groupServicesByCategory(services: Service[]) {
    this.serviceCategories = chain(services)
    .groupBy((s) => s.category)
    .entries()
    .sortBy((e: [string, Service[]]) => e[0])
    .value();

    this.cardList = [];

    if (this.selectedCategory !== null) {
      const currentCategory = this.serviceCategories.find((c) => c[0] === this.selectedCategory);

      if (currentCategory) {
        this.cardList = currentCategory[1];
      } else {
        this.selectedCategory = null;
      }
    }

    if (this.cardList.length === 0) {
      this.cardList = this.serviceCategories
        .filter((c) => c[0] !== 'undefined')
        .map((c) => ({
          name: c[0],
          type: 'category'
        }));

        const ungrouped = this.serviceCategories.find((c) => c[0] === 'undefined');

        if (ungrouped) {
          ungrouped
          this.cardList = this.cardList.concat(ungrouped[1]);
        }
    }
  }

  selectCategory(category: string) {
    this.selectedCategory = category;
    this.categoryBackButton.backgroundColor = `#${brighten(this?.terminal?.headerBackgroundColor || '#7604C2', 20)}`,
    this.cardLisTemp = this.cardList;
    this.cardList = this.serviceCategories.find((c) => c[0] === category)[1];
    this.buttons = this.getButtons();
  }

  leaveCategory() {
    this.selectedCategory = null;
    this.cardList = this.cardLisTemp;
    this.buttons = this.getButtons();
  }

  private makeCategoryButton(element: any): CollapsableButton {
    return {
      backgroundColor: `#${brighten(this?.terminal?.headerBackgroundColor || '#7604C2', 20)}`,
      fontColor: "primary",
      text: element.name,
      type: 'category'
    }
  }

  private makeServiceButton(service: Service): CollapsableButton {
    return {
      backgroundColor: `#${brighten(this?.terminal?.headerBackgroundColor || '#7604C2', 20)}`,
      fontColor: "primary",
      text: service.name,
      serviceOrientation: service.note,
      serviceId: service.id,
    };
  }

  get shouldDisplayCategory() {
    return this.terminal.optionsDisplay === TerminalOptionsDisplay.CATEGORY;
  }

  getButtons():Array<CollapsableButton> {
    return this.shouldDisplayCategory
    ? this.cardList.map((c) => {
      if (c.type === 'category') {
        return this.makeCategoryButton(c);
      } else {
        return this.makeServiceButton(c);
      }
    })
    : this.filteredServices.map(this.makeServiceButton);
  }

  get hasSessions() {
    if (this.hasServices) {
      return this.terminal.services.some((service) => service.sessions && service.sessions.length > 0);
    }

    return false;
  }

  get areSlotsAvailable() {
    this.terminal.services.forEach(service => this.hasSlot = service.sessions.some(this.slotIsEmpty));
    return this.hasSlot;
  }

  slotIsEmpty(session: Session) {
    return session.hasSlotsLeft;
  }

  get isNike() {
    return this.terminal.provider.id === environment.nike.providerId;
  }

  get isQueuesNoel() {
    return this.terminal.provider.id === environment.phoneNoelProviderId || this.terminal.provider.id === environment.queueNoelProviderId;
  }

  get isPhoneNoel() {
    return this.terminal.provider.id === environment.phoneNoelProviderId;
  }

  get isQueueNoel() {
    return this.terminal.provider.id === environment.queueNoelProviderId;
  }

  get hasCustomFields() {
    return this.fields && this.fields.length > 0;
  }

  get isValid() {
    return this.formGroup.valid;
  }

  get displayOptions() {
    return this.showOptions;
  }

  get allowPriority() {
    return this.terminal.allowPriority;
  }
}
