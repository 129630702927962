<h1 class="page-title unselectable">
  {{ "general.pages.track.details.ticketStatusMessage" | translate }}
</h1>
<h1 class="page-title__content unselectable">
  {{ status | translate }}
</h1>
<div class="details">
  <div class="details__header unselectable">
    <mat-icon class="header__img" style="font-size: 25px">assignment</mat-icon>
    <span class="header__title">{{
      "general.pages.track.details.ticketDetails" | translate
    }}</span>
  </div>
  <div class="details__item">
    <span class="item__title">{{"general.pages.track.details.service" | translate}}</span>
    <span class="item__content">{{ ticket.serviceName }}</span>
  </div>
  <div class="details__item escuro">
    <!-- bilhete -->
    <span class="item__title">{{
      "general.pages.track.details.ticket" | translate
    }}</span>
    <span class="item__content">{{ ticket.friendlyCode }}</span>
  </div>
  <div class="details__item">
    <span class="item__title smartcode">Smart Code</span>
    <span class="item__content smartcode-content"
      >SC-{{ ticket.smartCode }}</span
    >
  </div>
  <div *ngIf="!ticket.prevision" class="details__item escuro last">
    <span class="item__title">{{
      "general.pages.track.details.session" | translate
    }}</span>
    <span class="item__content">{{
      session.start.toString()
        | timezoneFormat: ticket.timezoneId
        | dateFormat
          : {
              format: "LLL",
              locale: currentLang
            }
    }}</span>
  </div>
  <div *ngIf="ticket.prevision" class="details__item escuro">
    <span class="item__title">{{
      "general.pages.track.details.session" | translate
    }}</span>
    <span class="item__content">{{
      session.start.toString()
        | timezoneFormat: ticket.timezoneId
        | dateFormat
          : {
              format: "LLL",
              locale: currentLang
            }
    }}</span>
  </div>
  <div *ngIf="ticket.prevision" class="details__item last">
    <span class="item__title">{{
      "general.pages.track.details.prevision" | translate
    }}</span>
    <span class="item__content"
      >{{ "general.pages.track.details.at" | translate }}
      {{
        ticket.prevision
          | timezoneFormat: ticket.timezoneId
          | dateFormat: { format: "LT", locale: currentLang }
      }}</span
    >
  </div>
</div>
<div class="more-details">
  <div class="more-details__header unselectable" (click)="toggleMoreDetails()">
    <mat-icon
      style="font-size: 30px; font-weight: 700"
      class="more-details__header__img"
      >add</mat-icon
    >
    <span class="more-details__header__title">{{
      "general.pages.track.details.moreDetails" | translate
    }}</span>
    <mat-icon
      style="font-size: 25px; font-weight: 700"
      class="more-details__header__arrow"
      >expand_more</mat-icon
    >
  </div>
  <div class="more-details__body">
    <div *ngIf="ticket.resource" class="more-details__body__item escuro">
      <span class="item__title">{{
        "general.pages.track.details.professional" | translate
      }}</span>
      <span class="item__content">{{ ticket.resource }}</span>
    </div>
    <div *ngIf="ticket.resource" class="more-details__body__item">
      <span class="item__title">{{
        "general.pages.track.details.priority" | translate
      }}</span>
      <span class="item__content">{{ priority | translate }}</span>
    </div>
    <div *ngIf="!ticket.resource" class="more-details__body__item escuro">
      <span class="item__title">{{
        "general.pages.track.details.priority" | translate
      }}</span>
      <span class="item__content">{{ priority | translate }}</span>
    </div>
    <div *ngIf="ticket.resource" class="more-details__body__item escuro">
      <span class="item__title">Status</span>
      <span class="item__content">{{ status | translate }}</span>
    </div>
    <div
      *ngIf="!ticket.resource"
      class="more-details__body__item"
      style="
        border-bottom-width: 2px;
        border-bottom-style: solid;
        border-color: var(--cinza);
      "
    >
      <span class="item__title">Status</span>
      <span class="item__content">{{ status | translate }}</span>
    </div>
    <div class="more-details__body__local-title unselectable">
      <mat-icon class="smaller-icon">location_on</mat-icon>
      <span class="local-title__text">{{
        "general.pages.track.details.location" | translate
      }}</span>
    </div>
    <div class="more-details__body__local-texts">
      <div class="local-texts__text">
        {{ ticket.providerName }} - {{ ticket.serviceName }}
      </div>
      <div class="local-texts__text">
        {{ ticket.locationAddress }}, {{ ticket.locationCity }} -
        {{ ticket.locationUF }}
      </div>
    </div>
    <div class="agmBox">
      <google-map [center]="center" zoom="15">
      </google-map>
    </div>
    <div class="acompanhe">
      <div class="acompanhe__title unselectable">
        <mat-icon class="acompanhe__title__img smaller-icon"
          >smartphone</mat-icon
        >
        <span class="acompanhe__title__text">{{
          "general.pages.track.details.follow" | translate
        }}</span>
      </div>
      <div class="acompanhe__content">
        <qrcode
          id="qrcode-01"
          class="content__qrcode"
          [qrdata]="qrCodeBaseLink"
          (click)="open(qrCodeBaseLink)"
          [width]="200"
          [errorCorrectionLevel]="'M'"
        ></qrcode>
        <qrcode
          id="qrcode-02"
          class="content__qrcode"
          [qrdata]="qrCodeBaseLink"
          (click)="open(qrCodeBaseLink)"
          [width]="120"
          [errorCorrectionLevel]="'M'"
        ></qrcode>
        <qrcode
          id="qrcode-03"
          class="content__qrcode"
          [qrdata]="qrCodeBaseLink"
          (click)="open(qrCodeBaseLink)"
          [width]="100"
          [errorCorrectionLevel]="'M'"
        ></qrcode>
        <div class="content__texto">
          {{ "general.pages.track.details.qrCodePhrase" | translate }}
        </div>
        <img
          id="playstore"
          class="content__app"
          (click)="
            open(
              'https://play.google.com/store/apps/details?id=com.inventione.filazero&hl=pt_BR&gl=US'
            )
          "
          src="assets/images/playstore.svg"
          alt="filazero na playstore"
        />
        <img
          id="appstore"
          class="content__app"
          (click)="open('https://apps.apple.com/br/app/filazero/id1077360648')"
          src="assets/images/appstore.svg"
          alt="filazero na appstore"
        />
      </div>
    </div>
    <div class="historico unselectable">
      <mat-icon class="historico__title__img smaller-icon">history</mat-icon>
      <span class="historico__title__text">{{
        "general.pages.track.details.history" | translate
      }}</span>
    </div>
    <div id="history-container">
      <div class="lastest-update">
        <div id="first" class="history-ball"></div>
        <span class="history-title">{{
          this.convertStatus(this.ticket.activities[0].name) | translate
        }}</span
        ><span class="history-content">{{
          this.ticket.activities[0].at
            | date: "H:mm - dd/MM/yyyy":this.ticket.timezoneId:"en-US"
        }}</span>
      </div>
      <div
        class="other-update"
        *ngFor="let update of this.ticket.activities | slice: 1; let i = index"
      >
        <div class="history-ball"></div>
        <span class="history-title">{{
          this.convertStatus(update.name) | translate
        }}</span
        ><span class="history-content">{{
          update.at | date: "H:mm - dd/MM/yyyy":this.ticket.timezoneId:"en-US"
        }}</span>
      </div>
    </div>
  </div>
</div>
