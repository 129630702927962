<app-header
  [bgColor]="'#007E78'"
  [logoUrl]="'assets/images/boticario-white.png'"
  [logoHeight]="'12px'"
  [showText]="true"
>
</app-header>
<app-secondary-header
  [icon]="'access_time'"
  [text]="ticket.prevision | timezoneFormat: ticket.timezoneId | dateFormat: { format: 'LL', locale: currentLang }"
  [isClickable]="false"
  [isBoticario]="true"
>
</app-secondary-header>
<div class="container">

  <content *ngIf="isLoading">
    <section class="wrapper-center">
      <mat-spinner [diameter]="40"></mat-spinner>
    </section>
  </content>

  <section *ngIf="ticket.status == 'Checked' && !isLoading" class="waiting">
    <p *ngIf="ticket.position != 1" class="title" [innerHTML]="'boticario.pages.track.info1' | translate"></p>
    <h3 *ngIf="ticket.position == 1" class="alert">{{ 'boticario.pages.track.info2' | translate }}</h3>
    <p class="subtitle">{{ 'boticario.pages.track.ticketCode' | translate }}</p>
    <p class="center number-position">{{ ticket.friendlyCode }}</p>
    <p class="subtitle">{{ 'boticario.pages.track.queuePosition' | translate }}</p>
    <p id="queuePosition" class="center number-position">{{ticket.position}}</p>
    <p *ngIf="ticket.position == null" class="center number-position">--</p>
    <p class="subtitle">{{ 'boticario.pages.track.previsionInfo' | translate }}</p>

    <div class="center timer">
      <p id="prevision">{{ ticket.prevision | timezoneFormat: ticket.timezoneId | dateFormat: { format: 'LL', locale: currentLang } }}</p>
    </div>
  </section>

  <div *ngIf="ticket.status == 'Checked' && !isLoading" class="fixed-footer">
    <div class="leave">
      <button class="btn-leave" (click)="openExitAlertModal(); exitQueueEvent()" mat-flat-button color="warn">
        {{ 'boticario.actions.exitQueue' | translate }}
      </button>
    </div>
  </div>

  <section *ngIf="(ticket.status === 'Called' || ticket.status === 'Started') && !isLoading" class="call">
    <h3 class="resource-title alert flex-2">{{ 'boticario.pages.track.itsYourTurn' | translate }}</h3>

    <p class="resource flex-1">{{ticket.resource}}</p>
    <div class="flex-1">&nbsp;</div>
  </section>

  <div *ngIf="(ticket.status === 'Called' || ticket.status === 'Started') && !isLoading" class="fixed-footer">
    <div class="start-button">
      <button class="btn btn-outline" (click)="backStart()" mat-flat-button color="primary">
        <mat-icon>keyboard_backspace</mat-icon>
        {{ 'boticario.actions.start' | translate }}
      </button>
    </div>
  </div>

  <section *ngIf="isCanceled() && !isLoading" class="canceled">
    <p>{{ 'boticario.pages.track.ticketWasCanceled' | translate }}</p>
    <button class="btn" mat-flat-button color="primary" (click)="backStart()">
        <mat-icon>keyboard_backspace</mat-icon>
        {{ 'boticario.actions.start' | translate }}
    </button>
  </section>
</div>
