<div class="warning">
  <mat-icon class="icon">warning</mat-icon>
</div>
<div mat-dialog-content [innerHTML]="'components.exitAlertModal.message' | translate"></div>
<div class="flex" mat-dialog-actions>
  <button class="flex-1" mat-flat-button color="success" (click)="closeDialog()" cdkFocusInitial>
    {{ 'general.actions.stay' | translate | uppercase }}
  </button>
  <button class="flex-1" mat-flat-button color="warn" (click)="cancelTicket()">
    {{ 'general.actions.exitQueue' | translate | uppercase }}
  </button>
</div>
