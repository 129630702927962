<nav>
  <div class="navigator">
    <div>
      <button mat-menu-item (click)="next()">
        <mat-icon
          style="color: white"
          matBadge="&#8288;"
          [matBadgeHidden]="shouldHideBadge()"
          matBadgeColor="warn"
          matBadgeSize="small"
          >receipt
        </mat-icon>
        <span style="color: white">{{
          "general.components.header.ticketList" | translate
        }}</span>
      </button>
    </div>
    <div>
      <button mat-menu-item [matMenuTriggerFor]="language">
        <mat-icon style="color: white">translate</mat-icon>
        <span style="color: white">{{
          "general.components.header.language" | translate
        }}</span>
      </button>
    </div>
    <div>
      <button mat-menu-item (click)="goHome()">
        <mat-icon style="color: white">add</mat-icon>
        <span style="color: white">
          {{ "general.actions.enterNewQueue" | translate }}</span
        >
      </button>
    </div>
  </div>
  <mat-menu #language="matMenu">
    <button
      mat-menu-item
      *ngFor="let lang of langs"
      (click)="setLang(lang.code)"
      [disabled]="currentLang === lang.code"
    >
      <span>{{ lang.name }}</span>
    </button>
  </mat-menu>
</nav>
