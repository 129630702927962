<app-header
  [bgColor]="'#7604C2'"
  [logoUrl]="'assets/images/fz-logo-white.svg'"
></app-header>

<div id="container" class="container">
  <div class="message-image-container">
    <svg xmlns="http://www.w3.org/2000/svg" width="128" height="126.844">
      <path
        d="M18.777 18.795H58.85v40.097H18.777zm48.15 48.172h16v16.006h-16zm24.078 24.078h16v16.006h-16z"
        fill="none"
        stroke="#7604C2"
        stroke-width="5.406"
      />
      <path
        d="M93.642 69.612h10.702v10.702H93.642zM69.566 93.706h10.702v10.702H69.566z"
        fill="#7604C2"
        fill-rule="evenodd"
      />
      <g stroke="#7604C2">
        <g stroke-width="5.406">
          <path d="M18.777 66.967H58.85v40.1H18.777z" fill="none" />
          <path
            d="M29.477 77.692H48.15v18.65H29.477zm48.155-48.195h18.673V48.18H77.632z"
            fill="#7604C2"
          />
        </g>
        <g fill="none">
          <path
            d="M1.5 22.495V1.5h21.8m82.228.003h20.97v21.83M24.546 125.343H3.578v-21.83m121.692 0v21.78h-21.8"
            stroke-width="3.003"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path d="M66.934 18.79h40.07v40.097h-40.07z" stroke-width="5.406" />
        </g>
        <path
          d="M29.457 29.497H48.13v18.65H29.457z"
          fill="#7604C2"
          stroke-width="5.406"
        />
      </g>
    </svg>
    <div class="message-container">
      <h2>{{ "general.pages.home.scanTutorial" | translate }}</h2>
    </div>
  </div>
</div>

<div class="footer-fixed">
  <div class="button-next">
    <button mat-flat-button (click)="next()" class="btn">
      <span>
        {{ "general.actions.scan" | translate }}
      </span>
    </button>
  </div>
</div>
