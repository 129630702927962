<div class="container">
  <div class="information">
    <!--<qrcode
      [elementType]="elementType"
      [errorCorrectionLevel]="correctionLevel"
      [qrdata]="expressLink"
      [width]="500"
    ></qrcode> !-->

    <div class="title">
      <p>{{ 'general.display.freedom' | translate }}</p>
    </div>
    <div class="subtitle">
      <p>{{ 'general.display.instructions' | translate }}</p>
    </div>
    <div class="follow">
      <p>{{ 'general.display.follow' | translate }}</p>
    </div>
    <div class="subtitle">
      <p>{{ 'general.display.link' | translate }}</p>
    </div>
  </div>
  <div class="context">
    <img [src]="providerLogo">
  </div>
</div>