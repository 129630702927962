import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Service, Terminal, TerminalPayload } from '../models';

@Injectable()
export class TerminalService {
  private urlApi: string = environment.urlApi;

  constructor(private http: HttpClient) { }

  getTerminal(accessKey: string): Observable<any> {
    return this.http
      .get<any>(`${this.urlApi}api/terminals/${accessKey}`);
  }

  getCurrentTerminal(): Terminal {
    return JSON.parse(localStorage.getItem('currentTerminal'));
  }

  setCurrentTerminal(terminal: Terminal): void {
    localStorage.setItem('currentTerminal', JSON.stringify(terminal));
  }

  getCurrentTerminalPayload(): TerminalPayload {
    return JSON.parse(localStorage.getItem('currentTerminalPayload'));
  }

  setCurrentTerminalPayload(payload: TerminalPayload): void {
    localStorage.setItem('currentTerminalPayload', JSON.stringify(payload));
  }

  initPayload(pid: number, locationId: number, accessKey: string, id: number): void {
    const payload = {
      terminalSchedule: {
        id,
        sessions: undefined,
        publicAccessKey: accessKey,
      },
      pid,
      locationId,
      serviceId: undefined,
      customer: {
        name: undefined,
        phone: undefined,
        email: undefined
      },
      recaptcha: undefined,
      priority: undefined,
      metadata: []
    };

    localStorage.setItem('currentTerminalPayload', JSON.stringify(payload));
  }

  booking(payload: TerminalPayload): Observable<any> {
    return this.http
      .post<any>(`${this.urlApi}v2/ticketing/tickets/booking-express`, payload);
  }

  providerAvailableServices(services: Service[], showOptionsBasedOnSessions: boolean, currentDate: Date): Service[] {
    return services.filter((service) => this.isServiceAvailable(showOptionsBasedOnSessions, service, currentDate));
  }

  getService(id: number): Observable<any> {
    return this.http
      .get<any>(`${this.urlApi}api/services/${id}`);
  }

  getTemplate(slug: string): Observable<any> {
    return this.http
      .get<any>(`${this.urlApi}api/companies/${slug}/template`);
  }
  
  private isServiceAvailable(showOptionsBasedOnSessions: boolean, service: Service, currentDate: Date): boolean {
    if (!service.sessions || service.sessions.length === 0) {
      return false;
    }

    if (showOptionsBasedOnSessions) {
      for (const session of service.sessions) {
        const start = new Date(session.start)
        const end = new Date(session.end);
        if (this.hasStarted(start, currentDate) && !this.hasEnded(currentDate, end)) {
          return true;
        }
      }
    } else {
      for (const session of service.sessions) {
        const end = new Date(session.end);
        if (!this.hasEnded(currentDate, end)) {
          return true;
        }
      }
    }
    
    return false;
  }
  
  private hasStarted(startTime: Date, currentDate: Date): boolean {
    return startTime.getTime() <= currentDate.getTime();
  }

  private hasEnded(currentDate: Date, endTime: Date): boolean {
    return currentDate.getTime() > endTime.getTime();
  }
}
