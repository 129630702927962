<app-header
  [bgColor]="template?.templateColor || templateAux?.templateColor ||'#7604C2'"
  [logoUrl]="template?.logoUrl|| templateAux?.logoUrl || 'assets/images/fz-logo-white.svg'"
></app-header>
<app-secondary-header
  [icon]="'access_time'"
  [text]="ticket.prevision | timezoneFormat: ticket.timezoneId | dateFormat: { format:'LL', locale: currentLang }"
  [isClickable]="false"
  [isBoticario]="false"
  [reduceHeight]="true"
  [bgColor]="brighten(template?.templateColor || templateAux?.templateColor || '#7604C2', 20)"
>
</app-secondary-header>
<section class="container">

  <content *ngIf="isLoading || feedbackDone">
    <section class="wrapper-center">
      <mat-spinner [diameter]="40"></mat-spinner>
    </section>
  </content>

  <!-- Checked -->
  <section *ngIf="ticket.status == 'Checked' && !isLoading" class="waiting">
    <h3 *ngIf="!isNike && ticket.position !== 1" class="title">{{ 'general.pages.track.info1' | translate }}</h3>
    <h3 *ngIf="!isNike && !isQueuesNoel && ticket.position === 1 && !isVideoConference" class="title alert">{{ 'general.pages.track.info2' | translate }}</h3>
    <h3 *ngIf="!isNike && !isQueuesNoel && ticket.position === 1 && isVideoConference" class="title alert">{{ 'general.pages.track.meetInfo2' | translate }}</h3>
    <h3 *ngIf="isQueuesNoel && ticket.position === 1" class="title alert">{{ 'general.pages.track.infoNoel' | translate }}</h3>
    <h3 *ngIf="isNike && ticket.position > 5" class="title">{{ 'general.pages.track.info1' | translate }}</h3>
    <h3 *ngIf="isNike && ticket.position <= 5 && !isVideoConference" class="title alert">{{ 'general.pages.track.info2' | translate }}</h3>
    <h3 *ngIf="isNike && ticket.position <= 5 && isVideoConference" class="title alert">{{ 'general.pages.track.meetInfo2' | translate }}</h3>

    <section class="current-store">
      <h3>
        {{ ticket.locationName | uppercase}}
      </h3>
    </section>

    <section class="position-container">
      <app-track-information
        [ticketPosition]="ticket.position"
        [prevision]="ticket.prevision | timezoneFormat: ticket.timezoneId | relativeTimeFormat | async"
        [ticketCode]="ticket.friendlyCode"
      >
      </app-track-information>
    </section>

    <a *ngIf="isNike"[href]="nikeUrl" target="_blank" class="nike-store">
      <mat-card appearance="outlined">
        <mat-card-content>{{ nikeAdsMsg }}</mat-card-content>
      </mat-card>
    </a>

    <div class="nike-info" *ngIf="isNike" [style.color]="template?.templateColor || templateAux?.templateColor || '#000000'">
      <span>
        Use o cupom que será emitido no caixa<br>
        Converse com um dos atendentes para mais informações.
      </span>
    </div>

    <div *ngIf="service.lobbyContent" [innerHtml]="service.lobbyContent | safeHtml" class="lobby-content" ></div>
  </section>
  <div *ngIf="ticket.status === 'Checked' && !isLoading" class="fixed-footer">
    <div class="leave">
      <button class="btn-leave" (click)="openExitAlertModal()" mat-flat-button color="warn">
        {{ 'general.actions.exitQueue' | translate }}
      </button>
    </div>
  </div>

  <!-- Called / Started -->
  <section *ngIf="(ticket.status === 'Called' || ticket.status === 'Started') && !isLoading" class="call">
    <div class="meet" *ngIf="isVideoConference">
      <h3>{{ 'general.pages.track.meetInfo3' | translate }}</h3>
      <div class="icon">
        <mat-icon [inline]="true" >videocam</mat-icon>
      </div>
      <div>
        <a target="_blank" href="{{urlMeet}}">{{ 'general.pages.track.meetLink' | translate }}</a>
      </div>
    </div>
    <div *ngIf="!isVideoConference">
      <h3 class="resource-title alert">{{ 'general.pages.track.itsYourTurn' | translate }}</h3>

      <p class="resource" [style.color]="template?.templateColor || templateAux?.templateColor || '#000000'">{{ticket.resource}}</p>

      <p class="subtitle" [style.color]="template?.templateColor || templateAux?.templateColor || '#000000'">{{ 'general.pages.track.ticketCode' | translate }}</p>
      <p class="center number-position" [style.color]="template?.templateColor || templateAux?.templateColor || '#000000'">{{ ticket.friendlyCode }}</p>
      <div class="flex-1">&nbsp;</div>
    </div>
  </section>
  <div *ngIf="(ticket.status === 'Called' || ticket.status === 'Started') && !isLoading" class="fixed-footer">
    <div
      *ngIf="ticket.status !== 'Started'"
      class="start-button"
    >
      <button
        [style.color]="brighten(template?.templateColor || templateAux?.templateColor || '#7604C2', 20)"
        class="btn btn-outline"
        (click)="openExitAlertModal()"
        mat-flat-button
        color="primary"
      >
        <mat-icon>keyboard_backspace</mat-icon>
        {{ 'general.actions.backToStart' | translate }}
      </button>
    </div>
  </div>

  <!-- Completed -->
  <section class="feedback" *ngIf="isCompleted && !feedbackDone">
    <div [formGroup]="feedbackForm">
      <h3 class="feedback-title">{{ 'general.pages.track.feedback.title' | translate }}</h3>
      <ngb-rating [formControl]="rateCtrl" max="5" [style.color]="'gold'"></ngb-rating>
      <mat-form-field class="input">
        <textarea [ngStyle]="{'resize': 'none'}" placeholder="{{ 'general.pages.track.feedback.placeholder' | translate }}" matInput formControlName="comment"></textarea>
      </mat-form-field>
      <button
        class="btn"
        (click)="onSubmitFeedback()"
        [disabled]="!feedbackForm.valid || isFeedbackLoading"
        mat-raised-button
        [style.background-color]="brighten(template?.templateColor || templateAux?.templateColor || '#7604C2', 20)"
        color="primary">
          {{ 'general.actions.send' | translate }}
      </button>
      <button (click)="goToTickets()" class="btn" mat-flat-button style="margin-bottom: 0;">{{ 'general.actions.ticketList' | translate }}</button>
      <button *ngIf="childTicketKey" (click)="goToChildTicket()" class="btn" mat-flat-button style="margin-top: 0; margin-bottom: 0;">Continuar atendimento</button>
    </div>
  </section>

  <!-- Authorized / Reopened / Confirmed -->
  <section class="ticket-details-container" *ngIf="!isLoading && (isReopened || isConfirmed || isAuthorized)">
    <app-ticket-details
      [ticket]="ticket"
      [session]="session"
      [location]="location"
      [terminal]="terminal"
    ></app-ticket-details>
  </section>

  <section class="lobby__fixed-footer" *ngIf="!isLoading && !isSessionClosed && (isReopened || isConfirmed || isAuthorized)">
      <button
        [style.backgroundColor]="brighten(template?.templateColor || templateAux?.templateColor || '#7604C2', 20)"
        class="btn" (click)="openExitAlertModal()" mat-flat-button color="warn"
      >
        {{ 'general.actions.cancel' | translate }}
      </button>
      <button
        [style.backgroundColor]="brighten(template?.templateColor || templateAux?.templateColor || '#7604C2', 20)"
        *ngIf="!isConfirmed"class="btn" (click)="confirm()" mat-flat-button color="primary"
      >
        {{ 'general.actions.confirm' | translate }}
      </button>
      <button
        [style.backgroundColor]="brighten(template?.templateColor || templateAux?.templateColor || '#7604C2', 20)"
        *ngIf="isConfirmed" class="btn" (click)="check()" mat-flat-button color="primary" [disabled]="!canChekin"
      >
        {{ 'general.actions.getInLine' | translate }}
      </button>
  </section>

  <!-- Empty State -->
  <section class="empty-state" *ngIf="!isLoading && !isValidStatus">
    <app-empty-state
      [color]="template?.templateColor || templateAux?.templateColor || '#7604C2'"
      icon="error_outline"
      message="{{'general.pages.track.invalidStatus' | translate}}">
      <div class="fixed-footer">
        <div
          class="start-button"
        >
          <button
            [style.color]="brighten(template?.templateColor || templateAux?.templateColor || '#7604C2', 20)"
            class="btn btn-outline" (click)="goHome()" mat-flat-button color="primary"
          >
            <mat-icon>add</mat-icon>
            {{ "general.actions.enterNewQueue" | translate }}
          </button>
        </div>
      </div>
    </app-empty-state>
  </section>

</section>

<!-- Canceled -->
<section *ngIf="isCanceled && !isLoading" class="canceled">
  <h3>{{ 'general.pages.track.ticketWasCanceled' | translate }}</h3>
  <div class="back">
    <button [style.background-color]="brighten(template?.templateColor || templateAux?.templateColor || '#7604C2', 20)" class="btn" mat-flat-button color="primary" (click)="goToTickets()">
      <mat-icon>keyboard_backspace</mat-icon>
      {{ 'general.actions.ticketList' | translate }}
    </button>
  </div>
</section>

<app-embed-chat
  *ngIf="!isLoading && (!!terminal?.location.embedChat || !!service?.embedChat)"
  [embedChat]="terminal?.location.embedChat || service?.embedChat"
></app-embed-chat>
