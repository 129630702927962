<div class="container">
  <div class="info-position">
    <div>
      <p class="track-number">{{ ticketPosition }}</p>
    </div>
    <div>
      <p class="waiting-info">{{ 'general.components.trackInformation.position' | translate }}</p>
    </div>
  </div>
  <div class="info-prevision">
    <div>
      <p class="track-number">{{ prevision }}</p>
    </div>
    <div>
      <p class="waiting-info">{{ 'general.components.trackInformation.forecast' | translate }}</p>
    </div>
  </div>
  <div class="info-code">
    <div>
      <p class="track-number">{{ ticketCode }}</p>
    </div>
  </div>
</div>
