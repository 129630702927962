import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-service-list-wrapper',
  templateUrl: './service-list-wrapper.component.html',
  styleUrls: ['./service-list-wrapper.component.scss']
})
export class ServiceListWrapperComponent implements OnInit {
  @Input() displayOptions: boolean;
  @Input() isLoadingEmit: boolean;
  constructor() { }

  ngOnInit(): void {
  }

}
