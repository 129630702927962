<app-header
  [bgColor]="'#007E78'"
  [logoUrl]="'assets/images/boticario-white.png'"
  [logoHeight]="'12px'"
  [showText]="true"
>
</app-header>
<app-secondary-header
  icon="keyboard_backspace"
  [text]="'boticario.pages.schedule.title' | translate"
  [isClickable]="true"
  [isBoticario]="true"
  (onBackClick)="backEvent()"
>
</app-secondary-header>

<section *ngIf="!isLoading && hasServices && hasSessions">
  <section class="services">
    <h4>{{ 'boticario.pages.schedule.howDoYouPreferToBeServed' | translate }}</h4>

    <section class="schedule-wrapper">
      <div class="input-wrapper">
        <div class="queue-dropdown">
          <p>{{ 'boticario.pages.schedule.form.service.title' | translate }}</p>
          <mat-form-field
            class="service-dropdown"
            appearance="outline"
          >
            <mat-label>{{ 'boticario.pages.schedule.form.service.label' | translate }}</mat-label>
            <mat-select
              (selectionChange)="selectService($event.value)"
            >
              <mat-option
                *ngFor="let service of terminal.services"
                [value]="service.id"
              >
                {{ service.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="name-input">
          <label for="">{{ 'boticario.pages.schedule.form.name.title' | translate }}</label>
          <mat-form-field
            class="input"
            appearance="outline"
          >
            <mat-label>{{ 'boticario.pages.schedule.form.name.label' | translate }}</mat-label>
            <input
              #name
              (focus)="onFocus($event, name)"
              matInput
              [(ngModel)]="payload.customer.name"
            />
          </mat-form-field>
        </div>
        <div class="priority-checkbox">
          <div class="check-option">
            <mat-checkbox [(ngModel)]="isPriority" (click)="checkPreferentialEvent()">
              <mat-icon
                *ngIf="currentLang === 'pt-PT'"
                class="material-icons-outlined"
                id="report-icon"
              >
                accessible
              </mat-icon>
              {{ 'boticario.pages.schedule.form.preferential' | translate }}
              <mat-icon
                class="material-icons-outlined"
                id="report-icon"
                (click)="tooltipPreferentialEvent()"
              >
                report
              </mat-icon>
            </mat-checkbox>
          </div>
        </div>
        <div class="info-card">
          <div class="card-icon">
            <mat-icon
              class="material-icons-outlined"
              id="releases-icon"
            >
              new_releases
            </mat-icon>
          </div>
          <div class="card-text">
            {{ 'boticario.pages.schedule.warningTerms.firstSentence' | translate }}
            <a href="{{ '/assets/pdfs/' + currentLang + '/termos_de_uso.pdf' }}" target="_blank">{{ 'boticario.pages.schedule.warningTerms.termsOfUse' | translate }}</a>
            {{ 'boticario.pages.schedule.warningTerms.and' | translate }}
            <a href="{{ '/assets/pdfs/' + currentLang + '/politica_privacidade.pdf' }}" target="_blank">{{ 'boticario.pages.schedule.warningTerms.privacyPolicy' | translate }}</a>.

          </div>
        </div>
      </div>
      <div class="fixed-footer">
        <div class="button-next">
          <button
            class="btn"
            [class.spinner]="isLoadingEmit"
            mat-flat-button
            color="primary"
            [disabled]="!isValid || isLoadingEmit"
            (click)="continueEvent()"
          >
            <span class="button-flex">
              <span class="item-invisible"></span>
              <span class="button-content">
                {{ 'boticario.actions.confirm' | translate }}
              </span>
              <span class="material-icons" id="icon-image">
                arrow_right_alt
              </span>
            </span>
          </button>
        </div>
      </div>
    </section>
  </section>

</section>

<app-empty-state
  *ngIf="!isLoading && (!hasServices || !hasSessions)"
  color="#007e78"
  icon="error_outline"
  message="{{ 'boticario.pages.schedule.noServices' | translate }}"
></app-empty-state>
