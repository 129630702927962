import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home/home.component';
import { RouterModule } from '@angular/router';
import { FilazeroRoutes } from './filazero.routing';
import { TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ComponentsModule } from '../components/components.module';
import { MaterialModule } from '../material/material.module';
import { QrCodeScanComponent } from './qr-code-scan/qr-code-scan.component';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { StartComponent } from './start/start.component';
import { PipeModule } from '../shared/pipe/pipe.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ScheduleComponent } from './schedule/schedule.component';
import { CustomFieldsModule } from '../modules/custom-fields/custom-fields.module';
import { TicketComponent } from './ticket/ticket.component';
import { TrackComponent } from './track/track.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LightboxModule } from 'ngx-lightbox';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    HomeComponent,
    StartComponent,
    ScheduleComponent,
    TrackComponent,
    TicketComponent,
    QrCodeScanComponent,
  ],
  imports: [
    CommonModule,
    ComponentsModule,
    MaterialModule,
    PipeModule,
    FormsModule,
    ReactiveFormsModule,
    ZXingScannerModule,
    CustomFieldsModule,
    NgbModule,
    RouterModule.forChild(FilazeroRoutes),
    LightboxModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateModule,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ]
})
export class FilazeroModule { }
