import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Terminal, TerminalPayload } from '../../shared/models';
import { TerminalService } from '../../shared/services/terminal.service';
import { StorageService } from '../../shared/services';
import { MatDialog } from '@angular/material/dialog';
import { brighten } from '../../shared/utils/colors';
import { TermsModalComponent } from '../../components/terms-modal/terms-modal.component';
import { TermsNoelModalComponent } from '../../components/terms-noel-modal/terms-noel-modal.component';
import { environment } from 'src/environments/environment';
import { finalize } from 'rxjs/operators';
import { PrivacyPolicyModalComponent } from '../../components/privacy-policy-modal/privacy-policy-modal.component';

@Component({
  selector: 'app-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.scss']
})
export class StartComponent implements OnInit {
  isChecked = false;
  terminal: Terminal;
  payload: TerminalPayload;
  isLoading = true;
  container: HTMLElement;

  constructor(
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private storageService: StorageService,
    private terminalService: TerminalService,
    private router: Router
  ) { }

  ngOnInit() {
    this.payload = {} as TerminalPayload;
    this.route.paramMap
      .subscribe(params => {
        const accessKey = params.get('accessKey');

        localStorage.setItem('terminalKey', accessKey);

        if (accessKey !== undefined) {
          this.terminalService.getTerminal(accessKey)
            .pipe(finalize(() => {
              this.isLoading = false

              const interval = setInterval(() => {
                this.container = document.getElementById('welcome-wrapper');
                if (this.container) {
                  const height = window.innerHeight;
                  this.container.style.height = `calc(${height}px - (64px + 40px))`;
                  clearInterval(interval);
                }
              }, 250);
            }))
            .subscribe(terminal => {
              if (!(terminal.messages && terminal.messages[0].type === 'ERROR')) {
                this.terminal = terminal;
                this.storageService.addTerminal(terminal);
                this.storageService.setTemplate({templateColor: this.terminal.headerBackgroundColor, logoUrl: this.terminal.providerLogoUrl })
                this.terminalService.initPayload(terminal.provider.id, terminal.location.id, accessKey, terminal.id);
                this.payload = this.terminalService.getCurrentTerminalPayload();
              }
            });
        }
      });
  }

  get isValid() { return this.isChecked; }

  next(): void {
    this.terminalService.setCurrentTerminalPayload(this.payload);
    this.router.navigate(['/schedule']);
  }

  brighten(color: string, amount: number): string {
    return '#' + brighten(color, amount);
  }

  get isPhoneNoel() {
    return this.terminal.provider.id === environment.phoneNoelProviderId;
  }

  openTermsNoelModal() {
    this.dialog.open(TermsNoelModalComponent, {
      minWidth: '100vw',
      height: '100vh'
    });
  }

  openTermsModal() {
    this.dialog.open(TermsModalComponent, {
      minWidth: '100vw',
      height: '100vh'
    });
  }

  openPrivacyPolicyModal() {
    this.dialog.open(PrivacyPolicyModalComponent, {
      minWidth: '100vw',
      height: '100vh'
    })
  }
}
