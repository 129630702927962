import { Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { FieldBase } from './models/field-base.model';
import { CustomFieldValidator } from './validator/custom-field-validator';

@Injectable({
  providedIn: 'root'
})
export class FieldControlService {

  constructor() {
  }

  makeFormGroup(fields: FieldBase[]): UntypedFormGroup {
    const group: any = {};

    fields.forEach(field => {

      if (field.required) {
        if (field.type === 'cpf') {
          group[field.title] = new UntypedFormControl('',
            Validators.compose([
              Validators.required,
              Validators.minLength(11),
              CustomFieldValidator.isValidCpf()
            ])
          );
        } else if (field.type === 'select') {
          group[field.title] = new UntypedFormControl('',
            Validators.compose([
              Validators.required,
            ])
          );
        } else if (field.type === 'cnpj') {
          group[field.title] = new UntypedFormControl('',
            Validators.compose([
              Validators.required,
              Validators.minLength(14),
              CustomFieldValidator.isValidCnpj()
            ])
          );
        } else {
          group[field.title] = new UntypedFormControl('',
            Validators.compose([
              Validators.required,
              Validators.maxLength(field.maxLength)
            ])
          );
        }
      } else {
        if (field.type === 'cpf') {
          group[field.title] = new UntypedFormControl('',
            Validators.compose([
              CustomFieldValidator.isValidCpf()
            ])
          );
        } else if (field.type === 'cnpj') {
          group[field.title] = new UntypedFormControl('',
            Validators.compose([
              CustomFieldValidator.isValidCnpj()
            ])
          );
        } else if (field.type === 'select') {
          group[field.title] = new UntypedFormControl('', []);
        } else {
          group[field.title] = new UntypedFormControl('',
            Validators.compose([
              Validators.maxLength(field.maxLength)
            ])
          );
        }
      }
    });
    return new UntypedFormGroup(group);
  }
}
