import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-sound-alert',
  templateUrl: './sound-alert.component.html',
  styleUrls: ['./sound-alert.component.scss']
})
export class SoundAlertComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<SoundAlertComponent>) {
    this.dialogRef.disableClose = true;
  }

  ngOnInit() {
  }

  closeDialog() {
    const audio = new Audio();
    this.dialogRef.close(audio);
  }

}
