<section class="modal-container">
  <article class="modal-content">
    <mat-icon class="clickable close" (click)="closeModal()">close</mat-icon>
    <section *ngIf="currentLang === 'pt-BR' || currentLang === 'pt-PT'">
      <h1>Política de Privacidade</h1>
      <article>
        <p>
          Nossa Política de Privacidade explica: quais informações coletamos e
          por que as coletamos. Como usamos essas informações. E com quem e por
          que compartilhamos essas informações.
        </p>

        <p>
          Somos uma startup de gestão de atendimentos e eliminação de filas, que
          visa auxiliar nossos parceiros em melhor atender ao público, reduzir
          custos e entregar ao seu cliente final maior conforto e liberdade
          quando precisar ser atendido.
        </p>

        <p>
          Sua privacidade é importante para nós, portanto, sendo você um
          usuário, por favor reserve um tempo para conhecer nossas práticas. E,
          se você tiver alguma dúvida, consulte esta página ou os Termos de Uso.
        </p>

        <p>
          Fazemos referência à empresa através do uso de termos tais como “app”
          “Filazero” “site”, “nós”, “nosso”, “conosco”, palavras tais como
          “você”, “seu”, e expressões similares referem-se a nossos clientes, ou
          a usuários de nosso site. Ao visitar uma de nossas plataformas e
          usufruir de nossos serviços, você automaticamente aceita as condições
          descritas a seguir, que compõem a Política de Privacidade.
        </p>

        <p>
          <strong>Importante: </strong>Você não é obrigado a conceder
          informações o Filazero. Entretanto, sem conceder certas informações
          quando estas forem solicitadas, não seremos capazes de entregar nossos
          serviços, assim não lhe será permitido o acesso a algumas
          funcionalidades. Coletamos informações para fornecer serviços de
          qualidade a todos nossos usuários, e elas ocorrem da seguinte forma:
        </p>

        <h2>1. Coleta de Informação Pessoal</h2>

        <p>
          O Filazero pode coletar informações de identificação pessoal, como
          nome, sobrenome, e-mail, senha, número de telefone, data de
          nascimento,foto, sexo, CPF, RG ou qualquer outra informação exigida
          durante o acesso a plataforma.
        </p>

        <p>
          Sempre que você acessar e utilizar a nossa plataforma de serviços, o
          Filazero receberá e armazenará automaticamente informações, incluindo
          seu endereço IP e informações de cookies, que serão relacionadas com
          as informações pessoais que você fornece, possibilitando uma
          experiência mais prática e eficiente.
        </p>

        <p>
          Como o Filazero auxilia seus parceiros no gerenciamento de seus
          atendimentos, poderá coletar, ainda, outros dados pessoais além dos
          listados acima, de acordo com as solicitações específicas destes
          parceiros, visando possibilitar que o serviço pretendido pelo usuário
          seja ofertado. Contudo, sempre que isso ocorrer, você será
          cientificado dos dados que serão coletados e deverá concordar com seu
          compartilhamento.
        </p>

        <h2>2. Uso da sua informação pessoal</h2>

        <p>
          O Filazero irá utilizar essas informações para processar e completar
          seu acesso às nossas plataformas. Dessa maneira, de acordo com a Lei
          12.965/2014 (Marco Civil da Internet) e Lei 13.709/2018 (Lei Geral de
          Proteção de Dados), trataremos estas informações para otimizar nossos
          serviços e para realizar o seu cadastro.
        </p>

        <p>
          O Filazero irá disponibilizar suas informações pessoais, sem aviso
          prévio, somente em caso de:
        </p>
        <ol type="a">
          <li>
            legislação, regulamentação, processo legal ou solicitação
            governamental aplicável;
          </li>
          <li>cumprir investigação de possíveis violações;</li>
          <li>fraude ou por segurança;</li>
          <li>
            proteger contra dano aos direitos, a propriedade ou a segurança do
            Filazero, nossos usuários ou o público, conforme solicitado ou
            permitido por lei.
          </li>
        </ol>

        <p>
          O Filazero efetuará o cadastro do usuário, mantendo em seu sistema os
          registros específicos das operações efetuadas. Fazemos isso para que
          você tenha acesso rápido e eficiente aos serviços ofertados pelo
          Filazero, melhorando sua experiência e evitando que você tenha que
          fornecer informações toda vez que for utilizar nossos serviços.
        </p>

        <p>
          Os seus dados serão armazenados. Todos os documentos e informações
          relacionados ao cadastro do Titular permanecerão armazenados em
          segurança na posse de empresa especializada em armazenamento digital
          de dados contratada pelo Filazero (Amazon Web Service), mas apenas o
          Filazero terá acesso a este banco de dados.
        </p>

        <p>
          Utilizamos seus dados para desambiguação de cadastros, chamadas em
          painéis ou ainda para entrar em contato com você através de e-mails,
          SMS ou similares, para que possamos realizar verificação de contas e
          notificações de mudanças, atualizações ou informações técnicas
          referentes aos nossos serviços.
        </p>

        <p>
          Seus dados poderão ser utilizados para que possamos analisar e
          entender nossos usuários, permitindo que possamos lhe fornecer
          conteúdo e serviços personalizados, de acordo com suas preferências, e
          possamos melhorar nossos serviços constantemente.
        </p>

        <h2>3. Compartilhamento de informações com terceiros</h2>

        <p>
          Em razão da natureza do serviço prestado pelo Filazero, é essencial
          que algumas de suas informações sejam compartilhadas com nossos
          parceiros. Quando você decide utilizar o serviço de algum desses
          parceiros, estes solicitarão o Filazero que compartilhe seus dados
          cadastrais e/ou outros dados necessários para concluir a operação.
          Seus dados só serão compartilhados neste caso, e se você tiver
          consentido com esta Política de Privacidade.
        </p>

        <p>
          Para proteger seus dados, cobramos destes parceiros que cumpram
          rigorosamente as normas referentes à proteção de dados pessoais.
          Também impomos fortes restrições sobre como nossos parceiros podem
          usar e divulgar os dados que fornecemos. Sempre que houver suspeita de
          violação, por qualquer um destes parceiros, o Filazero irá tomar todas
          as providências cabíveis para proteger as suas informações.
        </p>

        <p>
          É importante ressaltar, portanto, que para utilizar as plataformas do
          Filazero você precisará concordar que estes parceiros possam, para
          ofertar o serviço que você procura, adquirir informações que você
          compartilha com o Filazero.
        </p>

        <p>
          Excetuado para atender ao fim exclusivo de prestar nossos serviços,
          conforme destacado acima, nós não compartilharemos suas informações
          pessoais com outras empresas, em atenção à Lei 12.965/2014 e Lei
          13.709/2018, salvo com sua expressa autorização ou em caso de ordem
          judicial.
        </p>

        <p>
          Ainda que o Filazero sempre esteja atenta à forma como seus parceiros
          utilizam seus dados, as informações coletadas por esses serviços de
          terceiros estão sujeitas aos termos e políticas próprios, e não a esta
          Política de Privacidade. Por isso, nós não nos responsabilizamos pela
          forma com que terceiros coletam, usam, protegem ou revelam as
          informações que você possa vir a fornecê-los.
        </p>

        <p>
          Sabendo disso, ao utilizar os serviços ofertados pelo Filazero, o
          Titular autoriza a transmissão de alguns de seus dados cadastrais e de
          outros eventuais dados necessários com os parceiros do Filazero, para
          que estes possam processar e realizar o atendimento solicitado pelo
          usuário.
        </p>

        <p>
          O Filazero não irá vender seus dados pessoais em nenhuma
          circunstância.
        </p>

        <h2>4. Restrição de idade</h2>

        <p>
          O Filazero foi idealizada para consumidores maiores de idade. Se você
          tiver menos de 18 anos de idade, não será permitido o envio de suas
          informações pessoais às nossas plataformas para realização de seu
          cadastro. Apesar de indivíduos com menos de 18 anos poderem utilizar o
          serviço, eles só poderão fazê-lo com o envolvimento, a supervisão e
          aprovação dos pais ou responsáveis.
        </p>

        <h2>5. Segurança</h2>

        <p>
          Nós dispomos de medidas de segurança em âmbitos físico, eletrônico e
          administrativo, que protegem os dados pessoais e suas informações.
          Essas medidas de proteção nos auxiliam na prevenção de fraudes e
          acessos não autorizados às informações, bem como na manutenção da
          integridade dos dados. Além disso, dispomos de uma política de
          segurança interna para que funcionários do Filazero não tenham acesso
          a suas informações pessoais, de modo que seus dados permaneçam sempre
          em segurança.
        </p>

        <h2>6. Direitos dos usuários</h2>

        <p>
          Todos os direitos elencados abaixo podem ser exercidos a qualquer
          momento através dos canais de atendimento do Filazero.
        </p>

        <p>
          Acesso. O usuário poderá solicitar informações a respeito de quais
          dados são armazenados e para qual finalidade.
        </p>

        <p>
          Retificação. O usuário poderá solicitar a correção/atualização de
          algum de seus dados pessoais, pois é sua responsabilidade mantê-los
          sempre atualizado.
        </p>

        <p>
          Eliminação. O usuário poderá solicitar a eliminação dos seus dados
          pessoais nas seguintes situações:
        </p>
        <ol type="i">
          <li>
            quando não forem necessários para a finalidade para a qual são
            utilizados
          </li>
          <li>
            quando não estiver mais consentindo com os termos aqui expostos.
          </li>
        </ol>

        <p>
          O Filazero se reserva o direito de bloquear ou cancelar a Conta do
          usuário, excluindo todos os dados vinculadas a esta, em virtude da
          existência de indícios ou de ilícito efetivo identificado, além de
          divergências cadastrais.
        </p>

        <h2>7. Mudanças nesta Declaração</h2>

        <p>
          Em caso de modificação destes termos, para atender melhor seus
          clientes, publicaremos tais alterações da política de privacidade e
          notificaremos sobre esta situação. Também manteremos as versões
          anteriores desta Política de Privacidade arquivadas para você
          compará-las.
        </p>

        <h2>8. Prazo de armazenamento de dados</h2>

        <p>
          O Filazero armazenará seus dados pessoais durante o período necessário
          para a prestação do serviço que ofertamos, ou seja, enquanto sua conta
          permanecer ativa em nossas plataformas.
        </p>

        <p>
          Como destacado anterioremente, os dados poderão ser eliminados a
          pedido do usuário. Para encerrar a sua conta no Filazero, você deverá
          nos contatar por um de nossos canais de atendimento.
        </p>

        <p>
          O Filazero poderá armazenar seus dados durante o período necessário
          para cumprimento de obrigação legal ou regulatória.
        </p>

        <h2>9. Consentimento</h2>

        <p>
          Ao utilizar as plataformas do Filazero, você está consentindo com o
          tratamento dos seus dados nos termos da nossa Política de Privacidade,
          estando ciente dos dados que coletamos, das finalidades desta coleta e
          dos direitos do usuário em relação ao tratamento que realizamos.
        </p>

        <h2>10. Encarregado de Dados</h2>

        <p>
          Durante o período de armazenamento pelo Filazero, haverá um
          responsável exclusivo pelos dados, nos termos do art. 41 da Lei Geral
          de Proteção de Dados. Esta pessoa irá responder pelos dados, caso haja
          necessidade de apresentá-los para autoridade competente.
        </p>

        <p>
          Dados do contato: Hermeval Geraldo de Souza,
          <span>app&#64;filazero.net</span>
        </p>

        <h3>Resumo</h3>

        <p>
          O Filazero respeita a sua privacidade. Quando se conectam a nós,
          queremos que nossos clientes saibam que seus dados pessoais estão em
          segurança, bem como que nós nos dedicamos em levar-lhes uma
          experiência fácil e segura. As informações pessoais armazenadas e
          compartilhadas através de nossas plataformas tem o fim de fornecer aos
          nosso clientes um serviço de qualidade, mas também nos ajudam a
          compreender melhor os interesses destes clientes, sendo que o nosso
          esforço em atendê-los com o melhor serviço possível nunca cessa.
        </p>

        <p>Se, mesmo assim, você ficar com alguma dúvida, entre em contato:</p>
        <p>E-mail: <span>app&#64;filazero.net</span></p>
      </article>
    </section>

    <section *ngIf="currentLang === 'en-US'">
      <h1>Privacy Policy</h1>
      <article>
        <p>
          Our Privacy Policy explains: what information we collect and why we
          collect them. How we use this information. and with whom and by that
          we share this information.
        </p>

        <p>
          We are a service management and queue elimination startup, which aims
          to help our partners to better serve the public, reduce costs and
          deliver greater comfort and freedom to your end customer when you need
          to be attended to.
        </p>

        <p>
          Your privacy is important to us, so being you a user, please take the
          time to learn about our practices. AND, if you have any questions,
          please refer to this page or the Terms of Use.
        </p>

        <p>
          We refer to the company through the use of terms such as "app"
          “Filazero” “site”, “we”, “our”, “us”, words such as “you”, “your”, and
          similar expressions refer to our customers, or to users of our
          website. By visiting one of our platforms and enjoy our services, you
          automatically accept the conditions described below, which make up the
          Privacy Policy.
        </p>

        <p>
          <strong>Important: </strong>You are not required to grant information
          the Filazero. However, without giving certain information when these
          are requested, we will not be able to deliver our services, so you
          will not be allowed access to some functionalities. We collect
          information to provide services. quality to all our users, and they
          occur as follows:
        </p>

        <h2>1. Collection of Personal Information</h2>

        <p>
          Filazero may collect personally identifiable information such as name,
          surname, email, password, phone number, date of birth, photo, gender,
          CPF, RG or any other required information during platform access.
        </p>

        <p>
          Whenever you access and use our service platform, the Filazero will
          automatically receive and store information, including your IP address
          and cookie information, which will be related to the personal
          information you provide, enabling a more practical and efficient
          experience.
        </p>

        <p>
          How Filazero helps its partners in managing their services, may also
          collect other personal data in addition to the listed above, according
          to the specific requests of these partners, aiming to enable the
          service intended by the user be offered. However, whenever this
          happens, you will be aware of the data that will be collected and must
          agree with its sharing.
        </p>

        <h2>2. Use of your personal information</h2>

        <p>
          Filazero will use this information to process and complete your access
          to our platforms. In this way, according to the Law 12,965/2014
          (Internet Civil Mark) and Law 13,709/2018 (General Law of Data
          Protection), we will treat this information to optimize our services
          and to carry out your registration.
        </p>

        <p>
          Filazero will make your personal information available without notice
          prior, only in case:
        </p>
        <ol type="a">
          <li>
            legislation, regulation, legal process or request applicable
            government;
          </li>
          <li>complete investigation of possible violations;</li>
          <li>fraud or for security;</li>
          <li>
            protect against damage to the rights, property or safety of the
            Filazero, our users or the public, as requested or permitted by law.
          </li>
        </ol>

        <p>
          Filazero will register the user, keeping the specific records of the
          operations carried out. We do it so that you have fast and efficient
          access to the services offered by Filazero, improving your experience
          and preventing you from having to provide information every time you
          use our services.
        </p>

        <p>
          Your data will be stored. All documents and information related to the
          registration of the Holder will remain stored in security held by a
          company specializing in digital storage contracted by Filazero (Amazon
          Web Service), but only the Filazero will have access to this database.
        </p>

        <p>
          We use your data to disambiguate entries, calls in panels or to
          contact you through emails, SMS or similar, so that we can verify
          accounts and notifications of changes, updates or technical
          information regarding our services.
        </p>

        <p>
          Your data may be used so that we can analyze and understand our users,
          allowing us to provide you personalized content and services,
          according to your preferences, and we can constantly improve our
          services.
        </p>

        <h2>3. Sharing information with third parties</h2>

        <p>
          Due to the nature of the service provided by Filazero, it is essential
          that some of your information be shared with our partners. When you
          decide to use the service of one of these partners, they will ask
          Filazero to share their data records and/or other data necessary to
          complete the transaction. Your data will only be shared in this case,
          and if you have consented to this Privacy Policy.
        </p>

        <p>
          To protect your data, we charge these partners to comply strictly
          enforce the rules regarding the protection of personal data. We also
          impose strong restrictions on how our partners can use and disclose
          the data we provide. Whenever there is a suspicion of violation, by
          any of these partners, Filazero will take all take reasonable steps to
          protect your information.
        </p>

        <p>
          It is important to emphasize, therefore, that to use the platforms of
          the Filazero you will need to agree that these partners can, to offer
          the service you are looking for, acquire information that you share
          with Filazero.
        </p>

        <p>
          Except for the exclusive purpose of providing our services, as
          highlighted above, we will not share your information. with other
          companies, in compliance with Law 12.965/2014 and Law 13.709/2018,
          except with your express authorization or in case of order judicial.
        </p>

        <p>
          Although Filazero is always attentive to the way its partners use your
          data, the information collected by these services third parties are
          subject to their own terms and policies, not this Privacy Policy.
          Therefore, we are not responsible for the how third parties collect,
          use, protect or disclose information that you may provide them.
        </p>

        <p>
          Knowing this, when using the services offered by Filazero, the Holder
          authorizes the transmission of some of his registration data and any
          other necessary data with Filazero's partners, to that they can
          process and carry out the service requested by the user.
        </p>

        <p>Filazero will not sell your personal data in any circumstance.</p>

        <h2>4. Age restriction</h2>

        <p>
          Filazero was designed for older consumers. If you you are under 18
          years of age, you will not be allowed to send your personal
          information to our platforms to carry out your register. Although
          individuals under the age of 18 can use the service, they can only do
          so with involvement, supervision and approval of parents or guardians.
        </p>

        <h2>5. Security</h2>

        <p>
          We have security measures in the physical, electronic and
          administrative, which protect personal data and your information.
          These protective measures help us to prevent fraud and unauthorized
          access to information, as well as in maintaining the data integrity.
          In addition, we have a policy of internal security so that Filazero
          employees do not have access to your personal information, so that
          your data always remains in safety.
        </p>

        <h2>6. Users rights</h2>

        <p>
          All rights listed below can be exercised to any moment through
          Filazero's service channels.
        </p>

        <p>
          Access. The user may request information regarding which data is
          stored and for what purpose.
        </p>

        <p>
          Rectification. The user may request the correction/update of some of
          your personal data, as it is your responsibility to keep it always up
          to date.
        </p>

        <p>
          he immination. The user may request the deletion of their data
          personal in the following situations:
        </p>
        <ol type="i">
          <li>
            when they are not needed for the purpose for which they are used
          </li>
          <li>
            when you are no longer consenting to the terms set forth herein.
          </li>
        </ol>

        <p>
          Filazero reserves the right to block or cancel the user, excluding all
          data linked to it, due to the existence of evidence or of an
          identified effective offense, in addition to registration divergences.
        </p>

        <h2>7. Changes to this Statement</h2>

        <p>
          In case of modification of these terms, to better serve your
          customers, we will post such privacy policy changes and we will notify
          you of this situation. We will also keep the versions of this Privacy
          Policy filed for you compare them.
        </p>

        <h2>8. Data storage term</h2>

        <p>
          Filazero will store your personal data for as long as necessary. for
          the provision of the service we offer, that is, while your account
          stay active on our platforms.
        </p>

        <p>
          As highlighted above, the data can be deleted at user request. To
          close your Filazero account, you must contact us through one of our
          service channels.
        </p>

        <p>
          Filazero will be able to store your data during the necessary period
          to comply with a legal or regulatory obligation.
        </p>

        <h2>9. Consent</h2>

        <p>
          By using Filazero's platforms, you are consenting to the treatment of
          your data in accordance with our Privacy Policy, being aware of the
          data we collect, the purposes of this collection and of the user's
          rights in relation to the treatment we carry out.
        </p>

        <h2>10. Data Officer</h2>

        <p>
          During the Filazero storage period, there will be a exclusive
          responsible for the data, pursuant to art. 41 of the General Law of
          Data Protection. This person will answer for the data, if there is
          need to present them to the competent authority.
        </p>

        <p>
          Contact details: Hermeval Geraldo de Souza,
          <span>app&#64;filazero.net</span>
        </p>

        <h3>Summary</h3>

        <p>
          Filazero respects your privacy. When they connect to us, we want our
          customers to know that their personal data is in security, as well as
          that we are dedicated to bringing them a easy and safe experience.
          Personal information stored and shared through our platforms is
          intended to provide our customers a quality service, but also help us
          to better understand the interests of these customers, and our effort
          to serve them with the best possible service never ceases.
        </p>

        <p>If you still have any questions, please contact:</p>
        <p>E-mail: <span>app&#64;filazero.net</span></p>
      </article>
    </section>
  </article>
</section>
