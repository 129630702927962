import { Provider } from './provider.model';
import { Location } from './location.model';
import { Service } from './service.model';

export interface Terminal {
  id: number;
  name: string;
  providerLogoUrl: string;
  headerBackgroundColor: string;
  timezoneId: string;
  provider: Provider;
  location: Location;
  services: Service[];
  showOptionsBasedOnSessions: boolean;
  optionsDisplay: TerminalOptionsDisplay;
  allowPriority: boolean;
}

export enum TerminalOptionsDisplay {
  LIST,
  CATEGORY
}
