<div class="container" (click)="goToTrack()">
  <div class="left-container">
    <div class="inner-left-container">
      <div>{{ ticket.serviceName }}</div>
      <div>{{ ticket.providerName }}</div>
    </div>
  </div>
  <div class="line"></div>
  <div class="right-container">
    <div class="inner-right-container">
      <div *ngIf="ticket.prevision!=null">
        {{ ticket.prevision | relativeTimeFormat | async }}
      </div>
      <div *ngIf="ticket.prevision==null">
        {{'general.pages.tickets.noPrevision' | translate}}
      </div>
      <div>{{ ticket.position === 0 ? 'Chamado' : ticket.position + 'º' }}</div>
      <div>{{ ticket.friendlyCode }}</div>
    </div>
  </div>
</div>
