import Hashids from 'hashids';
import { DisplayComponent } from './display/display.component'
import { NgModule } from '@angular/core';
import { NotFoundComponent } from './shared/pages/not-found/not-found.component';
import {
  RemoteConfigService,
  ServiceLocator,
  StorageService,
  TicketService
} from './shared/services';
import { Routes, RouterModule } from '@angular/router';
import { TicketListComponent } from './ticket-list/ticket-list.component';
import { environment } from 'src/environments/environment';

async function shouldUseCustomInterface(): Promise<boolean> {
  const remoteConfigService = ServiceLocator.injector.get(RemoteConfigService);
  const ticketService = ServiceLocator.injector.get(TicketService);
  const url = location.href;
  let providerId: number;

  if (url.includes('/track/')) {

    const accessKey = url.split('/')[4].split('?')[0];

    const ticketId = decode(accessKey) as number;

    const ticket = await ticketService.getTicket(ticketId).toPromise();

    ticketService.getChildrenKey(ticketId).subscribe(response=>{
      console.log(response, ticket)
      if(response && ticket.feedback.rate)
        location.replace(`${environment.urlBase}track/${response}`);
    })

    providerId = ticket.provider.id;
  } else {
    providerId = null;
  }

  const isArtesano = providerId === environment.artesanoProviderId;

  if (isArtesano) {
    const shoudUse = await remoteConfigService.shouldUseCustomInterface();
    return isArtesano && shoudUse;
  }

  return false;
}

function decode(accessKey: string): number | bigint {
  const hashids = new Hashids("45148c5e-3401-470b-a737-253d9d0b0831", 8, "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789");
  return hashids.decode(accessKey)[0].valueOf();
}

async function getInterface() {
  const shouldUse = await shouldUseCustomInterface();

  return shouldUse
  ? import('./artesano/artesano.module').then(m => m.ArtesanoModule)
  : import('./filazero/filazero.module').then(m => m.FilazeroModule);
}

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: '', loadChildren: async () => await getInterface() },
  { path: 'tickets', component: TicketListComponent },
  { path: 'display/:accessKey', component: DisplayComponent },
  {
    path: 'boticario',
    loadChildren: () => import('./boticario/boticario.module').then(m => m.BoticarioModule)
  },
  { path: '**', component: NotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
