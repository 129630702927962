import { Injectable } from '@angular/core';

@Injectable()
export class LocaleService {
  private key = 'locale';

  getUserLocale(): string {
    return localStorage.getItem(this.key);
  }

  storeUserLocale(locale: string) {
    localStorage.setItem(this.key, locale);
  }

  getBrowserLocales(options = {}): string[] {
    const defaultOptions = {
      languageCodeOnly: false,
    };

    const opt = {
      ...defaultOptions,
      ...options,
    };

    const browserLocales =
      navigator.languages === undefined
        ? [navigator.language]
        : navigator.languages;

    if (!browserLocales) {
      return undefined;
    }

    return browserLocales.map(locale => {
      const trimmedLocale = locale.trim();

      return opt.languageCodeOnly
        ? trimmedLocale.split(/-|_/)[0]
        : trimmedLocale;
    });
  }
}
