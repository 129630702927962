import { Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { QrCodeScanComponent } from './qr-code-scan/qr-code-scan.component';
import { ScheduleComponent } from './schedule/schedule.component';
import { StartComponent } from './start/start.component';
import { TicketComponent } from './ticket/ticket.component';
import { TrackComponent } from './track/track.component';

export const FilazeroRoutes: Routes = [
    { path: '', redirectTo: '/home', pathMatch: 'full' },
    { path: 'home', component: HomeComponent },
    { path: 'start/:accessKey', component: StartComponent },
    { path: 'schedule', component: ScheduleComponent },
    { path: 'track', component: TrackComponent },
    { path: 'track/:accessKey', component: TicketComponent },
    { path: 'qrcode-scan', component: QrCodeScanComponent },
];