import { Injectable } from '@angular/core';
import { PositionUtilService } from '../utils/position-util.service';
import { Service, Storage, Terminal, Ticket } from '../models';
import { TicketService } from '../services/ticket.service';
import { Template } from '../models/template.model';

@Injectable()
export class StorageService {
  private key = 'app.storage';
  private storageAux = localStorage.getItem(this.key);

  constructor(private ticketService: TicketService, private positionUtil: PositionUtilService) {
    if (!this.storageAux) {
      this.initStorage();
    }

  }

  private get storage(): Storage {
    return JSON.parse(localStorage.getItem(this.key));
  }

  get currentTerminal(): Terminal {
    return this.storage.current.terminal;
  }

  get currentTicket(): Ticket {
    return this.storage.current.ticket;
  }

  get currentService(): Service {
    return this.storage.current.service;
  }

  get tickets(): Ticket[] {
    return this.storage.tickets;
  }

  get currentTemplate(): Template {
    return this.storage.current.template;
  }

  initStorage(): void {
    const storage: Storage = {
      current: {
        terminal: null,
        service: null,
        ticket: null,
        template: null,
      },
      terminals: [],
      tickets: []
    };

    localStorage.setItem(this.key, JSON.stringify(storage));
  }

  clear(): void {
    this.initStorage();
  }

  addTerminal(terminal: Terminal): void {
    const storage = this.storage;
    const index = storage.terminals.findIndex((t) => t.id === terminal.id);

    if (index > -1) {
      storage.terminals[index] = terminal;
    } else {
      storage.terminals.push(terminal);
    }

    localStorage.setItem(this.key, JSON.stringify(storage));
    this.setCurrentTerminal(terminal);
  }

  addTicket(ticket: Ticket): void {
    const storage = this.storage;
    const index = storage.tickets.findIndex((t) => t.id === ticket.id);

    if (index > -1) {
      storage.tickets[index] = ticket;
    } else {
      storage.tickets.push(ticket);
    }

    localStorage.setItem(this.key, JSON.stringify(storage));
  }

  removeTerminal(id: number): void {
    const storage = this.storage;
    const index = storage.terminals.findIndex((t) => t.id === id);

    if (index === -1) {
      return;
    }

    storage.terminals.splice(index, 1);
    localStorage.setItem(this.key, JSON.stringify(storage));
  }

  removeTicket(id: number): void {
    const storage = this.storage;
    const index = storage.tickets.findIndex((t) => t.id === id);

    if (index === -1) {
      return;
    }

    storage.tickets.splice(index, 1);
    localStorage.setItem(this.key, JSON.stringify(storage));
  }

  updateTicket(ticket: Ticket): void {
    const storage = this.storage;
    const index = storage.tickets.findIndex((t) => t.id === ticket.id);

    if (index === -1) {
      return;
    }

    storage.tickets[index] = ticket;
    localStorage.setItem(this.key, JSON.stringify(storage));
  }

  updateTicketsPositions() {
    this.tickets.forEach(async ticket => {
      ticket.position = await this.positionUtil.getNewPosition(ticket);
      this.updateTicket(ticket);
    });
  }

  setCurrentTicket(ticket: Ticket): void {
    const storage = this.storage;
    storage.current.ticket = ticket;
    localStorage.setItem(this.key, JSON.stringify(storage));

    if (ticket) {
      const terminal = this.findTerminal(ticket.providerId, ticket.locationId);

      if (terminal) {
        this.setCurrentTerminal(terminal);
      }
    } else {
      this.setCurrentTerminal(null);
    }

  }

  setCurrentService(service: Service) {
    const storage = this.storage;
    storage.current.service = service;
    localStorage.setItem(this.key, JSON.stringify(storage))
  }

  private setCurrentTerminal(terminal: Terminal): void {
    const storage = this.storage;
    storage.current.terminal = terminal;
    localStorage.setItem(this.key, JSON.stringify(storage));
  }

  private findTerminal(providerId: number, locationId: number): Terminal {
    return this.storage.terminals.find((t) => t.provider.id === providerId && t.location.id === locationId);
  }
  setTemplate(template: Template) {
    const storage = this.storage;
    storage.current.template = template;
    localStorage.setItem(this.key, JSON.stringify(storage))
  }
}
