import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocaleService } from './shared/services';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'filazero-express';

  constructor(
    private localeService: LocaleService,
    private translateService: TranslateService
  ) {
    this.translateService.addLangs(['pt-BR', 'pt-PT', 'en-US']);
    this.translateService.setDefaultLang('pt-BR');

    let locale = this.localeService.getUserLocale();

    if (locale === null) {
      locale = this.localeService.getBrowserLocales()[0];
      this.localeService.storeUserLocale(locale);
    }

    this.translateService.use(locale);
  }
}
