export class FieldBase {
  id: number;
  title: string;
  type: string;
  required: boolean;
  maxLength: number;

  constructor(obj: {
    id?: number;
    title?: string;
    type?: string;
    required?: boolean;
    maxLength?: number;
  } = {}) {
    this.id = obj.id;
    this.title = obj.title;
    this.type = obj.type;
    this.required = obj.required;
    this.maxLength = obj.maxLength;
  }
}
