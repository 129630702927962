<div class="modal-warning">
  <mat-icon class="icon">videocam</mat-icon>
  <div class="text" mat-dialog-content>
    <b>{{ 'components.videoConferenceAlertModal.emphasis-text' | translate }}</b>
    <p>{{ 'components.videoConferenceAlertModal.description' | translate }}</p>
  </div>
  <button class="btn" mat-stroked-button (click)="closeDialog()">
    {{ 'general.actions.ok' | translate | uppercase }}
  </button>
</div>
