import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { environment } from "src/environments/environment";
import { DisplayService } from "../modules/display/display.service";

@Component({
  selector: 'app-display',
  templateUrl: './display.component.html',
  styleUrls: ['./display.component.scss'],
})
export class DisplayComponent implements OnInit {
  totemKey: string;
  locationCapacity: number;
  isLoading = true;
  doesExistPanel = true;
  panelBackgroundUrl: string;
  providerLogo: string;

  constructor(
    private displayService: DisplayService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      const accessKey = params.get('accessKey');
      if (accessKey) {
        this.displayService.getPanel(accessKey).subscribe((response) => {
          this.isLoading = false;

          if (response.messages) {
            this.doesExistPanel = false;
            return;
          }

          const {
            backgroundUrl,
            serviceConfigs,
            capacity,
            totemKey,
            countStarted,
            countChecked,
            providerLogoUrl,
          } = response;

          this.displayService.setInitialCount(countStarted, countChecked);
          this.displayService.subscribeQueues(serviceConfigs);
          this.locationCapacity = capacity;
          this.totemKey = totemKey;
          this.panelBackgroundUrl = backgroundUrl;
          this.providerLogo = providerLogoUrl;
        });
      }
    });
  }

  get customersInQueueAmount() {
    return this.displayService.customersInQueueAmount;
  }

  get customersInStoreAmount() {
    return this.displayService.customersInStoreAmount;
  }

  get isFull() {
    return this.customersInStoreAmount >= this.capacity;
  }

  get backgroundUrl() {
    return this.panelBackgroundUrl ?? 'assets/images/display-bg-fallback.jpg';
  }

  get providerLogoUrl() {
    return this.providerLogo ?? 'assets/images/fz-logo.png';
  }

  get capacity() {
    return this.locationCapacity;
  }

  get expressLink() {
    return `${environment.urlBase}start/${this.totemKey}`;
  }
}
