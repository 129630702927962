<div [formGroup]="form">
  <div [ngSwitch]="field.type">
    <div *ngSwitchCase="'text'">
      <mat-form-field >
        <mat-label>{{ field.title }}</mat-label>
        <input matInput [formControlName]="field.title" [maxlength]="field.maxLength" type="text" [id]="field.title">
        <mat-hint align="end">{{form.value[field.title].length}} / {{ field.maxLength }}</mat-hint>
        <mat-error *ngIf="isInvalid">
          O campo {{ field.title }} é obrigatório.
        </mat-error>
        <mat-error *ngIf="isInvalidLength">
          O campo {{ field.title }} está inválido.
        </mat-error>
      </mat-form-field>
    </div>

    <div *ngSwitchCase="'number'">
      <label for="">{{ 'components.customFields.type.generic.title' | translate:{value: field.title} }}{{ field.required ? '*' : '' }}</label>

        <mat-label>{{ field.title }}</mat-label>
        <input matInput [formControlName]="field.title" type="number" [id]="field.title">
        <mat-error *ngIf="isInvalid">
          O campo {{ field.title }} é obrigatório.
        </mat-error>
    </div>

    <div *ngSwitchCase="'date'">
      <label for="">{{ 'components.customFields.type.date.title' | translate:{value: field.title} }}{{ field.required ? '*' : '' }}</label>
      <mat-form-field >
        <mat-label>{{ field.title }}</mat-label>
        <input matInput [formControlName]="field.title" type="date" [id]="field.title" [max]="currentDate">
        <mat-hint align="end">{{form.value[field.title].length}} / {{ field.maxLength }}</mat-hint>
        <mat-error *ngIf="isInvalid">
          O campo {{ field.title }} é obrigatório.
        </mat-error>
      </mat-form-field>
    </div>

    <div *ngSwitchCase="'cpf'">
      

      <mat-form-field>

        <mat-label>{{ field.title }}</mat-label>
        <input mask="000.000.000-00" matInput [formControlName]="field.title" type="tel" [id]="field.title">
        <mat-error *ngIf="isInvalid">
          O campo {{ field.title }} é obrigatório.
        </mat-error>
        <mat-error *ngIf="isInvalidCpf">
          {{ field.title }} inválido.
        </mat-error>
        <mat-error *ngIf="isInvalidLength">
          {{ field.title }} inválido.
        </mat-error>
      </mat-form-field>
    </div>

    <div *ngSwitchCase="'cnpj'">
      <mat-form-field>
        <mat-label>{{ field.title }}</mat-label>
        <input mask="00.000.000/0000-00" matInput [formControlName]="field.title" type="tel" [id]="field.title">
        <mat-error *ngIf="isInvalid">
          O campo {{ field.title }} é obrigatório.
        </mat-error>
        <mat-error *ngIf="isInvalidCnpj">
          {{ field.title }} inválido.
        </mat-error>
      </mat-form-field>
    </div>

    <div *ngSwitchCase="'select'">
      <label for="">{{ 'components.customFields.type.generic.title' | translate:{value: field.title} }}{{ field.required ? '*' : '' }}</label>

      <mat-form-field>
        <mat-label>{{ field.title }}</mat-label>
        <mat-select [formControlName]="field.title" [id]="field.title" (focus)="getCustomFieldOptions()">
          <mat-option>Selecione...</mat-option>
          <mat-option *ngFor="let option of options" [value]="option.value">
            {{option.name | uppercase}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="isInvalid">
          O campo {{ field.title }} é obrigatório.
        </mat-error>
        <mat-error *ngIf="isInvalidLength">
          {{ field.title }} inválido.
        </mat-error>
      </mat-form-field>
    </div>
  </div>
</div>
