import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  container: HTMLElement;
  constructor(private router: Router) { } 

  ngOnInit() {
    this.container = document.getElementById('container');
    const height = window.innerHeight;
    this.container.style.height = `calc(${height}px - (64px + 61px))`;
  }

  next(): void {
    this.router.navigate(['/qrcode-scan'])
  }

}
