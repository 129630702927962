import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ExitAlertModalComponent } from 'src/app/components/exit-alert-modal/exit-alert-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { TicketService, StorageService, NotificationService, FeedbackService, TerminalService } from '../../shared/services';
import { Ticket, Terminal, Notification, Customer, Service, Session, Location, Activity } from '../../shared/models';
import { Router } from '@angular/router';
import { brighten } from '../../shared/utils/colors';
import { finalize } from 'rxjs/operators';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import { SoundAlertComponent } from 'src/app/components/sound-alert/sound-alert.component';
import { VidoconferenceAlertComponent } from '../../components/vidoconference-alert/vidoconference-alert.component';
import { DOCUMENT } from '@angular/common';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PositionUtilService } from '../../shared/utils/position-util.service';
import { PresenceService } from '../../shared/services';
import { Template } from 'src/app/shared/models/template.model';
import { DeviceDetectorService } from 'ngx-device-detector';

moment.locale('pt-br');

@Component({
  selector: 'app-track',
  templateUrl: './track.component.html',
  styleUrls: ['./track.component.scss']
})
export class TrackComponent implements OnInit, OnDestroy {

  ticket: Ticket;
  terminal: Terminal;
  notification: Notification;
  accessKey: string;
  isLoading = false;
  isFeedbackLoading = false;
  audio: HTMLAudioElement;
  subscription: Subscription;
  feedbackForm: UntypedFormGroup;
  rateCtrl = new UntypedFormControl(null, Validators.required);
  customer: Customer;
  service: Service;
  location: Location;
  session: Session;
  childTicketId: number;
  userLocation: { latitude: number; longitude: number };
  watchPositionId: number;
  template: Template;
  slug: string;
  templateAux: Template;
  childTicketKey: string | null = null;
  canGoToChild = true;

  constructor(
    private router: Router,
    private storageService: StorageService,
    private ticketService: TicketService,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private feedbackService: FeedbackService,
    private positionUtil: PositionUtilService,
    private terminalService: TerminalService,
    private snackBar: MatSnackBar,
    private presenceService: PresenceService,
    public dialog: MatDialog,
    private deviceService: DeviceDetectorService,
    @Inject(DOCUMENT) private document: Document,
  ) {
    if (this.isSafari) {
      this.openSoundAlertModal();
    } else {
      this.audio = new Audio();
    }
  }

  ngOnInit() {
    this.feedbackForm = new UntypedFormGroup({comment: new UntypedFormControl('', [])});
    this.feedbackForm.addControl('rate', this.rateCtrl);

    this.terminal = this.storageService.currentTerminal;
    this.ticket = this.storageService.currentTicket;
    this.service = this.storageService.currentService;
    this.template = this.storageService.currentTemplate;
    this.getTicket();

    this.accessKey = localStorage.getItem('terminalKey');

    this.notificationService.add(this.ticket.id, this.ticket.serviceConfigId, [
      'TicketConfirm',
      'TicketCheckin',
      'PrevisionChanged',
      'TicketCalled',
      'TicketStart',
      'TicketComplete',
      'TicketFeedback',
      'TicketCancel',
      'TicketReopened',
      'TransferredTicket',
    ]);

    this.subscription = this.notificationService.notifications.subscribe(
      async (response) => {
        switch (response.eventName) {
          case 'TicketCheckin':
            this.getTicket();
            break;
          case 'TicketConfirm':
            this.getTicket();
            break;
          case 'PrevisionChanged':
            this.ticket.prevision = response.ticket.prevision;
            this.getPosition();
            break;
          case 'TicketCalled':
            if (this.ticket.id === response.ticket.id) {
              this.ticket.status = 'Called';
              this.ticket.resource = response.ticket.resource;
              this.playNotification();
              this.storageService.updateTicket(this.ticket);
            }
            break;
          case 'TicketStart':
            if (this.ticket.id === response.ticket.id) {
              this.ticket.status = 'Started';
              this.storageService.updateTicket(this.ticket);
            }
            break;
          case 'TicketComplete':
            if (response.ticket.id === this.ticket.id) {
              this.ticket = response.ticket;
              if (this.ticket.feedback === undefined) {
                setTimeout(
                  () => {
                    this.ticketService.getTicket(this.ticket.id).subscribe(ticket => {
                      this.ticket.feedback = ticket.feedback;
                      this.storageService.updateTicket(this.ticket);
                    });
                  }, 1000
                );
              }
              this.storageService.updateTicket(this.ticket);
            }
            break;
          case 'TicketFeedback':
            this.goToTickets()
            break;
          case 'TicketCancel':
            if (this.ticket.id === response.ticket.id) {
              this.ticket.status = 'Canceled';
              this.storageService.updateTicket(this.ticket);
            }
            break;
          case 'TicketReopened':
            this.ticket.status = 'Reopened';
            this.storageService.updateTicket(this.ticket);
            break;
          case 'TransferredTicket':
            this.addTransferredTicket(response.ticket);
            this.ticketService.getChildrenKey(this.ticket.id).subscribe(response=>{
              if(response) {
                this.childTicketKey = response;
              }
            });
            break;
          case 'ServiceConfigTicketStart':
          case 'ServiceConfigTicketCalled':
          case 'ServiceConfigTicketCheckin':
            if (this.ticket.serviceConfigId === response.ticket.serviceConfigId) {
              const positionEl = document.getElementById('queuePosition');
              const previsionEl = document.getElementById('prevision');

              this.ticket.position = await this.positionUtil.getNewPosition(this.ticket);
              this.storageService.updateTicket(this.ticket);
              this.storageService.updateTicketsPositions();

              if (this.isSafari) {
                this.forceRedraw(positionEl);
                this.forceRedraw(previsionEl);
              }
            }
            break;
        }
      }
    )
    this.ticketService.getChildrenKey(this.ticket.id).subscribe(response=>{
      if(response) {
        this.childTicketKey = response;
      }
    })
  }

  getChild() {
    this.ticketService.getChildrenKey(this.ticket.id).subscribe(response=>{
      if(response) {
        this.childTicketKey = response;
      }
    })
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    window.navigator.geolocation.clearWatch(this.watchPositionId);
    this.presenceService.dispose();
    this.presenceService.setPresence('offline', this.ticket);
  }

  openExitAlertModal() {
    this.dialog.open(ExitAlertModalComponent, {
      data: { ticketId: this.ticket.id, accessKey: this.accessKey, providerId: this.ticket.providerId }
    });
  }

  private async addTransferredTicket(ticket: Ticket): Promise<void>{
    this.storageService.addTicket(ticket);
    this.storageService.updateTicketsPositions();
    this.childTicketId = ticket.id;
  }

  private trackChild(): void {
    const child = this.storageService.tickets.find(t => t.id === this.childTicketId);
    this.router.navigate([`/track/${child.accessKey}`]);
  }

  openSoundAlertModal() {
    const modal = this.dialog.open(SoundAlertComponent);

    modal.afterClosed().subscribe((audio: HTMLAudioElement) => {
      this.audio = audio;
      this.audio.src = '../../../assets/audio/no-sound.mp3';
      this.audio.load();
      this.audio.play();
    });
  }

  goToTickets() {
    this.storageService.removeTicket(this.ticket.id);

    if (this.childTicketId) {
      this.trackChild();
    } else {
      if (this.storageService.tickets.length > 0) {
        const ticket = this.storageService.tickets[0];
        this.storageService.setCurrentTicket(ticket);
      } else {
        this.storageService.setCurrentTicket(null);
      }
      this.router.navigate([`/tickets`]);
    }
  }

  get currentLang(): string {
    return this.translateService.currentLang;
  }

  private getTicket() {
    this.isLoading = true;
    this.ticketService.getTicket(this.ticket.id)
      .pipe(finalize(() => this.isLoading = false))
      .subscribe((response) => {

        const activitiesList: Activity[] = [];

        response.activities.forEach(activity=>{
          activitiesList.push({name: activity.name, at: activity.at})
        })
        this.slug = response.provider.slug;

        this.ticket = {
          id: response.id,
          prevision: response.estimatedServiceStart,
          status: response.status,
          resource: response.resource?.name,
          attendanceType: response.attendanceType,
          timezoneId: response.location.timezoneId,
          providerId: response.provider.id,
          position: this.ticket.position,
          serviceConfigId: response.session.serviceConfigId,
          friendlyCode: response.friendlyCode,
          accessKey: response.accessKey,
          locationId: response.location.id,
          locationName: response.location.name,
          locationAddress: response.location.address,
          locationCity: response.location.city,
          locationUF: response.location.state,
          providerName: response.provider.name,
          serviceName: response.service.name,
          feedback: response.feedback,
          smartCode: response.smartCode,
          priority: response.priority,
          activities: activitiesList
        };

        this.location = {
          id: response.location.id,
          name: response.location.name,
          address: {
            address: response.location.address,
            city: response.location.city,
            latitude: response.location.latitude,
            longitude: response.location.longitude,
            state: response.location.state,
            zipcode: response.location.zipcode
          }
        };

        this.session = {
          id: response.session.id,
          start: response.session.estimatedStart,
          end: response.session.estimatedEnd,
          name: '',
          hasSlotsLeft: false,
          checkinConfigEnabled: response.session.selfServiceCheckinEnabled,
          chekinStartDate: response.session.selfServiceChekinStartDate,
          chekinEndDate: response.session.selfServiceChekinEndDate
        };

        this.customer = {
          name: response.customer.name,
          email: response.customer.email,
          phone: response.customer.phone
        }

        if (this.feedbackDone) this.goToTickets();

        if (!this.isVideoConference) this.handlePermission();

        this.getPosition();
        this.showServiceGuidelines();
        if (this.isOlder || this.isOpened) {
          this.goToTickets();
        }
        this.presenceService.addVisibilityListener(this.ticket);
        this.presenceService.setPresence(this.document.visibilityState, this.ticket);
        this.getTemplate(this.slug);
      }
    );
  }

  private getTemplate(slug: string): void {
  this.terminalService.getTemplate(slug).subscribe((response) => {
        this.templateAux = response;
        //As linhas abaixo foram feitas para burlar o cache das cores pro caso do usuário estar utilizando duas empresas ao mesmo tempo.
        /* -> */ this.template.logoUrl != this.templateAux.logoUrl ? window.location.reload() : 0;
        /* -> */ this.template.templateColor != this.templateAux.templateColor ? window.location.reload() : 0;
      });
  }

  goToFeedback() {
    this.storageService.removeTicket(this.ticket.id);
    this.storageService.setCurrentTicket(null);
    this.document.location.href = `${environment.feedBackSiteUrl}ticket-details/${this.ticket.accessKey}`;
  }

  goHome() {
    this.router.navigate(['/home']);
  }

  getPosition() {
    const positionEl = document.getElementById('queuePosition');
    const previsionEl = document.getElementById('prevision');

    this.ticketService.getPosition(this.ticket.providerId, this.ticket.id).subscribe((response) => {
      if (response > 0) {
        this.ticket.position = response;

        if (this.isSafari) {
          this.forceRedraw(positionEl);
          this.forceRedraw(previsionEl);
        }
      }

      if (response === 1) {
        this.playNotification();
      }

      this.storageService.updateTicket(this.ticket);
    });
  }

  showServiceGuidelines() {
    if (this.isVideoConference && !this.deviceService.isDesktop() && this.service.isRecommendDesktop) {
      this.dialog.open(VidoconferenceAlertComponent);
    }

  }

  brighten(color: string, amount: number): string {
    return '#' + brighten(color, amount);
  }

  get nikeUrl() {
    const now = moment(Date.now()).startOf('day');
    let url = `https://www.nike.com.br/LandingPage/cyber-week/?utm_source=NFS&utm_campaign=FilaZero&utm_medium=${environment.nike.nikeStore[this.accessKey]}`;

    if (now.isBetween(moment('01/11/2020', 'DD/MM/YYYY'), moment('21/11/2020', 'DD/MM/YYYY')) || now.isAfter(moment('30/11/2020', 'DD/MM/YYYY'))) {
      url = `https://www.nike.com.br/Loja/Ofertas/Destaques/Todos/446-481-485?utm_source=NFS&utm_campaign=FilaZeroAgenda&utm_medium=${environment.nike.nikeStore[this.accessKey]}`;
    }

    return url;
  }

  get nikeAdsMsg() {
    const now = moment(Date.now()).startOf('day');
    let msg = '';

    // moment.isBetween() is exclusive.
    if (now.isBetween(moment('01/11/2020', 'DD/MM/YYYY'), moment('21/11/2020', 'DD/MM/YYYY'))) {
      msg = 'Junte-se a nós em Nike.com e use o Cupom NFS10 e aproveite 10% OFF*!';
    } else if (now.isBetween(moment('20/11/2020', 'DD/MM/YYYY'), moment('24/11/2020', 'DD/MM/YYYY'))) {
      msg = 'Junte-se a nós em Nike.com e use o Cupom CYBER25 e aproveite 25% OFF*!';
    } else if (now.isBetween(moment('23/11/2020', 'DD/MM/YYYY'), moment('01/12/2020', 'DD/MM/YYYY'))) {
      msg = 'Junte-se a nós em Nike.com e use o Cupom CYBER5 e aproveite 5% OFF*!';
    } else if (now.isBetween(moment('30/11/2020', 'DD/MM/YYYY'), moment('20/01/2021', 'DD/MM/YYYY'))) {
      msg = 'Junte-se a nós em Nike.com e use o Cupom NFS10 e aproveite 10% OFF*!';
    } else if (now.isAfter(moment('19/01/2021', 'DD/MM/YYYY'))) {
      msg = 'NAS COMPRAS ACIMA DE R$350 SUA COMPRA VIRA DESCONTO GANHE 20%OFF EM NIKE.COM';
    }

    return msg;
  }

  get isSafari() {
    return this.deviceService.getDeviceInfo().browser === 'Safari';
  }

  get isOlder() {
    const ticketPrevision = moment(this.ticket.prevision)
      .tz(this.ticket.timezoneId)
      .startOf('day');

    const now = moment(Date.now()).startOf('day');

    return now.diff(ticketPrevision, 'days') >= 1;
  }

  get message () {
    switch (this.ticket.status) {
      case 'Authorized':
        return 'general.pages.track.authorized.message';
      case 'Reopened':
        return 'general.pages.track.reopened.message';
      case 'Confirmed':
        return 'general.pages.track.confirmed.message';
    }
  }

  get feedbackDone(): boolean {
    return this.ticket.feedback && !!this.ticket.feedback.lastUpdate;
  }

  get isSessionClosed() {
    const now = moment(Date.now()).startOf('day');
    const sessionEnd = moment(this.session.end)
      .tz(this.ticket.timezoneId)
      .startOf('day');
    return sessionEnd.isBefore(now);
  }

  get isAuthorized () {
    return this.ticket.status === 'Authorized';
  }

  get isReopened () {
    return this.ticket.status === 'Reopened';
  }

  get isConfirmed () {
    return this.ticket.status === 'Confirmed';
  }

  get isCompleted() {
    return this.ticket.status === 'Completed';
  }

  get isOpened() {
    return this.ticket.status === 'Opened';
  }

  get isCanceled() {
    return this.ticket.status === 'Canceled';
  }

  get isValidStatus(): boolean {
    const statusList = [
      'Opened', 'Authorized', 'Reopened', 'Confirmed', 'Checked',
      'Called', 'Started', 'Completed', 'Canceled', 'Unauthorized'
    ];
    return statusList.includes(this.ticket.status);
  }

  get isNike() {
    return this.ticket.providerId === environment.nike.providerId;
  }

  get isQueuesNoel() {
    return this.ticket.providerId === environment.phoneNoelProviderId || this.ticket.providerId === environment.queueNoelProviderId;
  }

  get urlMeet(): string {
    return `${environment.urlMeet}${this.ticket.accessKey}?jwt=${this.jwt}&providerId=${this.ticket.providerId}&serviceConfigId=${this.ticket.serviceConfigId}&ticketId=${this.ticket.id}`;
  }

  get jwt() {
    let token = btoa('{"alg":"HS256","typ":"JWT"}') + '.';
    if (this.customer) {
      token += btoa('{"context": {"user": {"name": "' + this.customer.name + '"}},"aud": "jitsi"}');
    } else {
      token += btoa('{"context": {"user": {"name": "Usuário Filazero"}},"aud": "jitsi"}');
    }
    return token;
  }

  get isVideoConference(): boolean {
    return this.ticket && this.ticket.attendanceType === 'VIDEO_CONFERENCE';
  }

  playNotification() {
    if (this.audio !== undefined) {
      this.audio.src = '../../../assets/audio/got-it-done.mp3';
      this.audio.load();
      this.audio.play();
    }
  }

  forceRedraw(element: HTMLElement) {
    if (!element) {
      return;
    }

    element.style.opacity = '0.9';

    setTimeout(() => {
      element.style.opacity = '1';
    }, 20);
  }

  onSubmitFeedback() {
    if (this.feedbackForm.valid) {
      this.ticket.feedback.rating = this.feedbackForm.controls['rate'].value;
      this.ticket.feedback.comment = this.feedbackForm.controls['comment'].value;

      this.isFeedbackLoading = true;

      this.feedbackService.update(this.ticket.feedback)
        .pipe(finalize(() => this.isFeedbackLoading = false))
        .subscribe((response: any) => {
          if (response.messages[0].code === '1040') {
            const snackRef = this.snackBar.open(
              this.translateService.instant('messages.success.1040'),
              this.translateService.instant('messages.success.success'),
              { duration: 3000 }
            );

            snackRef.afterDismissed().subscribe(() => {
              this.storageService.removeTicket(this.ticket.id);
              if (this.childTicketId) {
                this.trackChild();
              } else {
                if (this.storageService.tickets.length > 0) {
                  const ticket = this.storageService.tickets[0];
                  this.storageService.setCurrentTicket(ticket);
                } else {
                  this.storageService.setCurrentTicket(null);
                }
                if(this.childTicketKey)
                  this.router.navigate([`/track/${this.childTicketKey}`]);

                this.router.navigate([`/tickets`]);
              }
            });
          }
        })
    }
  }

  get inCheckinPeriod(): boolean {
    const chekinStartDate = moment(this.session.chekinStartDate);
    const chekinEndDate = moment(this.session.chekinEndDate);
    const now = moment(Date.now());
    return (chekinStartDate <= now) && (chekinEndDate >= now);
  }

  async check() {
    try {
      await this.ticketService.checkin(this.ticket.smartCode, this.ticket.providerId).toPromise();
    } catch (error) {
    }
  }

  async confirm() {
    try {
      await this.ticketService.confirm(this.ticket.id, this.ticket.providerId).toPromise();
    } catch (error) {
      if(error.error.messages[0].code === '2185') {
          this.snackBar.open(
          this.translateService.instant('messages.error.2185'),
          this.translateService.instant('messages.error.ok'),
          { duration: 3000, verticalPosition: 'top', horizontalPosition: 'right' }
      )}
    }
  }

  get canChekin(): boolean {
    if (!this.session.checkinConfigEnabled) return false;
    if (!this.inCheckinPeriod) return false;
    if (this.isVideoConference) return true;
    if (!this.userLocation || !this.location) return false;

    return (
      this.distanceBetween(
        this.userLocation.latitude,
        this.userLocation.longitude,
        +this.location.address.latitude,
        +this.location.address.longitude) <= 300
    );
  }

  /*
  * Code extracted from:
  * https://bitbucket.org/inventione/filazero-new-site/src/develop/src/app/modules/ticket/ticket-details/ticket-details.component.ts
  */
  distanceBetween(latFrom: number, lngFrom: number, latTo: number, lngTo: number) {
    const earthRadius = 6371e3; // meters
    const dLat = this.degreesToRadians(latTo - latFrom);
    const dLng = this.degreesToRadians(lngTo - lngFrom);

    const dLatFrom = this.degreesToRadians(latFrom);
    const dLatTo = this.degreesToRadians(latTo);

    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLng / 2) * Math.sin(dLng / 2) *
      Math.cos(dLatFrom) * Math.cos(dLatTo);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return earthRadius * c;
  }

  degreesToRadians(degrees: number): number {
    return degrees * Math.PI / 180;
  }

  handlePermission(): void {
    const browser = this.deviceService.browser;
    const os = this.deviceService.os;
    if (browser !== 'Safari' && os !== 'iOS') {
      window.navigator.permissions.query({name:'geolocation'}).then((result) => {
        if (result.state == 'denied') return;
        this.currentLocation();
      });
    } else {
      this.currentLocation();
    }
  }

  currentLocation(): void {
    if (window.navigator && window.navigator.geolocation) {
      this.watchPositionId = window.navigator.geolocation.watchPosition(
        (position) => {
          this.userLocation = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
          };
        }, (error) => {
          switch (error.code) {
            case 1:
              const snackRef = this.snackBar.open(
                'Você não habilitou a localização',
                'Habilitar',
                { verticalPosition: 'top' }
              );
              snackRef.onAction().subscribe(() => {
                this.handlePermission();
              });
              break;
            case 2:
              this.snackBar.open(
                'Localização indisponível',
                'Erro',
                { duration: 3000, verticalPosition: 'top' }
              );
              break;
            case 3:
              this.snackBar.open(
                'Localização não habilitada',
                'Erro',
                { duration: 3000, verticalPosition: 'top' }
              );
              break;
          }
        },
        {enableHighAccuracy:true}
      );
    }
  }
  goToChildTicket() {
    location.replace(`${environment.urlBase}track/${this.childTicketKey}`);
  }
}
