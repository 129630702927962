import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { Ticket } from 'src/app/shared/models';
import { LocaleService, NotificationService, StorageService } from 'src/app/shared/services';

@Component({
  selector: 'app-navigation-bar',
  templateUrl: './navigation-bar.component.html',
  styleUrls: ['./navigation-bar.component.scss']
})
export class NavigationBarComponent implements OnInit {
  langs = [
    {
      code: 'pt-BR',
      name: 'Brasil'
    },
    {
      code: 'pt-PT',
      name: 'Portugal'
    },
    {
      code: 'en-US',
      name: 'EUA'
    }
  ];

  hasTicketChanged: boolean = false;
  tickets: Ticket[];

  constructor(
    private translateService: TranslateService,
    private localeService: LocaleService,
    private notificationService: NotificationService,
    private router: Router,
    private storageService: StorageService
  ) { }

  ngOnInit() {
    this.tickets = this.storageService.tickets;
  }

  next(): void {
    this.router.navigate(['/tickets']);
    this.hasTicketChanged = false;
  }

  changeTicketStatus(): void {
    this.hasTicketChanged = !this.hasTicketChanged;
  }

  shouldHideBadge(): boolean {
    return this.isRouteTicketList() || this.isRouteHome() || !this.hasTicketChanged;
  }

  isRouteTicketList(): boolean {
    return this.router.url === '/tickets';
  }

  isRouteHome(): boolean {
    return this.router.url === '/home';
  }

  setLang(lang: string) {
    this.translateService.use(lang)
      .subscribe(() => this.localeService.storeUserLocale(lang));
  }

  get currentLang() {
    return this.localeService.getUserLocale();
  }

  goHome() {
    this.router.navigate(['/home']);
  }
}

