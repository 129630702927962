<div class="warning">
  <mat-icon class="icon">notifications</mat-icon>
</div>
<div class="modal-text" mat-dialog-content>
  <p>{{ 'components.soundAlertModal.message' | translate }}</p>
</div>
<div class="flex" mat-dialog-actions>
  <button class="flex-1" mat-flat-button color="success" (click)="closeDialog()" cdkFocusInitial>
    {{ 'general.actions.ok' | translate | uppercase }}
  </button>
</div>
