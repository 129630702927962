import { Component, OnDestroy, OnInit, Input, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { Ticket } from '../shared/models';
import { NotificationService, TicketService } from '../shared/services';
import { StorageService } from '../shared/services/storage.service';
import { PositionUtilService } from '../shared/utils/position-util.service';

@Component({
  selector: 'app-ticket-list',
  templateUrl: './ticket-list.component.html',
  styleUrls: ['./ticket-list.component.scss']
})
export class TicketListComponent implements OnInit, OnDestroy {

  date = new Date().toUTCString();
  @Input() tickets: Ticket[];
  eventNames = [
    'TicketCheckin',
    'PrevisionChanged',
    'TicketCalled',
    'TicketStart',
    'TicketComplete',
    'TicketCancel',
    'TransferredTicket',
    'ServiceConfigTicketCalled',
    'ServiceConfigTicketCancel'
  ];
  subscription: Subscription;

  constructor(
    private storageService: StorageService,
    private router: Router,
    private notificationService: NotificationService,
    private ticketService: TicketService,
    private translateService: TranslateService,
    private positionUtil: PositionUtilService
  ) { }

  ngOnInit(): void {
    this.tickets = this.storageService.tickets;
    this.addTicketsToChannels();
    this.subscribe();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  addTicketsToChannels(): void {
    this.tickets.forEach(ticket => this.notificationService.add(ticket.id, ticket.serviceConfigId, this.eventNames));
  }

  getTicketsPositions(): void {
    this.tickets.forEach(ticket => this.ticketService.getPosition(ticket.providerId, ticket.id).subscribe(response => ticket.position = response));
  }

  get currentLang(): string {
    return this.translateService.currentLang;
  }

  get hasNoTickets(): boolean {
    return this.storageService.tickets.length === 0;
  }

  async subscribe(): Promise<void> {
    this.subscription = this.notificationService.notifications.subscribe(async (response) => {

      if (response.eventName === 'PrevisionChanged') {
        this.storageService.updateTicket(response.ticket);
      }

      if (response.eventName === 'ServiceConfigTicketCancel') {
        for (let ticket of this.storageService.tickets) {
          if (ticket.serviceConfigId === response.ticket.serviceConfigId) {
            ticket.position = await this.positionUtil.getNewPosition(ticket);
            this.storageService.updateTicket(ticket);
          }
        }
      }

      if (response.eventName === 'TicketCalled') {
        let ticket = response.ticket;
        ticket.position = await this.positionUtil.getNewPosition(ticket);
        this.storageService.updateTicket(ticket);
      }

      if (response.eventName === 'ServiceConfigTicketCalled') {
        for (let ticket of this.storageService.tickets) {
          if (ticket.serviceConfigId === response.ticket.serviceConfigId) {
            ticket.position = await this.positionUtil.getNewPosition(ticket);
            this.storageService.updateTicket(ticket);
          }
        }
      }

      if (response.eventName === 'TicketComplete' || response.eventName === 'TicketCancel') {
        this.storageService.removeTicket(response.ticket.id);
        this.notificationService.remove(response.ticket.id, response.ticket.serviceConfigId);
      }

      if (response.eventName === 'TransferredTicket') {
        let ticket = response.ticket;
        ticket.position = await this.positionUtil.getNewPosition(ticket);
        this.storageService.addTicket(response.ticket);
      }

      this.tickets = this.storageService.tickets;
    });
  }

  goHome() {
    this.router.navigate(['/home']);
  }
}
