import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CollapsableButton } from 'src/app/shared/models';
import { brighten } from '../../shared/utils/colors';

import {  Terminal } from '../../shared/models';
import {  StorageService } from '../../shared/services';

@Component({
  selector: 'app-collapsable-option-button',
  templateUrl: './collapsable-option-button.component.html',
  styleUrls: ['./collapsable-option-button.component.scss']
})
export class CollapsableOptionButtonComponent {
  isActive = false;
  terminal: Terminal;

  @Input() button: CollapsableButton;
  @Output() buttonClick = new EventEmitter<number | string>();
  collapse() {
    this.isActive = !this.isActive;
  }

  constructor(
    private storageService: StorageService,
  ) {
    this.terminal = this.storageService.currentTerminal;
  }

  onClick() {
    this.buttonClick.next(this.button.type === 'category' ? this.button.text : this.button.serviceId);
  }

  brighten(color: string, amount: number): string {
    return '#' + brighten(color, amount);
  }
}
