import { Component, OnInit, Renderer2, Inject, Input } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-embed-chat',
  template: '',
})
export class EmbedChatComponent implements OnInit {
  @Input() embedChat: string;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer2: Renderer2,
    private domSanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.loadScript();
  }

  loadScript() {
    if (!this.domSanitizer.bypassSecurityTrustScript(this.embedChat)) return;
    this.setInnerHtmlWithScripts(this.document.body, this.embedChat);
  }

  setInnerHtmlWithScripts(element: HTMLElement, htmlText: string) {
    const template = this.renderer2.createElement('template');
    template.innerHTML = htmlText;

    const htmlElements = template.content.querySelectorAll(':not(script)');
    element.append(...htmlElements);

    const scriptElements = Array.from<HTMLScriptElement>(
      template.content.querySelectorAll('script')
    );

    scriptElements.forEach((currentScript) => {
      const newScript = this.renderer2.createElement('script');

      const currentScriptAttributes = Array.from(currentScript.attributes);
      currentScriptAttributes.forEach((attr) => {
        newScript.setAttribute(attr.name, attr.value);
      });

      const content = this.document.createTextNode(currentScript.innerHTML);
      newScript.appendChild(content);

      element.appendChild(newScript);
    });
  }
}
