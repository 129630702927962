import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { BehaviorSubject } from "rxjs";
import { environment } from "src/environments/environment";
import { Device } from "../models";

@Injectable({
  providedIn: 'root'
})
export class MessagingService {
  urlApi = environment.urlApi;
  currentMessage = new BehaviorSubject(null);

  constructor(private afm: AngularFireMessaging, private http: HttpClient) { }

  requestPermission() {
    this.afm.requestToken.subscribe((token) => {
        if (!token) return;
        this.registerToken(token);
      }, (error) => {
        console.error("Unable to get permission to notify", error);
      }
    );
  }

  receiveMessage(): void {
    this.afm.messages.subscribe((payload) => {
      this.currentMessage.next(payload);
    })
  }

  private registerToken(token: string): void {
    const browserUuid = localStorage.getItem('browserUuid');
    const device: Device = {
      browserUuid,
      platform: 'web',
      registrationId: token
    };

    this.http.post(`${this.urlApi}api/me/express/devices`, device).subscribe();
  }
}
