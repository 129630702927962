import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Notification } from '../models';
import { PusherService } from './pusher.service';
import { TicketService } from './ticket.service';

@Injectable()
export class NotificationService {
  private notifications$ = new Subject<Notification>();

  notifications = this.notifications$.asObservable();

  constructor(
    private ticketService: TicketService,
    private pusherService: PusherService
  ) { }

  sendNotification(notification: Notification): void {
    this.notifications$.next(notification);
  }

  add(ticketId: number, serviceConfigId: number, eventNames: string[]): void {
    const events = new Map<string, Function>();
    const serviceConfigEvent = new Map<string, Function>();
    serviceConfigEvent.set('TicketStart', (data) => this.parseMessage(data, 'ServiceConfigTicketStart'));
    serviceConfigEvent.set('TicketCalled', (data) => this.parseMessage(data, 'ServiceConfigTicketCalled'));
    serviceConfigEvent.set('TicketCancel', (data) => this.parseMessage(data, 'ServiceConfigTicketCancel'));
    serviceConfigEvent.set('TicketCheckin', (data) => this.parseMessage(data, 'ServiceConfigTicketCheckin'));

    for (const eventName of eventNames) {
      events.set(eventName, (data) => this.parseMessage(data, eventName));
    }
    this.pusherService.add(`ticket-${ticketId}`, events);
    this.pusherService.add(`serviceConfig-${serviceConfigId}`, serviceConfigEvent);
  }

  parseMessage(data: any, eventName: string): void {
    const id = data.ticket ? data.ticket.id : data.TicketId;
    this.ticketService.getTicket(id).subscribe((response) => {
      const notification: Notification = {
        eventName,
        ticket: {
          id: response.id,
          prevision: response.estimatedServiceStart,
          status: response.status,
          resource: response.resource ? response.resource.name : null,
          attendanceType: response.attendanceType,
          position: null,
          timezoneId: response.location.timezoneId,
          providerId: response.provider.id,
          serviceConfigId: response.session.serviceConfigId,
          friendlyCode: response.friendlyCode,
          accessKey: response.accessKey,
          locationId: response.location.id,
          locationName: response.location.name,
          providerName: response.provider.name,
          serviceName: response.service.name,
          feedback: response.feedback,
          smartCode: response.smartCode
        },
        message: "Chegou uma notificação"
      }

      this.sendNotification(notification);
    });
  }

  remove(ticketId: number, serviceConfigId: number): void  {
    this.pusherService.remove(`ticket-${ticketId}`);
    this.pusherService.remove(`serviceConfig-${serviceConfigId}`);
  }
}
