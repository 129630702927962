import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-vidoconference-alert",
  templateUrl: "./vidoconference-alert.component.html",
  styleUrls: ["./vidoconference-alert.component.scss"],
})
export class VidoconferenceAlertComponent {
  constructor(private dialogRef: MatDialogRef<VidoconferenceAlertComponent>) {
    this.dialogRef.disableClose = true;
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
