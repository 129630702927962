import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QueueTrackerComponent } from './components/queue-tracker/queue-tracker.component';
import { TranslateModule } from '@ngx-translate/core';
import { PipeModule } from 'src/app/shared/pipe/pipe.module';
import { QueueTrackerListComponent } from './components/queue-tracker-list/queue-tracker-list.component';
import { MaterialModule } from 'src/app/material/material.module';
import { StoreScannerComponent } from './components/store-scanner/store-scanner.component';
import { DisplayService } from './display.service';


@NgModule({
  declarations: [QueueTrackerComponent, QueueTrackerListComponent, StoreScannerComponent],
  imports: [
    CommonModule,
    TranslateModule,
    PipeModule,
    MaterialModule,
//    NgxQRCodeModule,
  ],
  providers: [
    DisplayService,
  ],
  exports: [
    QueueTrackerComponent,
    QueueTrackerListComponent,
    StoreScannerComponent,
  ],
})
export class DisplayModule { }
