<h2 mat-dialog-title>
  {{ 'general.pages.schedule.form.priorityModal.title' | translate }}
</h2>
<mat-dialog-content class="mat-typography">
  <p align="justify">
    {{ 'general.pages.schedule.form.priorityModal.content' | translate }}
  </p>
  <h3>
    {{ 'general.pages.schedule.form.priorityModal.subtitle' | translate }}
  </h3>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>
    {{ 'general.pages.schedule.form.priorityModal.no' | translate }}
  </button>
  <button mat-button [mat-dialog-close]="true" cdkFocusInitial>
    {{ 'general.pages.schedule.form.priorityModal.yes' | translate }}
  </button>
</mat-dialog-actions>
