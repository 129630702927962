import { Component, Input, OnInit } from '@angular/core';
import { Session, Ticket, Location, Terminal } from 'src/app/shared/models';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-ticket-details',
  templateUrl: './ticket-details.component.html',
  styleUrls: ['./ticket-details.component.scss'],
})

export class TicketDetailsComponent implements OnInit {
  @Input() ticket: Ticket;
  @Input() session: Session;
  @Input() location: Location;
  @Input() terminal: Terminal;

  //isOpen é a boolean utilizada no toggleMoreDetails();
  public isOpen = false;
  public qrCodeBase = "https://chart.googleapis.com/chart?chs=200x200&cht=qr&chl=https://entre.filazero.app/track/";
  public qrCodeBaseLink = "https://entre.filazero.app/track/";

  constructor(private translateService: TranslateService) { }

  ngOnInit(): void {
    this.qrCodeBase += this.ticket.accessKey;
    this.qrCodeBaseLink += this.ticket.accessKey;

  }

  get currentLang(): string {
    return this.translateService.currentLang;
  }

  get center(): google.maps.LatLngLiteral {
    return {
      lat: +this.location.address.latitude, 
      lng: +this.location.address.longitude
    };
  }

  get lat(): number {
    return +this.location.address.latitude;
  }

  get lng(): number {
    return +this.location.address.longitude;
  }

  get status(): string {
    if (this.translateService.currentLang) {

    }
    switch (this.ticket.status) {
      case 'Authorized':
        return 'general.pages.track.details.authorized';
      case 'Confirmed':
        return 'general.pages.track.details.confirmed';
      case 'Reopened':
        return 'general.pages.track.details.reopened';
    }
  }

  convertStatus(str) {
    switch (str) {
      case 'Authorized':
        return 'general.pages.track.details.authorized';
      case 'Confirmed':
        return 'general.pages.track.details.confirmed';
      case 'Reopened':
        return 'general.pages.track.details.reopened';
      case 'Checked':
        return 'general.pages.track.details.checked';
        case 'Called':
        return 'general.pages.track.details.called';
    }
  }

  get priority(): string {
    switch (this.ticket.priority) {
      case 'Normal':
        return 'general.pages.track.details.nonPreferential';
      case 'Preferential':
        return 'general.pages.track.details.preferential';
      case 'SuperPreferential':
        return 'general.pages.track.details.superPreferential';
    }
  }

  open(url) {
    window.open(url)
  }

  toggleMoreDetails() {
    if (!this.isOpen) {
      document.querySelectorAll<HTMLElement>('.more-details__body')[0].style.display = "block";
      setTimeout(() => {
        document.querySelectorAll<HTMLElement>('.more-details__body')[0].style.transform = "translateY(0%) scaleY(1)";
        document.querySelectorAll<HTMLElement>('.more-details__header')[0].style.borderRadius = "10px 10px 0 0";
        document.querySelectorAll<HTMLElement>('.more-details__header__arrow')[0].style.transform = "rotateZ(180deg) translateX(-50%)";
        document.querySelectorAll<HTMLElement>('.more-details__body')[0].style.borderRadius = "0 0 10px 10px";
        this.isOpen = true;
      }, 50)

    } else {
      setTimeout(() => {
        document.querySelectorAll<HTMLElement>(".more-details__body")[0].style.display = "none";
        this.isOpen = false;
      }, 350)
      document.querySelectorAll<HTMLElement>(".more-details__body")[0].style.transform = "translateY(-50%) scaleY(0)";
      document.querySelectorAll<HTMLElement>(".more-details__header")[0].style.borderRadius = "10px";
      document.querySelectorAll<HTMLElement>(".more-details__header__arrow")[0].style.transform = "rotateZ(0deg)";
      document.querySelectorAll<HTMLElement>(".more-details__body")[0].style.borderRadius = "10px 10px 10px 10px";
    }

  }


}


