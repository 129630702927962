import { Routes } from '@angular/router';
import { BoticarioStartComponent } from './boticario-start/boticario-start.component';
import { BoticarioScheduleComponent } from './boticario-schedule/boticario-schedule.component';
import { BoticarioTrackComponent } from './boticario-track/boticario-track.component';
import { NotFoundComponent } from '../shared/pages/not-found/not-found.component';

export const BoticarioRoutes: Routes = [
  { path: 'schedule', component: BoticarioScheduleComponent },
  { path: 'track', component: BoticarioTrackComponent },
  { path: ':accessKey', component: BoticarioStartComponent },
  { path: '**', component: NotFoundComponent }
];
