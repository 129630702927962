<div class="container" *ngIf="isVerificate">
  <zxing-scanner
    [formats]="['QR_CODE']"
    (scanSuccess)="onCodeResult($event)"
  >
  </zxing-scanner>

  <div class="footer-fixed">
    <div class="button-next">
      <button
        class="btn"
        mat-flat-button
        (click)="back()"
      >
        <span>{{ 'general.actions.cancel' | translate }}</span>
      </button>
    </div>
  </div>

  <div class="exit" (click)="back()">
    <span class="material-icons">
      cancel
    </span>
  </div>

</div>

<div *ngIf="!isVerificate">
  <app-header [bgColor]="'#7604C2'" [logoUrl]="'assets/images/fz-logo-white.svg'"></app-header>
  <app-empty-state
    color="#7604C2"
    icon="error_outline"
    message="{{ 'general.pages.start.queueNotFound' | translate }}"
  >
    <div class="empty-state-button">
      <button 
        (click)="backToScan()" 
        class="btn" mat-flat-button>
        <span> 
          {{ 'general.actions.scan' | translate }}
        </span>
      </button>
    </div>
  </app-empty-state>


  
  <div class="footer-fixed">
    <div class="button-next">
      <button
        class="btn"
        mat-flat-button
        (click)="backToScan()"
      >
        <span>
          {{ 'general.actions.scan' | translate }}
        </span>
      </button>
    </div>
  </div>
</div>
