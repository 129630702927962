import Pusher from 'pusher-js';
import * as PusherTypes from 'pusher-js';

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable()
export class PusherService {

  channels: Map<string, ChannelEntry> = new Map();
  pusher: Pusher;

  constructor() {
    this.pusher = new Pusher(environment.pusher.key, environment.pusher);
  }

  add(channelName: string, events: Map<string, Function>): void {
    if (!this.channels.has(channelName)) {
      this.channels.set(channelName, new ChannelEntry(this.pusher.subscribe(channelName)));
    }

    const channel = this.channels.get(channelName);

    events.forEach((value: Function, key: string) => {
      channel.bind(key, value);
    });

  }

  remove(channelName: string): void {
    if (this.channels.has(channelName)) {
      this.channels.get(channelName).clear();
      this.channels.delete(channelName);
    }

    this.pusher.unsubscribe(channelName);
  }

  addHandler(channelName: string, eventName: string, handler: Function): void {
    if (this.channels.has(channelName)) {
      this.channels.get(channelName).bind(eventName, handler);
    }
  }

  removeHandler(channelName: string, eventName: string): void {
    if (this.channels.has(channelName)) {
      this.channels.get(channelName).unbind(eventName);
    }
  }

  exists(channelName: string): boolean {
    return this.channels.has(channelName);
  }
}

class ChannelEntry {
  channel: PusherTypes.Channel;
  events: string[] = [];

  constructor(channel: PusherTypes.Channel) {
    this.channel = channel;
  }

  bind(eventName: string, handler: Function): void {
    if (!this.events.includes(eventName)) {
      this.events.push(eventName);
      this.channel.bind(eventName, handler)
    }
  }

  unbind(eventName: string): void {
    if (this.events.includes(eventName)) {
      this.channel.unbind();
      const index = this.events.findIndex((e) => e === eventName);
      this.events.splice(index);
    }
  }

  clear(): void {
    this.channel.unbind_all();
  }
}
