<app-header
  [bgColor]="'white'"
  [logoUrl]="'assets/images/boticario-green.png'"
  [logoHeight]="'12px'"
  [showText]="true"
  [textColor]="'#007E78'"
  (logoClick)="logoEvent()"
  (digitalQueueClick)="digitalQueueEvent()"
></app-header>

<content *ngIf="isLoading">
  <section class="wrapper-center">
    <mat-spinner [diameter]="40"></mat-spinner>
  </section>
</content>

<content *ngIf="terminal && !isLoading">
  <section class="featured-image-container">
    <img
      class="featured-image"
      src="/assets/images/o-boticario-featured-image.jpg"
    />
    <div class="text">
    </div>
  </section>

  <section class="wellcome">
    <p>
      {{ 'boticario.pages.start.wellcome' | translate }}
    </p>

    <h3 (click)="nameStoreEvent()">
      {{ terminal.location.name }}
    </h3>
  </section>

  <div class="footer-fixed">
    <div class="button-next">
      <button
        class="btn"
        (click)="next(); startEvent()"
        mat-flat-button color="primary"
      >
        <span class="button-flex">
          <span class="item-invisible"></span>
          <span class="button-content">
            {{ 'boticario.actions.getIn' | translate }}
          </span>
          <span class="material-icons" id="icon-image">
            arrow_right_alt
          </span>
        </span>
      </button>
    </div>
  </div>
</content>

<content *ngIf="!terminal && !isLoading">
  <section class="wellcome">
    <p>A url acessada não conrresponde a nenhuma fila.</p>
  </section>
</content>
