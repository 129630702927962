import { Component, OnInit } from '@angular/core';
import { Terminal, TerminalPayload, Ticket } from 'src/app/shared/models';
import { PusherService, StorageService, TerminalService, TicketService } from 'src/app/shared/services';
import { MatSnackBar } from '@angular/material/snack-bar';
import { finalize } from 'rxjs/operators';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { TranslateService } from '@ngx-translate/core';
import { ReCaptchaV3Service } from 'ng-recaptcha';

@Component({
  selector: 'app-boticario-schedule',
  templateUrl: './boticario-schedule.component.html',
  styleUrls: ['./boticario-schedule.component.scss']
})

export class BoticarioScheduleComponent implements OnInit {
  terminal: Terminal;
  payload: TerminalPayload;
  isLoading = true;
  isLoadingEmit = false;
  completed = false;
  ticketId: number;
  isNameFocus = false;
  isPhoneFocus = false;
  isPriority = false;

  constructor(
    private terminalService: TerminalService,
    private pusherService: PusherService,
    private ticketService: TicketService,
    private snackBar: MatSnackBar,
    private router: Router,
    private deviceService: DeviceDetectorService,
    private translateService: TranslateService,
    private storageService: StorageService,
    private reCaptchaV3Service: ReCaptchaV3Service
  ) { }

  ngOnInit() {
    this.terminal = this.storageService.currentTerminal;
    this.payload = this.terminalService.getCurrentTerminalPayload();
    this.isLoading = false;
  }

  get isValid() {
    return this.payload.serviceId !== undefined && this.payload.customer.name !== undefined;
  }

  selectService(serviceId: number) {
    this.payload.serviceId = serviceId;
    const service = this.terminal.services.find((s) => s.id === serviceId);
    this.payload.terminalSchedule.sessions = service.sessions;
    this.terminalService.setCurrentTerminalPayload(this.payload);

    this.reCaptchaV3Service
      .execute('schedule')
      .subscribe((token) => this.resolvedCaptcha(token));

    if (service.name === 'QUERO COMPRAR') {
      this.selectBuyEvent();
    } else {
      this.selectRemovalEvent();
    }
  }

  private selectServicePreference() {
    this.isPriority
      ? this.payload.priority = 1
      : this.payload.priority = 0;
  }

  get currentLang(): string {
    return this.translateService.currentLang;
  }

  get hasServices() {
    return this.terminal.services &&
    this.terminal.services.length > 0 &&
    this.terminalService.providerAvailableServices(this.terminal.services, this.terminal.showOptionsBasedOnSessions, new Date()).length > 0;
  }

  get hasSessions() {
    if (this.hasServices) {
      return this.terminal.services.some((service) => service.sessions && service.sessions.length > 0);
    }

    return false;
  }

  booking() {
    this.selectServicePreference();
    this.terminalService.setCurrentTerminalPayload(this.payload);
    this.isLoadingEmit = true;
    this.terminalService.booking(this.payload)
      .pipe(finalize(() => {
        setTimeout(() => {
          if (!this.completed) {
            this.getTicket();
          }
        }, 5000);
      }))
      .subscribe((result) => {
        if (result.responseData) {
          this.subscribeBooking(result.responseData.aggregatorId);
          this.ticketId = result.responseData.tickets[0];
        } else {
          this.isLoadingEmit = false;
          this.snackBar.open(result.messages[0].description, 'Erro', { duration: 3000 });
          this.completed = true;
        }
      }, (error) => {
        this.isLoadingEmit = false;
        this.snackBar.open('Não foi possível emitir o bilhete', 'Erro', { duration: 3000 });
        this.completed = true;
      });
  }

  subscribeBooking(aggregatorId: string) {
    const scheduleChannel = this.pusherService.pusher.subscribe('schedule-' + aggregatorId);

    scheduleChannel.bind('ScheduleAuthorized', data => {
      this.isLoadingEmit = false;
      this.completed = true;
      this.ticketService.getPrevision(data.tickets[0].Id)
        .subscribe((result) => {
          if (!(result.messages && result.messages[0].type === 'ERROR')) {
            const ticket: Ticket = {
              accessKey: data.tickets[0].AccessKey,
              friendlyCode: data.tickets[0].FriendlyCode,
              locationId: data.tickets[0].LocationId,
              id: data.tickets[0].Id,
              providerId: data.tickets[0].TenantId,
              timezoneId: data.tickets[0].Timezone,
              serviceConfigId: data.tickets[0].ServiceConfigId
            } as Ticket;
            this.storageService.addTicket(ticket);
            this.storageService.setCurrentTicket(ticket);
            this.goToTrack();
          }


        });
    });

    scheduleChannel.bind('ScheduleUnauthorized', data => {
      this.isLoadingEmit = false;
      this.completed = true;
    });
  }

  private getTicket() {
    this.ticketService.getTicket(this.ticketId)
      .pipe()
      .subscribe((ticket) => {

        const t: Ticket = {
          accessKey: ticket.accessKey,
          friendlyCode: ticket.friendlyCode,
          locationId: ticket.location.id,
          id: ticket.id,
          providerId: ticket.provider.id,
          timezoneId: ticket.location.timezoneId,
          serviceConfigId: ticket.session.serviceConfigId,
          prevision: ticket.estimatedServiceStart
        } as Ticket;

        this.storageService.addTicket(t);
        this.storageService.setCurrentTicket(t);
        this.goToTrack();
      });
  }

  resolvedCaptcha(result) {
    this.payload.recaptcha = result;

    this.booking();
  }

  private goToTrack() {
    this.router.navigate(['/boticario/track']);
  }

  onFocus(event: FocusEvent, el: HTMLElement) {
    if (this.deviceService.os === 'Android') {
      setTimeout(() => el.scrollIntoView(), 300);
    }
  }

  continueEvent() {
    window['dataLayer'] = window['dataLayer'] || [];

    window['dataLayer'].push({
      event: 'click',
      data: 'Escolha_Fila_Continuar'
    });
  }

  backEvent() {
    window['dataLayer'] = window['dataLayer'] || [];

    window['dataLayer'].push({
      event: 'click',
      data: 'Escolha_Fila_Voltar'
    });
  }

  selectRemovalEvent() {
    window['dataLayer'] = window['dataLayer'] || [];

    window['dataLayer'].push({
      event: 'click',
      data: 'Escolha_Fila_Selected_Retirada'
    });
  }

  selectBuyEvent() {
    window['dataLayer'] = window['dataLayer'] || [];

    window['dataLayer'].push({
      event: 'click',
      data: 'Escolha_Fila_Selected_Compra'
    });
  }

  checkPreferentialEvent() {
    window['dataLayer'] = window['dataLayer'] || [];

    window['dataLayer'].push({
      event: 'click',
      data: 'Escolha_Fila_Check_Preferencial'
    });
  }

  tooltipPreferentialEvent() {
    window['dataLayer'] = window['dataLayer'] || [];

    window['dataLayer'].push({
      event: 'click',
      data: 'Escolha_Fila_Tooltip_Preferencial'
    });
  }
}

