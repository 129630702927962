<mat-toolbar [style.background-color]="bgColor">
  <img [src]="logoUrl" [style.height]="logoHeight" (click)="onLogoClick()">
  <span class="spacer"></span>
  <span class="text" *ngIf="showText" [style.color]="textColor" (click)="onDigitalQueueClick()">Fila Digital</span>

  <button mat-icon-button [matMenuTriggerFor]="menu" [style.color]="textColor" class="menu">
    <mat-icon
      matBadge="&#8288;"
      [matBadgeHidden]="shouldHideBadge()"
      matBadgeColor="warn"
      matBadgeSize="small"
    >more_vert</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item (click)="next()">
      <mat-icon
        matBadge="&#8288;"
        [matBadgeHidden]="shouldHideBadge()"
        matBadgeColor="warn"
        matBadgeSize="small"
        >receipt
      </mat-icon>
      <span>{{ 'general.components.header.ticketList' | translate }}</span>
    </button>
    <button mat-menu-item [matMenuTriggerFor]="language">
      <mat-icon >translate</mat-icon>
      <span>{{ 'general.components.header.language' | translate }}</span>
    </button>
  </mat-menu>

  <app-navigation-bar></app-navigation-bar>

  <mat-menu #language="matMenu">
    <button
      mat-menu-item
      *ngFor="let lang of langs"
      (click)="setLang(lang.code)"
      [disabled]="currentLang === lang.code"
    >
      <span>{{ lang.name }}</span>
    </button>
  </mat-menu>
</mat-toolbar>
