import { Component, Input, OnInit, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { FieldBase } from '../models/field-base.model';
import { FieldControlService } from '../field-control.service';

@Component({
  selector: 'app-custom-fields-form',
  templateUrl: './custom-fields-form.component.html',
  styleUrls: ['./custom-fields-form.component.scss'],
  providers: [FieldControlService]
})

export class CustomFieldsFormComponent implements OnInit, AfterViewInit {
  @Input() fields: FieldBase[] = [];
  @Input() providerId: number;
  @Input() locationId: number;
  @Output() sendFormGroup: EventEmitter<UntypedFormGroup>;
  form: UntypedFormGroup;

  constructor(private fieldControlService: FieldControlService) {
    this.sendFormGroup = new EventEmitter<UntypedFormGroup>();
  }

  ngOnInit() {
    this.form = this.fieldControlService.makeFormGroup(this.fields);
  }

  ngAfterViewInit() {
    this.sendFormGroup.emit(this.form);
  }
}
