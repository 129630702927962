import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {

  transform(date: string, args: { format: string, locale: string }): string {
    moment.locale(args.locale)
    return moment.utc(date).format(args.format);
  }

}
