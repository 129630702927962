import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-privacy-policy-modal',
  templateUrl: './privacy-policy-modal.component.html',
  styleUrls: ['./privacy-policy-modal.component.scss']
})
export class PrivacyPolicyModalComponent implements OnInit {

  constructor(
    private matDialogRef: MatDialogRef<PrivacyPolicyModalComponent>,
    private translateService: TranslateService
    ) { }

  ngOnInit() {
  }

  get currentLang() {
    return this.translateService.currentLang;
  }

  closeModal() {
    this.matDialogRef.close();
  }
}





