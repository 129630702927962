import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-terms-modal',
  templateUrl: './terms-modal.component.html',
  styleUrls: ['./terms-modal.component.scss']
})
export class TermsModalComponent implements OnInit {

  constructor(
    private matDialogRef: MatDialogRef<TermsModalComponent>,
    private translateService: TranslateService
    ) { }

  ngOnInit() {
  }

  get currentLang() {
    return this.translateService.currentLang;
  }

  closeModal() {
    this.matDialogRef.close();
  }

}
