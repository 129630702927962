import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Terminal, TerminalPayload } from 'src/app/shared/models';
import { StorageService, TerminalService, TicketService } from 'src/app/shared/services';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-boticario-start',
  templateUrl: './boticario-start.component.html',
  styleUrls: ['./boticario-start.component.scss']
})
export class BoticarioStartComponent implements OnInit {
  terminal: Terminal;
  payload: TerminalPayload;
  isLoading = true;

  constructor(
    private route: ActivatedRoute,
    private terminalService: TerminalService,
    private router: Router,
    private storageService: StorageService 
  ) { }

  ngOnInit() {
    this.payload = {} as TerminalPayload;
    this.route.paramMap
      .subscribe(params => {
        const accessKey = params.get('accessKey');

        localStorage.setItem('terminalKey', accessKey);

        if (accessKey !== undefined) {
          this.terminalService.getTerminal(accessKey)
            .pipe(finalize(() => this.isLoading = false))
            .subscribe(terminal => {
              if (!(terminal.messages && terminal.messages[0].type === 'ERROR')) {
                this.terminal = terminal;
                this.storageService.addTerminal(terminal);
                this.terminalService.initPayload(terminal.provider.id, terminal.location.id, accessKey, terminal.id);
                this.payload = this.terminalService.getCurrentTerminalPayload();
              }
            });
        }
      });

    this.loadGoogleAnalytics();
    this.gtm();
  }

  next(): void {
    this.router.navigate(['/boticario/schedule']);
  }

  loadGoogleAnalytics(): void {
    const gaScript = document.createElement('script');
    gaScript.setAttribute('async', 'true');
    gaScript.setAttribute('id', 'boticario_gtag');
    gaScript.setAttribute('src', 'https://www.googletagmanager.com/gtag/js?id=UA-173624925-2');

    const gaScript2 = document.createElement('script');
    gaScript2.innerText = `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag(\'js\', new Date());gtag(\'config\', \'UA-173624925-2\');`;

    document.documentElement.firstChild.appendChild(gaScript);
    document.documentElement.firstChild.appendChild(gaScript2);
  }

  gtm(): void {
    const gtmScript = document.createElement('script');
    gtmScript.innerText = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-PL9TCGC');`;

    document.documentElement.firstChild.appendChild(gtmScript);

    const noScript = document.createElement('noscript');
    noScript.innerHTML = '<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-PL9TCGC" height="0" width="0" style="display:none;visibility:hidden"></iframe>';

    document.body.appendChild(noScript);
  }

  startEvent() {
    window['dataLayer'] = window['dataLayer'] || [];

    window['dataLayer'].push({
      event: 'click',
      data: 'Inicial_Entrar'
    });
  }

  logoEvent() {
    window['dataLayer'] = window['dataLayer'] || [];

    window['dataLayer'].push({
      event: 'click',
      data: 'Inicial_Logo'
    });
  }

  digitalQueueEvent() {
    window['dataLayer'] = window['dataLayer'] || [];

    window['dataLayer'].push({
      event: 'click',
      data: 'Inicial_Fila_Digital'
    });
  }

  nameStoreEvent() {
    window['dataLayer'] = window['dataLayer'] || [];

    window['dataLayer'].push({
      event: 'click',
      data: 'Inicial_Nome_Loja'
    });
  }
}
