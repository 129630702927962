<div>
  <form [formGroup]="form">
    <div *ngFor="let field of fields">
      <app-custom-fields
        class="custom-field"
        [field]="field"
        [providerId]="providerId"
        [locationId]="locationId"
        [form]="form"
      >
      </app-custom-fields>
    </div>
  </form>
</div>
