import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-qr-code-scan',
  templateUrl: './qr-code-scan.component.html',
  styleUrls: ['./qr-code-scan.component.scss']

})
export class QrCodeScanComponent {

  qrResultString: string;
  isVerificate = true;

  constructor(private router: Router) { }

  onCodeResult(resultString: string) {
    this.qrResultString = resultString;

    if (this.verificationUrl(this.qrResultString)) {
      location.href = this.qrResultString;
    } else if(this.qrResultString.includes(environment.urlExterna)){
      const urlArray = this.qrResultString.split('/')
      const arrayId = urlArray [urlArray.length -1].split('=')
      const id = arrayId [arrayId.length -1]
      this.router.navigate([`/track/${id}`])
    }
  }

  private verificationUrl(url: string): boolean {
    if (url.includes(environment.urlBase)){
      return true;
    } else {
      return false;
    }
  }

  back(): void {
    this.router.navigate(['/home'])
  }

  backToScan(): void {
    this.router.navigate(['/qrcode-scan'])
    this.isVerificate = true;
  }
}
