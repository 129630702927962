<section class="modal-container">
  <article class="modal-content">
    <mat-icon class="clickable close" (click)="closeModal()">close</mat-icon>
    <section *ngIf="currentLang === 'pt-BR' || currentLang === 'pt-PT'">
      <h1>Termos de uso</h1>
      <article>
        <p>
          Bem-vindo ao Filazero! Por favor, reserve-nos um momento e leia com
          atenção estes Termos de Uso.
        </p>
        <p>
          Vale lembrá-lo que o acesso ao conteúdo de nossa plataforma dependerá
          de sua prévia e expressa concordância com os Termos de Uso e Política
          de Privacidade.
        </p>
        <p>
          Os Termos de Uso apresentam as "condições gerais" aplicáveis ao uso
          das plataformas disponibilizadas pela Inventione Tecnologia da
          Informação Ltda., pessoa jurídica de direito privado, com sede na Rua
          Pastor Euclides Arlindo, 575, Loja A Pereira Lobo Aracaju - SE
          49052-330, inscrita no CNPJ sob o n. 11.533.443/0001-64, doravante
          denominada "Filazero".
        </p>

        <h2>Definições Importantes</h2>

        <p>
          <strong>Usuário:</strong> Toda e qualquer pessoa física que acessa e
          utiliza os recursos ofertados pelo Filazero.
        </p>
        <p>
          <strong>Plataforma:</strong> São os meios digitais pelo qual o usuário
          poderá acessar os recursos ofertados pelo Filazero (aplicativo, site,
          totem de atendimento, qr code e etc.).
        </p>
        <strong> Parceiro:</strong> Toda e qualquer pessoa jurídica que oferte
        seus próprios serviços ao usuário por meio das plataformas do Filazero.

        <h2>O que é o Filazero</h2>

        <p>
          O Filazero é uma plataforma que dinamiza o gerenciamento de
          atendimentos e a otimização de processos, auxiliando nossos parceiros
          em melhor atender ao público e proporcionando ao usuário maior
          conforto e praticidade quando precisar aguardar para ser atendido,
          melhorando a qualidade de atendimentos e a interação entre clientes e
          estabelecimentos.
        </p>
        <p>
          Ao utilizar a plataforma Filazero, o usuário terá acesso aos seguintes
          recursos: Conhecimento de locais que você pode ir. Com a nossa
          plataforma você consegue encontrar os estabelecimentos que possuem
          nosso sistema e, assim, usufruir dos benefícios de nossos serviços. No
          mais, você pode verificar quais destes estabelecimentos estão
          atendendo ou não naquele momento.
        </p>

        <p>
          Acompanhar sua posição de atendimento na fila. Em nossa plataforma
          você consegue ver sua posição na fila em tempo real, bastando ter
          conectividade e o código de acompanhamento.
        </p>
        <p>
          Pegar senhas de atendimentos pelo aplicativo. Você pode emitir senhas
          de atendimento para esperar virtualmente nas filas, dessa maneira você
          não precisa esperar no local até ser atendido.
        </p>
        <p>
          Melhor aproveitamento do seu tempo. O Filazero facilita sua vida,
          indicando a estimativa de tempo e a quantidade de pessoas na sua
          frente na fila, permitindo que você utilize o tempo que seria de
          espera no local para fazer o que quiser.
        </p>
        <p>
          Agendar um serviço. Você pode agendar serviços de nossos clientes para
          ser atendimento presencialmente a acompanhar atrasos no dia do
          atendimento.
        </p>
        <p>
          Atendimento remoto. O Filazero permite que as filas sejam configura
          para atendimento por vídeo conferência, o cliente poderá usar o
          browser através de um link seguro enviado por e-mail ou via aplicativo
          Filazero disponível para Android e IOS.
        </p>

        <h2>Acesso e Registro</h2>

        <p>
          O acesso e registro nas plataformas Filazero pelo usuário é gratuito,
          podendo ser realizado tanto por nosso aplicativo (disponível na “Apple
          App Store” e na “Google Play Store”) como pelo nosso site.
        </p>

        <p>
          Para ter acesso aos recursos disponíveis aos usuários do Filazero,
          você deverá realizar seu cadastro em nossas plataformas.
        </p>

        <p>
          Não será permitido o cadastro de pessoas com menos de 18 anos de idade
          na plataforma Filazero, sendo que se você tiver menos de 18 anos de
          idade só poderá fazer uso de nossos recursos com o envolvimento, a
          supervisão e aprovação de seus pais ou responsáveis.
        </p>

        <p>Você pode se cadastrar no Filazero de duas maneiras:</p>
        <ol type="i">
          <li>
            cadastrando-se diretamente nas plataforma Filazero, através da
            criação de uma senha de acesso e prestando as informações
            solicitadas;
          </li>
          <li>
            utilizando a rede social Facebook. Neste caso, o usuário deverá
            autorizar o Filazero a utilizar suas informações de registro naquela
            rede social para completar seu cadastro. O usuário é o único
            responsável pela segurança de seus dados de acesso. É por meio
            desses dados que o usuário pode acessar sua conta no Filazero e
            alterar suas informações pessoais e outros dados de seu cadastro.
            Caso haja qualquer suspeita de uso indevido ou não autorizado de sua
            conta, o usuário deve notificar imediatamente o Filazero através de
            nossos canais de atendimento.
          </li>
        </ol>
        <p>O usuário concorda em:</p>
        <ol type="a">
          <li>
            Fornecer informações verdadeiras, exatas, atuais e completas quando
            for realizar seu cadastro;
          </li>

          <li>
            Conservar e atualizar suas informações de forma a mantê-las
            verdadeiras, exatas, atuais e completas;
          </li>

          <li>
            Ser responsável por qualquer informação falsa, incorreta,
            desatualizada ou incompleta fornecida.
          </li>
        </ol>

        <p>
          Caso o Filazero tenha razões suficientes para suspeitar da veracidade
          e/ou da exatidão de tais informações, terá o direito de suspender ou
          cancelar, imediatamente e independente de qualquer aviso ou
          notificação, a conta do usuário.
        </p>

        <p>
          O Filazero é uma empresa privada e, com isso, se reserva o direito de,
          a seu exclusivo critério e segundo seus parâmetros de análise
          cadastral, aceitar ou não a adesão do usuário, bem como bloquear ou
          cancelar sua conta de nossas plataformas.
        </p>

        <h2>Limitação e Responsabilidade</h2>

        <p>
          O Filazero é uma plataforma que visa facilitar a relação entre o
          usuário e nossos parceiros, mas não assume responsabilidade pelo
          serviço prestado e pelas informações geradas por estes parceiros, nem
          tem qualquer obrigação de monitoramento.
        </p>
        <p>
          Os recursos são fornecidos ao usuário sem nenhuma garantia explícita
          ou implícita de fomentar qualquer objetivo comercial específico. Em
          nenhum caso o Filazero será responsabilizada por quaisquer danos,
          incluindo lucros cessantes, interrupção de negócio ou perda de
          informação que resultem do uso ou da incapacidade de usar os recursos
          disponíveis nas plataformasFilazero.
        </p>

        <p>
          O Filazero sempre se empenhará em trabalhar com seus parceiros para
          fornecer ao usuário a melhor experiência possível, porém você deve
          estar ciente que nem sempre as informações que são mostradas estarão
          corretas ou atualizadas, e que não nos responsabilizamos pela exatidão
          destas informações.
        </p>

        <p>
          Não nos responsabilizamos pelos serviços prestados por nossos
          parceiros cadastrados, tampouco por atrasos acima do tempo de espera,
          adiantamentos do tempo previsto ou atendimento de clientes que não
          estão na fila. O Filazero é uma ferramenta de otimização, mas o
          gerenciamento dos atendimentos é de inteira responsabilidade do
          parceiro que oferta o serviço.
        </p>
        <p>
          É de responsabilidade exclusiva do usuário estar no local de
          atendimento antes ou no momento que for notificado para ser atendido.
          Esta notificação poderá ocorrer por voz, por meio de aparelho
          televisor ou por meio de seu smartphone, sendo dever do usuário se
          certificar da forma de notificação.
        </p>

        <p>
          O Filazero não se responsabiliza por qualquer dano, prejuízo ou perda
          sofridos pelo usuário em razão de falhas na internet, no sistema ou no
          servidor utilizados pelo usuário, decorrentes de condutas de
          terceiros, caso fortuito ou força maior. O Filazero não é responsável
          pela qualidade de sua conexão com a internet.
        </p>

        <p>
          O Filazero não será também responsável por qualquer dano causado por
          vírus ou “hacker” que possa atacar o equipamento do usuário em
          decorrência do acesso, utilização ou navegação na internet nas
          plataforma Filazero.
        </p>

        <h2>Restrições de Uso</h2>

        <p>
          Por favor, tenha sempre em mente que os recursos disponibilizados pelo
          Filazero devem ser utilizados de forma consciente, respeitando nossos
          parceiros e a comunidade em geral. O Filazero não irá tolerar o uso
          abusivo de seus recursos. Ao utilizar o Filazero, sempre esteja atento
          às seguintes disposições:
        </p>

        <ol type="a">
          <li>
            Você não pode utilizar o Filazero para violar as leis em sua
            jurisdição; Você não pode se passar por outras pessoas ou fornecer
            informações imprecisas; Você não pode fazer algo que interfira ou
            afete negativamente a operação dos parcerios cadastrados;
          </li>

          <li>
            Você não pode realizar a aquisição de senhas em massa de serviços
            que não pretende utilizar, bem como não pode utilizar de
            automatizações (“bots”) ou qualquer forma de “spam” dentro das
            plataformas Filazero;
          </li>

          <li>
            Você não pode tentar comprar, vender ou transferir qualquer elemento
            de sua conta (inclusive seu nome de usuário e seu lugar em filas) ou
            solicitar, coletar ou usar credenciais de conta de outros usuários;
          </li>

          <li>
            Você não pode acessar o API (Interface de Programação de Aplicações)
            do Filazero por uma plataforma não oficial;
          </li>

          <li>
            Você não pode realizar qualquer ato que interfira no ou de qualquer
            forma interrompa o serviço ofertado pelo Filazero, manipule, viole
            ou tente sondar, varrer ou testar a vulnerabilidade do serviço ou
            dos sistemas de computador, rede e regras de uso do Filazero, ou
            qualquer componente de segurança do Filazero, medidas de
            autenticação ou quaisquer outras medidas de proteção aplicáveis ao
            serviço, ao conteúdo ou a qualquer parte dos mesmos;
          </li>
        </ol>

        <h2>Reserva de Direitos</h2>

        <p>
          Em que pese o compromisso do Filazero em garantir que os serviços
          estejam sempre normalmente disponíveis, é possível que aconteçam
          ocasiões em que os serviços serão interrompidos para manutenção
          programada, upgrades ou para reparos de emergência. O Filazero
          reserva-se o direito de excluir qualquer conteúdo por qualquer motivo
          ou suspender seus serviços a qualquer momento, sem aviso prévio.
        </p>
        <p>
          Nossa responsabilidade por qualquer ocorrência no serviço será
          limitada tanto quanto permitido por lei. Se houver um problema com
          nosso serviço, não temos como prever todos os impactos que poderão
          ocorrer. Você concorda que nós não seremos responsáveis por qualquer
          perda de lucro, receitas, informação ou dados, ou, ainda, por danos
          eventuais, especiais, indiretos, exemplares, punitivos ou acidentais
          decorrentes de ou relativos a estes termos, ainda que saibamos que
          eles são possíveis.
        </p>
        <p>
          O serviço Filazero é fornecido “no estado em que se encontra”, sem
          garantias ou condições. Especificamente, o nosso serviço pode não
          estar livre de interrupções ou erros. Você renuncia a todas e
          quaisquer indenizações por danos indiretos, morais e materiais, contra
          o Filazero.
        </p>
        <p>
          O uso comercial da expressão "Filazero" como marca, nome empresarial
          ou nome de domínio, bem como os conteúdos das telas relativas aos
          serviços do Filazero, assim como os programas, bancos de dados, redes,
          códigos de programação, e arquivos que permitem que o usuário acesse e
          use sua conta são propriedade do Filazero e estão protegidos pelas
          leis e tratados internacionais de direito autoral, marcas, patentes,
          modelos e desenhos industriais. O uso indevido e a reprodução total ou
          parcial dos referidos conteúdos são proibidos, salvo se houver
          autorização expressa pelo Filazero.
        </p>

        <h2>Atualização dos Termos de Uso</h2>

        <p>
          Em caso de modificação destes termos, para atender melhor seus
          clientes, publicaremos tais alterações de nossos serviços e dos termos
          de uso ligados a estes, e lhes notificaremos sobre esta situação.
          Também manteremos as versões anteriores destes Termos de Uso
          arquivadas para você compará-las. Se você optar por continuar
          utilizando nossas plataformas, você estará concordando automaticamente
          com as disposições atualizadas.
        </p>

        <h2>Legislação Aplicável</h2>

        <p>
          Estes termos de uso são regidos única e exclusivamente pelas leis
          brasileiras, sendo que qualquer ação judicial relativa à sua
          interpretação ou aplicação deverá ser processada e julgada pelo Poder
          Judiciário Brasileiro. Fica eleito o foro da Comarca de Aracaju-SE
          para dirimir as dúvidas oriundas destes termos de uso.
        </p>
      </article>
    </section>

    <section *ngIf="currentLang === 'en-US'">
      <h1>Terms of Use</h1>
      <article>
        <p>
          Welcome to Filazero! Please allow us a moment and read with attention
          to these Terms of Use.
        </p>
        <p>
          It is worth reminding you that access to the content of our platform
          will depend on of your prior and express agreement to the Terms of Use
          and Policy of Privacy.
        </p>
        <p>
          The Terms of Use present the "general conditions" applicable to the
          use of the platforms made available by Inventione Tecnologia da
          Information Ltd., a legal entity governed by private law,
          headquartered at Rua Pastor Euclides Arlindo, 575, A Pereira Lobo
          Aracaju Store - SE 49052-330, registered with the CNPJ under no.
          11.533.443/0001-64, hereinafter called "Filazero".
        </p>

        <h2>Important Definitions</h2>

        <p>
          <strong>User:</strong> Each and every individual who accesses and uses
          the resources offered by Filazero.
        </p>
        <p>
          <strong>Platform:</strong> Are the digital means by which the user
          will be able to access the resources offered by Filazero (application,
          website, service totem, qr code, etc.).
        </p>
        <strong> Partner:</strong> Any and all legal entities that offer their
        own user services through Filazero platforms.

        <h2>What is Filazero</h2>

        <p>
          Filazero is a platform that streamlines the management of service and
          process optimization, helping our partners in better serving the
          public and providing the largest user comfort and convenience when you
          need to wait to be served, improving the quality of care and
          interaction between customers and establishments.
        </p>
        <p>
          When using the Filazero platform, the user will have access to the
          following features: Knowledge of places you can go. with our platform
          you can find the establishments that have our system and thus enjoy
          the benefits of our services. At the more, you can check which of
          these establishments are answering or not at that moment.
        </p>

        <p>
          Keep track of your position in the queue. on our platform you can see
          your position in the queue in real time, just have connectivity and
          the tracking code.
        </p>
        <p>
          Get service passwords through the application. You can issue passwords
          to wait virtually in lines, this way you you don't need to wait on
          site until you're served.
        </p>
        <p>
          Better use of your time. Filazero makes your life easier, indicating
          the estimated time and the number of people in your front in line,
          allowing you to use the time you would have wait at the place to do
          what you want.
        </p>
        <p>
          Schedule a service. You can schedule services from our customers to be
          a face-to-face service to monitor delays on the day of the attendance.
        </p>
        <p>
          Remote service. Filazero allows queues to be configured. for video
          conference service, the customer can use the browser via a secure link
          sent by email or via the app Filazero available for Android and IOS.
        </p>

        <h2>Access and Registration</h2>
        <p>
          User access and registration on Filazero platforms is free, can be
          done either by our app (available on "Apple App Store” and the “Google
          Play Store”) as per our website.
        </p>

        <p>
          To access the resources available to Filazero users, you must register
          on our platforms.
        </p>

        <p>
          The registration of people under 18 years of age will not be allowed
          on the Filazero platform, if you are under 18 years of age age can
          only use our resources with the involvement, the supervision and
          approval of their parents or guardians.
        </p>

        <p>You can register with Filazero in two ways:</p>
        <ol type="i">
          <li>
            registering directly on the Filazero platform, through the creating
            an access password and providing the information requested;
          </li>
          <li>
            using the social network Facebook. In this case, the user must
            authorize Filazero to use your registration information in that
            social network to complete your registration. the user is the only
            one responsible for the security of your access data. It's through
            of these data that the user can access their account on Filazero and
            change your personal information and other data in your
            registration. If there is any suspicion of misuse or unauthorized
            use of your account, the user must immediately notify Filazero
            through our service channels.
          </li>
        </ol>
        <p>User agrees:</p>
        <ol type="a">
          <li>
            Provide true, accurate, current and complete information when to
            perform your registration;
          </li>

          <li>
            Keep and update your information in order to keep it true, accurate,
            current and complete;
          </li>

          <li>
            Be responsible for any false, incorrect information, outdated or
            incomplete provided.
          </li>
        </ol>

        <p>
          If Filazero has sufficient reasons to suspect the veracity and/or the
          accuracy of such information, shall have the right to suspend or
          cancel, immediately and regardless of any notice or notification, the
          user account.
        </p>

        <p>
          Filazero is a private company and, therefore, reserves the right to,
          at its sole discretion and according to its analysis parameters
          registration, accept or not the user's membership, as well as block or
          cancel your account from our platforms.
        </p>
        <h2>Limitation and Liability</h2>

        <p>
          Filazero is a platform that aims to facilitate the relationship
          between the user and our partners, but does not assume responsibility
          for the service provided and the information generated by these
          partners, nor has any monitoring obligation.
        </p>
        <p>
          Features are provided to the user without any explicit warranty or
          implied to further any specific business purpose. In in no case will
          Filazero be held liable for any damages, including lost profits,
          business interruption or loss of information resulting from the use or
          inability to use the resources available on Filazero platforms.
        </p>

        <p>
          Filazero will always strive to work with its partners to provide the
          best user experience possible, but you should be aware that the
          information that is shown will not always be correct or updated, and
          that we are not responsible for the accuracy of this information.
        </p>

        <p>
          We are not responsible for the services provided by our registered
          partners, or due to delays above the waiting time, advances of the
          estimated time or customer service that do not are in the queue.
          Filazero is an optimization tool, but the care management is the sole
          responsibility of the partner offering the service.
        </p>
        <p>
          It is the sole responsibility of the user to be at the location of
          service before or at the time you are notified to be serviced. This
          notification may occur by voice, through the device TV or through your
          smartphone, it is the user's duty to certify the form of notification.
        </p>

        <p>
          Filazero is not responsible for any damage, loss or loss suffered by
          the user due to internet, system or server used by the user, arising
          from conduct of third parties, acts of God or force majeure. Filazero
          is not responsible for the quality of your internet connection.
        </p>

        <p>
          Filazero will also not be responsible for any damage caused by virus
          or "hacker" that can attack the user's equipment in as a result of
          accessing, using or browsing the internet in the Filazero platform.
        </p>

        <h2>Usage Restrictions</h2>

        <p>
          Please always keep in mind that the features made available by
          Filazero must be used conscientiously, respecting our partners and the
          wider community. Filazero will not tolerate use abusive of its
          resources. When using Filazero, always be aware to the following
          provisions:
        </p>

        <ol type="a">
          <li>
            You may not use Filazero to violate the laws in your jurisdiction;
            You cannot impersonate other people or provide inaccurate
            information; You cannot do something that interferes or negatively
            affect the operation of registered partners;
          </li>

          <li>
            You cannot purchase bulk passwords from services that you don't want
            to use, as well as you can't use from automations ("bots") or any
            form of "spam" within the Filazero platforms;
          </li>

          <li>
            You may not attempt to buy, sell or transfer any element. of your
            account (including your username and your place in queues) or
            solicit, collect or use account credentials of other users;
          </li>

          <li>
            You cannot access the API (Application Programming Interface) of
            Filazero by an unofficial platform;
          </li>

          <li>
            You may not perform any act that interferes with or any way
            interrupt the service offered by Filazero, manipulate, violate or
            try to probe, scan or test the vulnerability of the service or of
            the Filazero computer systems, network and usage rules, or any
            security component of Filazero, measures of authentication or any
            other protective measures applicable to the service, content or any
            part thereof;
          </li>
        </ol>
        <h2>Reservation of Rights</h2>

        <p>
          Despite Filazero's commitment to ensure that the services are always
          normally available, it is possible that they will happen. occasions
          when services will be interrupted for maintenance scheduled, upgrades
          or for emergency repairs. the Filazero reserves the right to delete
          any content for any reason. or suspend your services at any time
          without notice.
        </p>
        <p>
          Our responsibility for any occurrence on the service will be limited
          as far as permitted by law. If there is a problem with our service, we
          cannot predict all the impacts that could to occur. You agree that we
          will not be responsible for any loss of profit, revenue, information
          or data, or damage occasional, special, indirect, exemplary, punitive
          or accidental arising from or relating to these terms, even though we
          know that they are possible.
        </p>
        <p>
          The Filazero service is provided "as is", without warranties or
          conditions. Specifically, our service may not be free from
          interruptions or errors. you renounce all and any indemnities for
          indirect, moral and material damages, against the Filazero.
        </p>
        <p>
          The commercial use of the expression "Filazero" as a brand, business
          name or domain name, as well as the contents of the screens related to
          the Filazero services, as well as programs, databases, networks,
          programming codes, and files that allow the user to access and use
          your account are the property of Filazero and are protected by laws
          and international treaties on copyright, trademarks, patents,
          industrial models and designs. Misuse and full reproduction or partial
          of said contents are prohibited, unless there is express authorization
          by Filazero.
        </p>

        <h2>Update of Terms of Use</h2>

        <p>
          In case of modification of these terms, to better serve your
          customers, we will post such changes to our services and terms. linked
          to them, and we will notify you of this situation. We will also retain
          previous versions of these Terms of Use. files for you to compare. If
          you choose to continue using our platforms, you will be automatically
          agreeing with updated provisions.
        </p>

        <h2>Applicable Law</h2>

        <p>
          These terms of use are governed solely and exclusively by the laws
          Brazilian companies, and any legal action related to their
          interpretation or application shall be processed and judged by the
          Power Brazilian Judiciary. The jurisdiction of the District of
          Aracaju-SE is elected to resolve any doubts arising from these terms
          of use.
        </p>
      </article>
    </section>
  </article>
</section>
