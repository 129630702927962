<div (click)="button.serviceOrientation ? collapse() : onClick()" class="collapsible" [style.background]="brighten(terminal.headerBackgroundColor || '#7604C2', 20)">
  <p>{{ button.text }}</p>
  <div [ngClass]="{'active' : isActive, 'content': !isActive}">
    <div *ngIf="button.serviceOrientation">
      {{ 'general.pages.schedule.orientation' | translate }}
    </div>
    <div class="txt">
      <p class="orientation">
        {{ button.serviceOrientation }}
      </p>
    </div>
    <div class="buttons">
      <button mat-raised-button class="clbutton" (click)="onClick()">Continuar</button>
    </div>
  </div>
</div>
