<section class="container" *ngIf="displayOptions">
  <section class="services">
    <section class="schedule-wrapper">
      <div class="input-wrapper">
        <div class="title">
          <h3>{{ 'general.pages.schedule.options' | translate }}</h3>
        </div>  
        <div *ngIf="!isLoadingEmit">
          <ng-content></ng-content>
        </div>
      </div>
      <div class="spinner" *ngIf="isLoadingEmit"></div>
    </section>
  </section>
</section>
