import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Ticket } from 'src/app/shared/models';
import { StorageService } from 'src/app/shared/services';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-ticket-list-tile',
  templateUrl: './ticket-list-tile.component.html',
  styleUrls: ['./ticket-list-tile.component.scss']
})
export class TicketListTileComponent implements OnInit {
  @Input() ticket: Ticket;

  currentTicket: Ticket;

  constructor(
    private storageService: StorageService,
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  goToTrack () {
    this.storageService.setCurrentTicket(this.ticket);
    if (this.ticket.providerId === environment.boticarioProviderId) {
      this.router.navigate(['/boticario/track']);
    } else {
      this.router.navigate(['/track']);
    }
  }

}
