import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-store-scanner',
  templateUrl: './store-scanner.component.html',
  styleUrls: ['./store-scanner.component.scss']
})
export class StoreScannerComponent implements OnInit {
  @Input() expressLink: string;
  @Input() providerLogo: string;

  elementType = 'url';
  correctionLevel = 'H';
  constructor() { }

  ngOnInit(): void {
  }

}
