<section class="modal-container">
  <article class="modal-content">
    <mat-icon class="clickable close" (click)="closeModal()">close</mat-icon>
    <section>
      <h1 class="title-section">Termos de uso</h1>
      <article>
        <p>As atividades e horários poderão ser alterados ou cancelados sem aviso prévio.</p>
        <p>Alguns minutos antes do papai noel realizar a ligação, o cliente irá receber um sms no telefone cadastrado informando que está perto de receber a ligação.</p>
        <p>Serão realizadas 2 (duas) tentativas de contato. Em casos onde o cliente não atenda as ligações, após todas as tentativas, o mesmo não permanecerá mais na fila, sendo necessário realizar todo procedimento novamente.</p>
        <p>Todos os clientes que participam desta ação autorizam previamente o cadastro dos dados para relacionamento do estabelecimento.</p>
        <p>Servirá como protocolo de atendimento o número do bilhete gerado ao realizar o cadastro no hotsite do Fila Zero.</p>
        <p>A chamada será realizada de um número aleatório e que não recebe ligação de volta.</p>
        <p>A ligação será gravada.</p>
        <p>A simples participação nesta ação/atividade significa que o participante conhece e aceita plenamente todas as normas expressas no presente regulamento, aceita fornecer os dados cadastrais e concorda em ser contatado pela empresa promotora através de e-mail, mensagem ou qualquer outra forma de contato, salvo quando o participante informar no posto de trocas que não aceita ser contatado pela empresa promotora.</p>
      </article>
    </section>
  </article>
</section>
