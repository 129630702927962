import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-secondary-header',
  templateUrl: './secondary-header.component.html',
  styleUrls: ['./secondary-header.component.scss']
})
export class SecondaryHeaderComponent implements OnInit {
  @Input() icon: string;
  @Input() text: string;
  @Input() isClickable: boolean;
  @Input() isBoticario: boolean;
  @Input() bgColor: string;
  @Input() reduceHeight: boolean;
  @Output() backClick = new EventEmitter();

  accessKey: string;
  constructor(private router: Router) { }

  ngOnInit() {
    this.accessKey = localStorage.getItem('terminalKey');
  }

  goToStart() {
    if (this.isBoticario) {
      this.router.navigate([`boticario/${this.accessKey}`]);
    } else {
      this.router.navigate([`/start/${this.accessKey}`]);
    }
  }

  onBackClick() {
    this.backClick.emit();
  }
}
