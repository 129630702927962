import { Component, Input } from '@angular/core';


@Component({
  selector: 'app-track-information',
  templateUrl: './track-information.component.html',
  styleUrls: ['./track-information.component.scss']

})
export class TrackInformationComponent {
  @Input() ticketPosition: string;
  @Input() prevision: string;
  @Input() ticketCode: string;
}
