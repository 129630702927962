import { Component, OnDestroy, OnInit } from '@angular/core';
import { ExitAlertModalComponent } from 'src/app/components/exit-alert-modal/exit-alert-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { TicketService, StorageService, NotificationService } from 'src/app/shared/services';
import { Ticket, Terminal } from 'src/app/shared/models';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import * as moment from 'moment';
import { SoundAlertComponent } from 'src/app/components/sound-alert/sound-alert.component';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';

moment.locale('pt-br');

@Component({
  selector: 'app-boticario-track',
  templateUrl: './boticario-track.component.html',
  styleUrls: ['./boticario-track.component.scss']
})
export class BoticarioTrackComponent implements OnInit, OnDestroy {

  ticket: Ticket;
  terminal: Terminal;
  accessKey: string;
  isLoading = false;
  audio: HTMLAudioElement;
  subscription: Subscription;

  constructor(
    public dialog: MatDialog,
    private ticketService: TicketService,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private router: Router,
    private storageService: StorageService,
    private deviceService: DeviceDetectorService
  ) {
    if (this.isSafari) {
      this.openSoundAlertModal();
    } else {
      this.audio = new Audio();
    }
  }

  ngOnInit() {
    this.terminal = this.storageService.currentTerminal;
    this.ticket = this.storageService.currentTicket;
    this.getTicket();
    this.accessKey = localStorage.getItem('terminalKey');

    this.notificationService.add(
      this.ticket.id,
      this.ticket.serviceConfigId,
      ['TicketCheckin', 'PrevisionChanged', 'TicketCalled', 'TicketStart', 'TicketComplete', 'TicketCancel']
    )

    this.subscription = this.notificationService.notifications.subscribe(
      (response) => {
        switch (response.eventName) {
          case 'TicketCheckin':
            this.getTicket();
            break;
          case 'PrevisionChanged':
            this.ticket.prevision = response.ticket.prevision;
            this.getPosition();
            break;
          case 'TicketCalled':
            if (this.ticket.id === response.ticket.id) {
              this.ticket.status = 'Called';
              this.ticket.resource = response.ticket.resource;
              this.playNotification();
              this.storageService.updateTicket(this.ticket);
            }
            break;
          case 'TicketStart':
            if (this.ticket.id === response.ticket.id) {
              this.ticket.status = 'Started';
              this.storageService.updateTicket(this.ticket);
            }
            break;
          case 'TicketComplete':
            if (response.ticket.id === this.ticket.id) {
              this.backStart();
            }
            break;
          case 'TicketCancel':
            this.ticket.status = 'Canceled';
            this.storageService.updateTicket(this.ticket);
            break;
          case 'ServiceConfigTicketCalled':
            if (this.ticket.id === response.ticket.id) {
              this.getPosition();
            }
            break;
        }
      }
    )
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  openExitAlertModal() {
    this.dialog.open(ExitAlertModalComponent, {
      data: { ticketId: this.ticket.id, accessKey: this.accessKey, providerId: this.ticket.providerId, isBoticario: true }
    });
  }

  openSoundAlertModal() {
    const modal = this.dialog.open(SoundAlertComponent);

    modal.afterClosed().subscribe((audio: HTMLAudioElement) => {
      this.audio = audio;
      this.audio.src = '../../../assets/audio/no-sound.mp3';
      this.audio.load();
      this.audio.play();
    });
  }

  get currentLang(): string {
    return this.translateService.currentLang;
  }

  backStart() {
    this.storageService.removeTicket(this.ticket.id);

    if (this.storageService.tickets.length > 0){
      this.storageService.setCurrentTicket(this.storageService.tickets[0]);
    }
    else {
      this.storageService.setCurrentTicket(null);
    }

    this.router.navigate([`/boticario/${this.accessKey}`]);
  }

  getPosition() {
    const positionEl = document.getElementById('queuePosition');
    const previsionEl = document.getElementById('prevision');

    this.ticketService.getPosition(this.ticket.providerId, this.ticket.id).subscribe((response) => {
      if (response > 0) {
        this.ticket.position = response;

        if (this.isSafari) {
          this.forceRedraw(positionEl);
          this.forceRedraw(previsionEl);
        }
      }

      if (response === 1) {
        this.playNotification();
      }

      this.storageService.updateTicket(this.ticket);

    });
  }

  isCanceled() {
    return this.ticket.status === 'Canceled';
  }

  private getTicket() {
    this.isLoading = true;
    this.ticketService.getTicket(this.ticket.id)
      .pipe(finalize(() => this.isLoading = false))
      .subscribe((response) => {
        this.ticket = {
          id: response.id,
          prevision: this.ticket.prevision || response.estimatedServiceStart,
          status: response.status,
          resource: response.resource ? response.resource.name : null,
          attendanceType: response.attendanceType,
          position: null,
          timezoneId: response.location.timezoneId,
          locationName: response.location.name,
          providerId: response.provider.id,
          serviceConfigId: response.session.serviceConfigId,
          friendlyCode: response.friendlyCode,
          accessKey: response.accessKey,
          locationId: response.location.id,
          providerName: response.provider.name,
          serviceName: response.service.name,
          feedback: response.feedback,
          smartCode: response.smartCode
        };
        this.getPosition();

        if (this.isOlder || this.isCompleted || this.isOpened) {
          this.backStart();
        }
      });
  }

  get isSafari() {
    return this.deviceService.getDeviceInfo().browser === 'Safari';
  }

  get isOlder() {
    const ticketPrevision = moment(this.ticket.prevision)
      .tz(this.ticket.timezoneId)
      .startOf('day');

    const now = moment(Date.now()).startOf('day');

    return now.diff(ticketPrevision, 'days') >= 1;
  }

  get isCompleted() {
    return this.ticket.status === 'Completed';
  }

  get isOpened() {
    return this.ticket.status === 'Opened';
  }

  exitQueueEvent() {
    window['dataLayer'] = window['dataLayer'] || [];

    window['dataLayer'].push({
      event: 'click',
      data: 'Sala_Espera_Sair'
    });
  }

  playNotification() {
    if (this.audio !== undefined) {
      this.audio.src = '../../../assets/audio/got-it-done.mp3';
      this.audio.load();
      this.audio.play();
    }
  }

  forceRedraw(element: HTMLElement) {
    if (!element) {
      return;
    }

    element.style.opacity = '0.9';

    setTimeout(() => {
      element.style.opacity = '1';
    }, 20);
  }
}
